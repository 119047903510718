/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { ReactComponent as FolderOpenIcon } from "../../../../../assets/icons/FolderOpenIcon.svg";
import { useContext, useState } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import InputField from "../../../../MuiComponents/InputField";
import TextLink from "../../../../MuiComponents/TextLink";
import Button from "../../../../MuiComponents/Button";
import { replacePlaceholders } from "../../../../../utils/helpers";
import ThemeContext from "../../../../../context/theme/themeContext";

export interface CaptureLogProps {
    path: string;
    time: string;
    minTimeValue: number;
    maxTimeValue: number;
    validateNumber: any;
    change: any;
}
const CaptureLog = ({
    path,
    time,
    minTimeValue,
    maxTimeValue,
    validateNumber,
    change,
}: CaptureLogProps) => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);

    const { openFileDialog, openDirectory } = useDesktopHost();

    const [error, setError] = useState<string | null>(null);

    const handleBrowseClick = () =>
        openFileDialog(
            {
                isDirectory: true,
            },
            (result: any) =>
                result.length > 0 && change("captureLogPath", result[0])
        );

    const changeWithValidation = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        const numberIsCorrect = validateNumber(
            value,
            minTimeValue,
            maxTimeValue
        );

        const errorValue = !numberIsCorrect
            ? replacePlaceholders(t.InputSupport, minTimeValue, maxTimeValue)
            : null;

        setError(errorValue);

        change(name, value);
    };
    return (
        <div>
            <InputField
                fullWidth
                name="captureLogPath"
                value={path}
                size="medium"
                label={t.CaptureLogPath}
                iconRight={
                    <TextLink
                        size="normal"
                        onClick={handleBrowseClick}
                        idForTesting="browseButton"
                    >
                        {t.Change}
                    </TextLink>
                }
                additionalContent={
                    <Button
                        variant="iconOnly"
                        size="normal"
                        color="white"
                        icon={<FolderOpenIcon />}
                        onClick={() => openDirectory(path)}
                        css={css({
                            backgroundColor: gray100,
                            borderRadius: "6px",
                            marginLeft: "auto",
                        })}
                        idForTesting="openFolderButton"
                    />
                }
                readOnly
            />
            <InputField
                fullWidth
                name="captureLogTime"
                value={time}
                onChange={changeWithValidation}
                type="number"
                inputProps={{ min: minTimeValue, max: maxTimeValue }}
                size="medium"
                label={t.CaptureTimeMin}
                error={error}
                wrapperStyle={css`
                    margin-top: 8px;
                `}
            />
        </div>
    );
};

export default CaptureLog;
