import { FirmwareType } from "../../constants/constants";
import { addOrRemoveStringFromArray } from "../../utils/helpers";

import { InitialState } from "./DeviceStatusState";
import {
    RESET_PROGRESS_BARS,
    SET_ACTIVE_DALLAS_SENSOR_VALUES,
    SET_ACTIVE_LLS_SENSOR_VALUES,
    SET_BUNDLE_MODAL_PROGRESS_STEPS,
    SET_BUNDLE_PROGRESS,
    SET_DALLAS_SENSOR_DATA,
    SET_DUMP_READING_DATA,
    SET_LLS_SENSOR_DATA,
    SET_PROGRESS_BAR,
    SET_SHOULD_STEP_UPDATE,
    SET_UPDATE_BUNDLE_MODAL_INFO,
    SET_UPDATE_FIRMWARE_MODAL_INFO,
} from "./deviceStatusActions";
import { BundleProgress, UpdateBundleModalInfo } from "./deviceStatusContext";
import {
    handleSetBundleModalProgressSteps,
    handleSetShouldStepUpdate,
} from "./deviceStatusHelpers";

type Action =
    | { type: typeof SET_DUMP_READING_DATA; payload: any }
    | { type: typeof SET_DALLAS_SENSOR_DATA; payload: string[] }
    | { type: typeof SET_LLS_SENSOR_DATA; payload: string[] }
    | { type: typeof SET_ACTIVE_DALLAS_SENSOR_VALUES; payload: string }
    | { type: typeof SET_ACTIVE_LLS_SENSOR_VALUES; payload: string }
    | {
          type: typeof SET_PROGRESS_BAR;
          payload: {
              progressBarName: string;
              completedPercent: number;
          };
      }
    | {
          type: typeof SET_UPDATE_FIRMWARE_MODAL_INFO;
          payload: {
              isOpen: boolean;
              path: string;
              step: number;
              newVersion: string;
              firmwareType: FirmwareType;
              currentVersion: string;
              error: string;
              stepWithError?: number;
          };
      }
    | {
          type: typeof RESET_PROGRESS_BARS;
      }
    | {
          type: typeof SET_BUNDLE_PROGRESS;
          payload: BundleProgress;
      }
    | {
          type: typeof SET_UPDATE_BUNDLE_MODAL_INFO;
          payload: UpdateBundleModalInfo;
      }
    | {
          type: typeof SET_BUNDLE_MODAL_PROGRESS_STEPS;
          payload: {
              progress: BundleProgress | null;
              steps: any;
          };
      }
    | {
          type: typeof SET_SHOULD_STEP_UPDATE;
          payload: {
              mcuType: string;
              fileType: string;
              shouldUpdate: boolean;
          };
      };

export default (state: InitialState, action: Action): InitialState => {
    switch (action.type) {
        case SET_UPDATE_FIRMWARE_MODAL_INFO:
            return {
                ...state,
                updateFirmwareModalInfo: action.payload,
            };
        case SET_SHOULD_STEP_UPDATE: {
            return handleSetShouldStepUpdate(action, state);
        }
        case SET_BUNDLE_MODAL_PROGRESS_STEPS: {
            return handleSetBundleModalProgressSteps(action, state);
        }
        case SET_BUNDLE_PROGRESS:
            return {
                ...state,
                bundleProgress: action.payload,
            };
        case SET_UPDATE_BUNDLE_MODAL_INFO:
            return {
                ...state,
                updateBundleModalInfo: {
                    ...state.updateBundleModalInfo,
                    ...action.payload,
                },
            };
        case SET_DUMP_READING_DATA:
            return {
                ...state,
                dumpData: {
                    ...state.dumpData,
                    ...action.payload,
                },
            };
        case SET_PROGRESS_BAR:
            return {
                ...state,
                progressBars: {
                    ...state.progressBars,
                    [action.payload.progressBarName]:
                        action.payload.completedPercent,
                },
            };
        case SET_DALLAS_SENSOR_DATA:
            return {
                ...state,
                dallasSensorData: action.payload,
            };
        case SET_LLS_SENSOR_DATA:
            return {
                ...state,
                llsSensorData: action.payload,
            };
        case SET_ACTIVE_LLS_SENSOR_VALUES: {
            return {
                ...state,
                activeLlsSensorValues: addOrRemoveStringFromArray(
                    state.activeLlsSensorValues,
                    action.payload,
                ),
            };
        }
        case SET_ACTIVE_DALLAS_SENSOR_VALUES: {
            return {
                ...state,
                activeDallasSensorValues: addOrRemoveStringFromArray(
                    state.activeDallasSensorValues,
                    action.payload,
                ),
            };
        }
        case RESET_PROGRESS_BARS: {
            return {
                ...state,
                progressBars: {},
            };
        }
        default:
            return state;
    }
};
