/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext, useState } from "react";

import { css, jsx } from "@emotion/react";

import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import useDebounce from "../../../../../../utils/useDebounce";

export enum LogicalSwitchValue {
    AND = "And",
    OR = "Or",
}

export interface LogicalSwitchProps {
    className?: string;
    data?: any;
}

const LogicalSwitch: React.FunctionComponent<LogicalSwitchProps> = ({
    className = "",
    data,
}) => {
    const {
        parameterId,
        collectionConfig: { collectionItems },
        parameterValue,
    } = data;

    const [selected, setSelected] = useState<string>(parameterValue);

    const {
        colors: { gray300, textDarkDisabled, gray100 },
    } = useContext(ThemeContext);

    const { updateParameter } = useContext(MenuItemContext);

    const debouncedChangeHandler = useDebounce(updateParameter, 500);

    const handleSelect = (value: LogicalSwitchValue) => {
        try {
            debouncedChangeHandler(parameterId, value);
            setSelected(value);
        } catch (e) {
            console.log(e);
        }
    };

    const renderButton = (value: LogicalSwitchValue, translation: string) => {
        return (
            <button
                onClick={() => handleSelect(value)}
                css={css({
                    padding: "6px 8px",
                    fontSize: "12px",
                    fontWeight: 600,
                    backgroundColor: "white",
                    color: selected === value ? "#111827" : textDarkDisabled,
                    outline: "none",
                    border: "none",
                    letterSpacing: "0.4px",
                    cursor: "pointer",
                    borderRadius: "6px",
                    "&:hover": {
                        backgroundColor: gray100,
                    },
                })}
            >
                {translation}
            </button>
        );
    };

    return (
        <div
            className={className}
            css={css({
                display: "flex",
                padding: "2px",
                width: "100%",
                justifyContent: "center",
            })}
        >
            {renderButton(collectionItems[0].value, collectionItems[0].text)}
            <div
                css={css({
                    width: "1px",
                    backgroundColor: gray300,
                    margin: "0 8px",
                })}
            />
            {renderButton(collectionItems[1].value, collectionItems[1].text)}
        </div>
    );
};

export default LogicalSwitch;
