import React from "react";
import DialogModal from "../../../MuiComponents/Modals/DialogModal";

export interface MobileSaveModalProps {
    isOpen: boolean;
    onClose: () => void;
    onPrimaryBtnClick: () => void;
    onSecondaryBtnClick: () => void;
}

const MobileSaveModal = ({
    onClose,
    isOpen,
    onPrimaryBtnClick,
    onSecondaryBtnClick,
}: MobileSaveModalProps) => {
    return (
        <DialogModal
            title="Where do you want to save configuration?"
            open={isOpen}
            close={onClose}
            onPrimaryBtnClick={onPrimaryBtnClick}
            onSecondaryBtnClick={onSecondaryBtnClick}
            primaryButtonText="Save to device"
            secondaryButtonText="Save to file"
        />
    );
};

export default MobileSaveModal;
