/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    ListItemIcon,
    ListItemText,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";

import Select from "../../MuiComponents/Select";
import TooltipLarge from "../../MuiComponents/TooltipLarge";
import Checkbox from "../../MuiComponents/Checkbox";
import FilteredByBlock from "../../MuiComponents/FilteredByBlock";

import { LogFilter } from "../../../generatedTypes";
import { AlertStatus, LogFilterCommands } from "../../../constants/constants";
import LanguageContext from "../../../context/language/languageContext";
import AlertContext from "../../../context/alert/alertContext";
import useApi from "../../../utils/useApi";

const FilterSelect: React.FunctionComponent<{}> = () => {
    const [filterValues, setFilterValues] = useState<LogFilter[]>([]);
    const [hasError, setHasError] = useState<boolean>(false);
    const { t } = useContext(LanguageContext);
    const { setAlert } = useContext(AlertContext);

    const { getData, updateData } = useApi();
    const location = useLocation();

    const withoutSaveAndDefaultCommands = (data: LogFilter[]) =>
        data.filter(
            (item: LogFilter) =>
                item.name !== LogFilterCommands.Save &&
                item.name !== LogFilterCommands.Default
        );

    useEffect(() => {
        const getFilters = async () => {
            try {
                const { data } = await getData(`logfilter`);
                setHasError(false);
                setFilterValues(withoutSaveAndDefaultCommands(data));
            } catch (error) {
                setHasError(true);
                setAlert(AlertStatus.Critical, t.FailedToLoadFilters);
            }
        };
        getFilters();
    }, []);

    const handleChange = async (event: SelectChangeEvent<any>) => {
        event.stopPropagation();
        const value = event.target.value[0];
        const filterEnabled =
            filterValues.find((item) => item.id === value)?.enabled || false;

        try {
            const { data } = await updateData(`logfilter`, [
                {
                    id: value,
                    enabled: !filterEnabled,
                },
            ]);
            setFilterValues(withoutSaveAndDefaultCommands(data));
        } catch (err) {
            setAlert(AlertStatus.Critical, t.FailedToAddFilter);
        }
    };

    const handleChipDelete = async (value: string | number) => {
        try {
            const { data } = await updateData(`logfilter`, [
                {
                    id: value,
                    enabled: false,
                },
            ]);
            setFilterValues(withoutSaveAndDefaultCommands(data));
        } catch (err) {
            setAlert(AlertStatus.Critical, t.FailedToRemoveFilter);
        }
    };

    const handleClearAll = async () => {
        try {
            const ids = filterValues.map((item) => item.id);
            const idsDisabled = ids.map((id) => ({ id, enabled: false }));
            const { data } = await updateData(`logfilter`, idsDisabled);
            setFilterValues(withoutSaveAndDefaultCommands(data));
        } catch (err) {
            setAlert(AlertStatus.Critical, t.FailedToRemoveAllFilters);
        }
    };
    const currentPath = location.pathname;
    const hasNoFilters = filterValues.length === 0;
    const isStartupPage = currentPath === "/startup";
    const isFiltersDisabled = isStartupPage || hasNoFilters || hasError;

    const renderTooltipText = () => {
        if (hasError) {
            return t.HasFiltersError;
        }
        if (hasNoFilters) {
            return t.TerminalFiltersNotSupported;
        }
        if (isStartupPage) {
            return t.TerminalFilters;
        }
        return "";
    };

    const filteredTags = filterValues.filter(
        (filterValue) => filterValue.enabled
    );
    const filteredTagsNameAndValue = filteredTags.map((filterValue) => ({
        name: filterValue.translatedName,
        value: filterValue.id,
    }));

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                width: "100%",
            })}
        >
            <TooltipLarge title={renderTooltipText()}>
                <span
                    css={css({
                        maxWidth: "246px",
                        width: "100%",
                    })}
                >
                    <Select
                        value={[]}
                        onChange={handleChange}
                        placeholder={t.Filter}
                        disabled={isFiltersDisabled}
                        wrapperStyle={css`
                            scroll-margin: 16px;
                            width: 100%;
                        `}
                        multiple
                    >
                        {filterValues.map((item) => {
                            return (
                                <MenuItem
                                    key={item.name}
                                    value={item.id}
                                    css={{ padding: "14px 2px 14px 16px" }}
                                >
                                    <ListItemText
                                        primary={item.translatedName}
                                        sx={{
                                            ".MuiListItemText-primary": {
                                                fontWeight: "600",
                                                fontSize: "14px",
                                            },
                                        }}
                                    />
                                    <ListItemIcon>
                                        <Checkbox checked={item.enabled} />
                                    </ListItemIcon>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </span>
            </TooltipLarge>
            {!isFiltersDisabled && !!filteredTagsNameAndValue.length && (
                <FilteredByBlock
                    filteredTagsNameAndValue={filteredTagsNameAndValue}
                    onChipDelete={(value) => handleChipDelete(value)}
                    onClearAll={() => handleClearAll()}
                    additionalStyles={{
                        padding: "0px",
                        marginTop: "8px",
                        marginBottom: "4px",
                    }}
                />
            )}
        </div>
    );
};

export default FilterSelect;
