/** @jsxRuntime classic */

/** @jsx jsx */
import { SerializedStyles, jsx } from "@emotion/react";

import MuiSwitch from "@mui/material/Switch";

import { forwardRef, useContext } from "react";

import ThemeContext from "../../../context/theme/themeContext";

type Ref = HTMLInputElement;

export type SwitchComponentSizes = "small" | "medium" | "large";

interface SwitchComponentProps {
    size: SwitchComponentSizes;
    wrapperStyle?: SerializedStyles;
    checked: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    idForTesting: string;
    disabled?: boolean;
}

const SwitchComponent = forwardRef<Ref, SwitchComponentProps>(
    (
        { size, wrapperStyle, checked, onChange, idForTesting, ...props },
        ref,
    ) => {
        const {
            colors: { white, gray400, blue700, blue800, blue1000, blue400 },
        } = useContext(ThemeContext);

        const sizes = {
            large: {
                rootWidth: "48px",
                rootHeight: "24px",
                thumbWidth: "22px",
                thumbHeight: "22px",
                thumbCheckedTransform: "translateX(24px)",
            },
            medium: {
                rootWidth: "36px",
                rootHeight: "20px",
                thumbWidth: "18px",
                thumbHeight: "18px",
                thumbCheckedTransform: "translateX(16px)",
            },
            small: {
                rootWidth: "30px",
                rootHeight: "16px",
                thumbWidth: "14px",
                thumbHeight: "14px",
                thumbCheckedTransform: "translateX(14px)",
            },
        };

        const rootWidth = sizes[size].rootWidth;
        const rootHeight = sizes[size].rootHeight;
        const thumbWidth = sizes[size].thumbWidth;
        const thumbHeight = sizes[size].thumbHeight;
        const thumbCheckedTransform = sizes[size].thumbCheckedTransform;

        return (
            <MuiSwitch
                {...props}
                disableRipple
                data-test={idForTesting}
                inputRef={ref}
                checked={checked}
                onChange={onChange}
                sx={{
                    width: rootWidth,
                    height: rootHeight,
                    padding: "0",

                    "& .MuiSwitch-switchBase": {
                        backgroundColor: white,
                        padding: "0",
                        top: "1px",
                        left: "1px",

                        "&.Mui-checked": {
                            transform: thumbCheckedTransform,
                            color: white,
                        },

                        "&.Mui-disabled, &.Mui-checked.Mui-disabled": {
                            color: white,
                        },

                        "&.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: blue700,
                            opacity: "1",
                        },

                        "&.Mui-disabled + .MuiSwitch-track": {
                            backgroundColor: gray400,
                            opacity: "1",
                        },
                    },

                    "& .MuiSwitch-thumb": {
                        width: thumbWidth,
                        height: thumbHeight,
                        boxShadow: "unset",
                    },

                    "& .MuiSwitch-track": {
                        backgroundColor: blue400,
                        borderRadius: "12px",
                        opacity: "1",
                    },

                    "&:hover": {
                        "& .MuiSwitch-track": {
                            backgroundColor: blue1000,
                        },

                        "& .MuiSwitch-switchBase": {
                            "&.Mui-checked + .MuiSwitch-track": {
                                backgroundColor: blue800,
                            },

                            "&.Mui-disabled + .MuiSwitch-track": {
                                backgroundColor: gray400,
                            },
                        },
                    },
                }}
                css={wrapperStyle}
            />
        );
    },
);

export default SwitchComponent;
