/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { jsx } from "@emotion/react";

import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import NewNumberInput from "../../../../MuiComponents/reusableInputs/NewNumberInput";

export interface NumberInputProps {
    data: any;
    onChange?: (e: { id: number; value: string; error?: string }) => void;
    withoutLabelAndId?: boolean;
    isGeoInput?: boolean;
    otherInputSx?: any;
    stacked?: boolean;
    withoutLabel?: boolean;
    resetErrorOnUnmount?: boolean;
}

const NumberInput = ({
    data,
    onChange,
    withoutLabelAndId,
    isGeoInput,
    otherInputSx,
    stacked,
    withoutLabel,
    resetErrorOnUnmount,
}: NumberInputProps) => {
    const { parameterId, parameterValue, minId, maxId } = data;

    const { findParameterValueById } = useContext(MenuItemContext);

    return (
        <NewNumberInput
            data={data}
            componentValue={
                findParameterValueById(parameterId) || parameterValue
            }
            dependentMaxValue={findParameterValueById(maxId)}
            dependentMinValue={findParameterValueById(minId)}
            isGeoInput={isGeoInput}
            stacked={stacked}
            withoutLabel={withoutLabel}
            otherInputSx={otherInputSx}
            onChange={onChange}
            withoutLabelAndId={withoutLabelAndId}
            resetErrorOnUnmount={resetErrorOnUnmount}
        />
    );
};

export default NumberInput;
