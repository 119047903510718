/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";

import useUpdateFunctions from "./useUpdateFunctions";
import DownloadProgress from "./DownloadProgress";
import Button from "../../../../MuiComponents/Button";
import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";

const UpdateButton = () => {
    const { t } = useContext(LanguageContext);

    const {
        downloadingPercentage,
        updateInformation: { isUpdateDownloading, isUpdateDownloaded },
    } = useContext(SettingsContext);

    const { handleUpdate } = useUpdateFunctions();

    const renderControlText = () => {
        if (isUpdateDownloading) {
            return t.Downloading;
        } else if (isUpdateDownloaded) {
            return t.RestartAndInstall;
        }
        return t.Update;
    };

    return isUpdateDownloading ? (
        <DownloadProgress
            downloadText={renderControlText()}
            downloadingPercentage={downloadingPercentage}
            percentageWidth="32px"
        />
    ) : (
        <Button
            variant="textOnly"
            size="small"
            color="primary"
            css={css({ marginLeft: "8px" })}
            onClick={handleUpdate}
            disabled={isUpdateDownloading}
            idForTesting="updateButton"
        >
            {renderControlText()}
        </Button>
    );
};

export default UpdateButton;
