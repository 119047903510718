/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/ErrorRounded";

import Tag from "../../../../MuiComponents/Tag";
import DialogModal from "../../../../MuiComponents/Modals/DialogModal";
import Divider from "../../../../MuiComponents/Divider";
import Button from "../../../../MuiComponents/Button";
import InputField from "../../../../MuiComponents/InputField";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import ConfirmModal from "../../../../MuiComponents/Modals/ConfirmModal";
import UserKeyTable from "../../../../MuiComponents/Table";
import SearchInput from "../../../../MuiComponents/SearchInput";
import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import useApi from "../../../../../utils/useApi";
import useDebounce from "../../../../../utils/useDebounce";
import { AlertStatus, TableTypes } from "../../../../../constants/constants";
import AlertContext from "../../../../../context/alert/alertContext";
import { UserKey } from "../../../../../generatedTypes";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import ConfigurationContext from "../../../../../context/configuration/configurationContext";

export interface ActivateKeyModalProps {}

const ActivateKeyModal: React.FunctionComponent<ActivateKeyModalProps> = () => {
    const { setAlert } = useContext(AlertContext);

    const { selectedLanguage, t } = useContext(LanguageContext);

    const { getExperimentalConfigurations } = useContext(ConfigurationContext);

    const {
        colors: { red700, gray700, gray200 },
    } = useContext(ThemeContext);

    const { isActivateKeyModalOpen, closeActivateKeyModal } =
        useContext(SettingsContext);

    const { getData, postData, showErrorMsg } = useApi();
    const { toMd } = useMediaQueries();

    const [tableData, setTableData] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [keyValue, setKeyValue] = useState("");
    const [parsedData, setParsedData] = useState<null | UserKey>(null);
    const [keyName, setKeyName] = useState("");
    const [refetch, setRefetch] = useState(false);
    const [error, setError] = useState<string>("");

    const deleteConfirmationModalInitialState = {
        open: false,
        ids: [],
    };

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<{
        open: boolean;
        ids: string[];
    }>(deleteConfirmationModalInitialState);

    useEffect(() => {
        const getTableView = async () => {
            try {
                const { data } = await getData(`${selectedLanguage}/userkeys`);
                setTableData(data);
            } catch (error) {
                console.log(error);
            }
        };
        getTableView();
        setRefetch(false);
    }, [refetch]);

    const handleOpenDeleteConfirmation = (selectedIds: string[]) => {
        setDeleteConfirmationModal({ open: true, ids: selectedIds });
    };

    const handleDelete = async () => {
        try {
            await postData(
                `${selectedLanguage}/userkeys/batchDelete`,
                deleteConfirmationModal.ids
            );

            setRefetch(true);
            setAlert(AlertStatus.Success, t.TableKeysDeleted);
            setError("");
            setDeleteConfirmationModal(deleteConfirmationModalInitialState);
        } catch (_err) {
            setError("");
            setAlert(AlertStatus.Critical, t.TableKeysDeleteFailed);
            setDeleteConfirmationModal(deleteConfirmationModalInitialState);
        }
    };

    const handleSearch = (searchValue: string) => {
        setSearchValue(searchValue);
    };

    const resetInputValues = (resetSearch?: boolean) => {
        setKeyValue("");
        setKeyName("");
        setError("");
        resetSearch && setSearchValue("");
    };

    const handleAdd = async () => {
        try {
            await postData(`${selectedLanguage}/userkeys`, {
                ...parsedData,
                name: keyName,
            });
            resetInputValues();
            setParsedData(null);
            setRefetch(true);
        } catch (err) {
            showErrorMsg(err as any, t.ErrorUnexpected);
        }
    };

    const handleValueParse = async (value: string) => {
        try {
            if (!value) {
                setError("");
                return;
            }
            const { data } = await postData(
                `${selectedLanguage}/userkeys/parse`,
                {
                    value: String(value),
                }
            );
            setParsedData(data);
            setError("");
            data.name && setKeyName(data.name);
        } catch (err: any) {
            const error = err.response?.data;
            setError(error.detail);
            setAlert(AlertStatus.Critical, error.detail);
        }
    };

    const debouncedParseHandler = useDebounce(handleValueParse, 500);

    const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setKeyValue(e.target.value);
            debouncedParseHandler(e.target.value);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Fragment>
            <DialogModal
                open={isActivateKeyModalOpen}
                close={() => {
                    closeActivateKeyModal();
                    getExperimentalConfigurations();
                }}
                title={t.UserKeys}
                sx={{
                    maxWidth: "920px",
                }}
                TransitionProps={{
                    onExited: () => {
                        resetInputValues(true);
                    },
                }}
                description={
                    <Fragment>
                        {t.AccelerometerCapturedDescription}
                        <Divider
                            additionalCss={{
                                marginTop: "12px",
                                marginBottom: "16px",
                            }}
                        />
                        <TypedGrid
                            container
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <TypedGrid item xs0={5}>
                                <InputField
                                    fullWidth
                                    size="medium"
                                    label={t.AddNewSpecOrFotaKey}
                                    value={keyValue}
                                    onChange={handleOnChange}
                                    wrapperStyle={css({
                                        scrollMargin: "16px",
                                    })}
                                    placeholder={`${t.Example} 7ca44868d2ba977a0bc38cbb5489`}
                                    maxRows={4}
                                    info={t.AddNewSpecOrFotaKeyTooltip}
                                    withoutTooltipPadding
                                    multiline
                                />
                            </TypedGrid>

                            <TypedGrid item xs0={5}>
                                <InputField
                                    fullWidth
                                    size="medium"
                                    label={t.KeyName}
                                    value={keyName}
                                    placeholder={`${t.Example} Experimental ID key 1`}
                                    onChange={(e) => setKeyName(e.target.value)}
                                    wrapperStyle={css({
                                        scrollMargin: "16px",
                                    })}
                                />
                            </TypedGrid>

                            <TypedGrid
                                item
                                xs0={2}
                                css={css({
                                    marginTop: "24px",
                                })}
                            >
                                <Button
                                    fullWidth
                                    variant="textOnly"
                                    size="normal"
                                    color="primary"
                                    idForTesting="add-key"
                                    onClick={handleAdd}
                                    disabled={!keyValue || !keyName || !!error}
                                >
                                    {t.AddSpecOrFotaKey}
                                </Button>
                            </TypedGrid>
                        </TypedGrid>

                        {error && (
                            <div
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "12px 0px",
                                })}
                            >
                                <ErrorIcon
                                    css={css({
                                        color: red700,
                                        fontSize: "16px",
                                        marginRight: "4px",
                                    })}
                                />
                                <span
                                    css={css({
                                        color: gray700,
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        letterSpacing: "0.1px",
                                    })}
                                >
                                    {error}
                                </span>
                            </div>
                        )}

                        <div css={css({ marginTop: "16px" })}>
                            <div
                                style={{
                                    padding: toMd ? "16px 24px" : "18px 24px",
                                    border: `1px solid ${gray200}`,
                                    borderBottom: "unset",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }}
                            >
                                <div
                                    css={css({
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    })}
                                >
                                    <div
                                        css={css({
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                        })}
                                    >
                                        <span
                                            css={css({
                                                fontWeight: 600,
                                                fontSize: "20px",
                                                lineHeight: "24px",
                                                letterSpacing: "0.15px",
                                            })}
                                        >
                                            {t.FotaOrSpecKeys}
                                        </span>
                                    </div>

                                    <div
                                        css={css({
                                            maxWidth: "180px",
                                        })}
                                    >
                                        <SearchInput
                                            onChange={(e) =>
                                                handleSearch(e.target.value)
                                            }
                                            resetValue={() => handleSearch("")}
                                            searchValue={searchValue}
                                        />
                                    </div>
                                </div>
                            </div>
                            <UserKeyTable
                                data={tableData}
                                searchValue={searchValue}
                                tableType={TableTypes.UserKeys}
                                onDeleteClick={handleOpenDeleteConfirmation}
                            />
                        </div>
                    </Fragment>
                }
                customButtons={
                    <TypedGrid container>
                        <Button
                            fullWidth
                            variant="textOnly"
                            size="small"
                            color="secondary"
                            idForTesting="close-key"
                            disabled={false}
                            onClick={() => closeActivateKeyModal()}
                            css={css({
                                height: "44px",
                            })}
                        >
                            {t.Close}
                        </Button>
                    </TypedGrid>
                }
            />
            <ConfirmModal
                title={`${t.DeleteKey}?`}
                description={t["DeleteKey.description"]}
                open={deleteConfirmationModal.open}
                close={() =>
                    setDeleteConfirmationModal(
                        deleteConfirmationModalInitialState
                    )
                }
                submit={handleDelete}
            >
                <Divider />
                <div
                    css={css({
                        padding: "24px",
                    })}
                >
                    <Tag
                        size="medium"
                        color="primary"
                        title={`${t.SelectedKeys}: ${deleteConfirmationModal.ids.length}`}
                    />
                </div>
                <Divider />
            </ConfirmModal>
        </Fragment>
    );
};

export default ActivateKeyModal;
