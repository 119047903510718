import { createContext } from "react";

import { Trigger } from "../../../../../../generatedTypes";

export interface TriggerContextState {
    sourceCollection: any;
    setSourceCollection: (
        value: any,
        hiddenItems: { [id: string]: string }
    ) => void;
    updateSourceCollection: (id: number, value: string) => void;
    hiddedValues: string[];
    getSourceCollection: (
        selectedParameterValue: string,
        isEdit?: boolean
    ) => any;
    setModalData: (value: any) => void;
    modalData: Trigger;
    setValues: (value: any) => void;
    values: any;
    setSubmitLoading: (value: boolean) => void;
    submitLoading: boolean;
    resetModalState: () => void;
    setErrors: (errors: any) => void;
    hasErrors: boolean;
    errors: any;
}

const TriggerContext = createContext({} as TriggerContextState);

export default TriggerContext;
