/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { InputLabel, Popover } from "@mui/material";
import { Fragment, useContext, useState } from "react";

import FilterSelect from "./FilterSelect";
import MoreFilters from "../../../shared/MoreFilters";
import {
    eventStatus,
    geozonesPriorityColumns,
    shapeColumns,
} from "../../../../../../constants/constants";
import ManualGeofenceContext from "../../../../../../context/manualGeofence/manualGeofenceContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../context/language/languageContext";

const MoreFiltersSelect = () => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(
        null
    );

    const {
        colors: { gray700 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const {
        activeShape,
        activeOperand,
        activePriority,
        setActiveShape,
        setActiveOperand,
        setActivePriority,
    } = useContext(ManualGeofenceContext);

    return (
        <Fragment>
            <InputLabel
                css={css({
                    position: "unset",
                    display: "inline-flex",
                })}
                onClick={(e) => setMenuAnchorEl(e.currentTarget as any)}
            >
                <MoreFilters />
            </InputLabel>
            <Popover
                onClose={() => setMenuAnchorEl(null)}
                open={!!menuAnchorEl}
                anchorEl={menuAnchorEl}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{
                    "& .MuiPaper-root": {
                        padding: "16px",
                        maxWidth: "211px",
                        width: "100%",
                    },
                }}
            >
                <div
                    css={css({
                        marginBottom: "16px",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: gray700,
                    })}
                >
                    {t.Filters}
                </div>
                <FilterSelect
                    columns={shapeColumns}
                    onChange={(e) => setActiveShape(e.target.value)}
                    value={activeShape}
                    name="Shape"
                />

                <FilterSelect
                    columns={geozonesPriorityColumns}
                    onChange={(e) => setActivePriority(e.target.value)}
                    value={activePriority}
                    name="Priority"
                />

                <FilterSelect
                    columns={eventStatus}
                    onChange={(e) => setActiveOperand(e.target.value)}
                    value={activeOperand}
                    name="Operand"
                />
            </Popover>
        </Fragment>
    );
};

export default MoreFiltersSelect;
