/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import Popover from "@mui/material/Popover";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NotificationsList from "./NotificationsList";
import Button from "../../../MuiComponents/Button";
import ThemeContext from "../../../../context/theme/themeContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import LanguageContext from "../../../../context/language/languageContext";
import useMediaQueries from "../../../../utils/useMediaQueries";

const NotificationsModal = () => {
    const {
        colors: { gray200, textDark },
    } = useContext(ThemeContext);

    const {
        isNotificationModalOpen,
        anchorEl,
        closeNotificationsModal,
        readAllCommonNotifications,
    } = useContext(SettingsContext);

    const handleClose = () => {
        readAllCommonNotifications();
        closeNotificationsModal();
    };

    const { t } = useContext(LanguageContext);
    const { toMd } = useMediaQueries();

    return (
        <Popover
            open={isNotificationModalOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference={toMd ? "anchorPosition" : "anchorEl"}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            marginThreshold={toMd ? 0 : 16}
            sx={{
                "& .MuiPaper-root": {
                    maxHeight: toMd ? "unset" : "626px",
                    height: toMd ? "100%" : "unset",
                    maxWidth: toMd ? "unset" : "566px",
                    width: toMd ? "100%" : "calc(100% - 284px)",
                    marginTop: toMd ? "0" : "10px",
                    overflowY: toMd ? "auto" : "hidden",
                    borderRadius: "12px",
                },
            }}
        >
            <div
                css={css({
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "16px 24px",

                    borderBottom: `1px solid ${gray200}`,

                    span: {
                        color: textDark,
                        fontSize: "20px",
                        lineHeight: "24px",
                        fontWeight: "600",
                        letterSpacing: "0.15px",
                        alignSelf: "flex-end",
                    },

                    "& > button": {
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    },
                })}
            >
                <span>{t.Notifications}</span>

                <Button
                    variant="iconOnly"
                    size="small"
                    color="white"
                    icon={<CloseIcon onClick={handleClose} />}
                    idForTesting="closeNotificationsModal"
                />
            </div>

            <NotificationsList />
        </Popover>
    );
};

export default NotificationsModal;
