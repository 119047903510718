/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useContext } from "react";
import { css, jsx } from "@emotion/react";
import { Popover } from "@mui/material";
import { useLocation } from "react-router-dom";
import { HubConnection } from "@microsoft/signalr";

import TextLink from "../../../../MuiComponents/TextLink";
import SinglePortPopupItem from "./SinglePortPopupItem";

import ThemeContext from "../../../../../context/theme/themeContext";
import TerminalContext from "../../../../../context/components/terminal/terminalContext";
import LayoutContext from "../../../../../context/layout/layoutContext";

import { TerminalPortType } from "../../../../../utils/types";

export interface SinglePortPopupProps {
    connection: HubConnection | null;
}

const SinglePortPopup: React.FunctionComponent<SinglePortPopupProps> = ({
    connection,
}) => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);
    const { layoutData } = useContext(LayoutContext);
    const {
        singlePortAnchorEl,
        setSinglePortListAnchorEl,
        setPortListAnchorEl,
        activePortsForDropdown,
        setActiveTerminalPort,
        activeTerminalPorts,
    } = useContext(TerminalContext);

    const location = useLocation();

    const isStartup = location.pathname === "/";

    const isOpen = !!singlePortAnchorEl;

    const activeEditorPort = layoutData?.port;

    const handleClose = () => {
        setSinglePortListAnchorEl(null);
        setPortListAnchorEl(null);
    };

    const handleGoBack = () => {
        setPortListAnchorEl(singlePortAnchorEl);
        setSinglePortListAnchorEl(null);
    };

    const handlePortAdd = (item: any) => {
        try {
            if (connection) {
                connection.invoke("ConnectToPortAsync", item.address);
            }

            const portInfo = {
                id: item.address,
                imei: activePortsForDropdown.imei,
                deviceName: activePortsForDropdown.modelName,
                port: item,
                isLogging: true,
            };

            setActiveTerminalPort(portInfo);
        } catch (err) {
            throw new ReferenceError(
                `Cannot invoke 'ConnectAsync'. Web sockets connection is ${connection}`
            );
        } finally {
            handleClose();
        }
    };

    return (
        <Popover
            open={isOpen}
            anchorEl={singlePortAnchorEl}
            onClose={handleClose}
            anchorReference={"anchorEl"}
            anchorPosition={{ top: 0, left: 0 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            marginThreshold={16}
            sx={{
                "& .MuiPaper-root": {
                    maxHeight: "626px",
                    height: "unset",
                    maxWidth: "304px",
                    width: "calc(100% - 284px)",
                    marginTop: "10px",
                    overflowY: "hidden",
                },
            }}
        >
            <div
                css={css({
                    display: "flex",
                    padding: "16px 30px 16px 24px",

                    "& > h2": {
                        color: textDark,
                        fontSize: "20px",
                        lineHeight: "24px",
                        fontWeight: "600",
                        letterSpacing: "0.15px",
                        alignSelf: "flex-end",
                    },
                })}
            >
                <TextLink
                    size="normal"
                    onClick={handleGoBack}
                    idForTesting="port-modal-back-button"
                    startIcon
                >
                    Go back
                </TextLink>
            </div>
            {activePortsForDropdown?.ports.map((item: any, index: any) => {
                const isAlreadyAdded = activeTerminalPorts.some(
                    (activeTerminalPort: any) =>
                        activeTerminalPort.id === item.address
                );

                const isDisabled =
                    (item.type === TerminalPortType.Device && isStartup) ||
                    (item.type === TerminalPortType.Device &&
                        activeEditorPort !== item.address);

                return (
                    <SinglePortPopupItem
                        portName={`${
                            item.type === 1 ? "Device" : "Debug"
                        } port ${item.address}`}
                        isDisabled={isDisabled}
                        isAlreadyAdded={isAlreadyAdded}
                        onClick={() => handlePortAdd(item)}
                        key={item.address}
                    />
                );
            })}
        </Popover>
    );
};

export default SinglePortPopup;
