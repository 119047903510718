/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Tooltip from "./Tooltip";
import LanguageContext from "../../context/language/languageContext";
import { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import { ReactComponent as FlaskIcon } from "../../assets/icons/Flask.svg";

const ExpFwIndicator: React.FunctionComponent<{}> = () => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { purple50, purple100 },
    } = useContext(ThemeContext);
    return (
        <Tooltip title={t.ExpFirmwareApplied}>
            <span
                css={css({
                    display: "flex",
                    height: "24px",
                    padding: "0px 8px",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "12px",
                    border: `1px solid ${purple100}`,
                    background: purple50,
                })}
            >
                <FlaskIcon />
            </span>
        </Tooltip>
    );
};

export default ExpFwIndicator;
