import React, { ReactNode } from "react";

import { MenuItem } from "@mui/material";

import { noop } from "../../../../utils/helpers";
import TooltipLarge from "../../TooltipLarge";

interface CollectionMenuItemProps {
    tooltipTitle?: string;
    tooltipButtonText?: string;
    tooltipOnBtnClick?: () => void;
    disabled: boolean;
    children: ReactNode;
    value: any;
}

const CollectionMenuItem = ({
    tooltipTitle,
    tooltipButtonText,
    tooltipOnBtnClick,
    disabled,
    children,
    ...props
}: CollectionMenuItemProps) => {
    if (disabled) {
        return (
            <TooltipLarge
                noMargin
                placement="top-start"
                title={tooltipTitle ?? ""}
                buttonText={tooltipButtonText ?? ""}
                onBtnClick={tooltipOnBtnClick || noop}
            >
                <li>
                    <MenuItem disabled={disabled} {...props} component="div">
                        {children}
                    </MenuItem>
                </li>
            </TooltipLarge>
        );
    }
    return <MenuItem {...props}>{children}</MenuItem>;
};
export default CollectionMenuItem;
