/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useMemo } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";
import useDeviceGuideProvider from "../../utils/useDeviceGuide";
import { DeviceGuideType } from "../../constants/constants";
import SettingsContext from "../../context/settings/settingsContext";
import LanguageContext from "../../context/language/languageContext";
import useMediaQueries from "../../utils/useMediaQueries";
import LayoutContext from "../../context/layout/layoutContext";
import ThemeContext from "../../context/theme/themeContext";
import Button from "../MuiComponents/Button";
import useDeviceGuideButtons from "./useDeviceGuideButtons";

export interface GuideTooltipProps {
    step: any;
    primaryProps: any;
    index: number;
    isLastStep: boolean;
    tooltipProps: any;
}

const GuideTooltip = ({
    step,
    index,
    isLastStep,
    tooltipProps,
}: GuideTooltipProps) => {
    const {
        colors: { gray700, white, textDarkDisabled },
    } = useContext(ThemeContext);

    const { isDeviceGuideButtonDisabled } = useContext(SettingsContext);

    const { t } = useContext(LanguageContext);
    const { deviceGuide } = useContext(LayoutContext);

    const currentStep = useMemo(
        () => deviceGuide?.guideSteps[index],
        [deviceGuide]
    );

    const { toSm } = useMediaQueries();

    const { handleDeviceSetupClose } = useDeviceGuideProvider();

    const getDeviceGuideStepsByType = (type: string) => {
        if (deviceGuide?.guideSteps) {
            return deviceGuide?.guideSteps.filter((entry) => {
                return entry.guideType === type;
            });
        }

        return [];
    };

    const stepGroupLength = getDeviceGuideStepsByType(
        currentStep?.guideType as string
    ).length;

    const getCurrentStepCount = () => {
        if (currentStep?.guideType === (DeviceGuideType.Optional as string))
            return Number(index) - 2;

        return Number(index) + 1;
    };

    const {
        getProceedButtonText,
        handleProceedButton,
        handlePreviousButton,
        showPreviousButton,
    } = useDeviceGuideButtons(
        isLastStep,
        index,
        stepGroupLength,
        getCurrentStepCount(),
        currentStep,
        step
    );

    return (
        <div
            css={css({
                padding: "16px",
                backgroundColor: gray700,
                maxWidth: toSm ? "216px" : "312px",
                width: "100%",
                borderRadius: "12px",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                color: white,
            })}
            {...tooltipProps}
        >
            <div
                css={css({
                    marginBottom: "8px",
                    fontWeight: 600,
                    display: "flex",

                    "span:first-of-type": {
                        color: textDarkDisabled,
                        marginRight: "8px",
                    },

                    "span:last-of-type": {
                        marginRight: "auto",
                    },
                })}
            >
                <span>
                    {getCurrentStepCount()}/{stepGroupLength}
                </span>

                <span>{step.title}</span>
                <div>
                    <CloseIcon
                        onClick={handleDeviceSetupClose}
                        css={css({
                            height: "20px",
                            cursor: "pointer",
                        })}
                    />
                </div>
            </div>

            <div css={css({ marginBottom: "16px" })}>{step.content}</div>

            <div
                css={css({
                    display: "flex",
                    gap: "8px",
                })}
            >
                {showPreviousButton() && (
                    <Button
                        variant="textOnly"
                        size="normal"
                        color="transparent"
                        fullWidth
                        onClick={handlePreviousButton}
                        idForTesting={`device-guide-previous-button-${index}`}
                        css={css({
                            flex: "0 0 80px",
                        })}
                    >
                        {t.Previous}
                    </Button>
                )}

                <Button
                    variant="textOnly"
                    size="normal"
                    color="secondary"
                    fullWidth
                    onClick={handleProceedButton}
                    disabled={isDeviceGuideButtonDisabled}
                    idForTesting={`device-guide-button-${index}`}
                    css={css({
                        flex: "1",
                    })}
                >
                    {getProceedButtonText()}
                </Button>
            </div>
        </div>
    );
};

export default GuideTooltip;
