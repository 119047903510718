import React, { useContext, useReducer } from "react";
import ConfigurationContext, {
    ExperimentalConfiguration,
} from "./configurationContext";
import ConfigurationReducer from "./configurationReducer";
import {
    SET_OFFLINE_CONFIGURATIONS,
    TOGGLE_LEGACY_CONFIGURATIONS,
    FILTER_CONFIGURATIONS,
    SEARCH_CONFIGURATIONS,
    SET_EXPERIMENTAL_CONFIGURATIONS,
    SET_EXPERIMENTAL_MODAL_OPEN,
    SET_EXPERIMENTAL_MODAL_VALUE,
} from "./configurationActions";
import SettingsContext from "../settings/settingsContext";
import useTct from "../../utils/useTct";

interface ConfigurationStateProps {
    children: React.ReactNode;
}

const ConfigurationState = ({ children }: ConfigurationStateProps) => {
    const { setNotification } = useContext(SettingsContext);

    const { getExperimentalConfigurationAsync } = useTct();

    const initialState = {
        allOffline: [],
        modernOffline: [],
        displayedOffline: [],
        experimentalConfigurations: undefined,
        isExperimentalModalOpen: false,
        filteredValues: [],
        searchValue: "",
        technologies: [],
        legacy: false,
        loading: true,
        experimentalModalValue: {
            value: "",
            default: false,
            active: false,
            path: "",
        },
    };

    const [state, dispatch] = useReducer(ConfigurationReducer, initialState);

    const setOfflineConfigurations = (configurations: {}) =>
        dispatch({ type: SET_OFFLINE_CONFIGURATIONS, payload: configurations });

    const toggleLegacyConfigurations = (showLegacy: boolean) =>
        dispatch({ type: TOGGLE_LEGACY_CONFIGURATIONS, payload: showLegacy });

    const filterConfigurations = (filteredValues: any[]) =>
        dispatch({ type: FILTER_CONFIGURATIONS, payload: filteredValues });

    const searchConfigurations = (searchValue: string) =>
        dispatch({ type: SEARCH_CONFIGURATIONS, payload: searchValue });

    const setExperimentalConfigurations = (
        configurations: ExperimentalConfiguration[]
    ) =>
        dispatch({
            type: SET_EXPERIMENTAL_CONFIGURATIONS,
            payload: configurations,
        });

    const setIsExperimentalModalOpen = (isOpen: boolean, hasClose?: boolean) =>
        dispatch({
            type: SET_EXPERIMENTAL_MODAL_OPEN,
            payload: {
                isOpen: isOpen,
                hasClose: hasClose,
            },
        });

    const getExperimentalConfigurations = async (
        onSuccess?: (data: any[]) => void
    ) => {
        try {
            const experimentalData = await getExperimentalConfigurationAsync();
            setExperimentalConfigurations(experimentalData);
            onSuccess && onSuccess(experimentalData);
        } catch (err: any) {
            const error = err.response?.data;
            setNotification("error", error.detail, error.detail);
            setExperimentalConfigurations([]);
            console.log(err);
        }
    };

    const setExperimentalModalValue = (value: any) => {
        dispatch({
            type: SET_EXPERIMENTAL_MODAL_VALUE,
            payload: value,
        });
    };

    const hasExperimentalFirmwareApplied =
        state.experimentalConfigurations?.some(
            (item: ExperimentalConfiguration) =>
                item.active && item.path !== "Default"
        );

    return (
        <ConfigurationContext.Provider
            value={{
                selectedConfiguration: state.selectedConfiguration,
                offlineConfigurations: state.displayedOffline,
                filteredValues: state.filteredValues,
                searchValue: state.searchValue,
                technologies: state.technologies,
                loading: state.loading,
                experimentalConfigurations: state.experimentalConfigurations,
                isExperimentalModalOpen: state.isExperimentalModalOpen,
                hasExperimentalModalClose: state.hasExperimentalModalClose,
                experimentalModalValue: state.experimentalModalValue,
                hasExperimentalFirmwareApplied,
                setOfflineConfigurations,
                toggleLegacyConfigurations,
                filterConfigurations,
                searchConfigurations,
                setExperimentalConfigurations,
                setIsExperimentalModalOpen,
                getExperimentalConfigurations,
                setExperimentalModalValue,
            }}
        >
            {children}
        </ConfigurationContext.Provider>
    );
};

export default ConfigurationState;
