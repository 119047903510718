/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from "@emotion/react";

import { useContext } from "react";

import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import { Component } from "../../../../../generatedTypes";
import NewCollection from "../../../../MuiComponents/reusableInputs/NewCollection";

export interface CollectionProps {
    data: Component;
    onChange?: (event: { id: number; value: string }) => void;
    hasNoDependencies?: boolean;
    withoutLabelAndId?: boolean;
    defaultValue?: string | number;
}

const Collection = ({
    data,
    hasNoDependencies,
    withoutLabelAndId,
    defaultValue,
    onChange,
}: CollectionProps) => {
    const { parameterValue, collectionConfig, parameterId } = data;

    const hasAnEmptyValue = (value: string) =>
        collectionConfig?.collectionItems.some(
            (item) => item.isInvisible && item.value === value,
        )
            ? ""
            : value;

    const { findParameterValueById } = useContext(MenuItemContext);

    return (
        <NewCollection
            componentValue={
                findParameterValueById(parameterId) ||
                hasAnEmptyValue(parameterValue)
            }
            defaultValue={defaultValue}
            data={data}
            onChange={onChange}
            hasNoDependencies={hasNoDependencies}
            withoutLabelAndId={withoutLabelAndId}
        />
    );
};

export default Collection;
