/** @jsxRuntime classic */

/** @jsx jsx */
import { useContext } from "react";

import { css, jsx } from "@emotion/react";

import LanguageContext from "../../../../../context/language/languageContext";
import Badge from "../../../Badge";
import Checkbox from "../../../Checkbox";
import Tooltip from "../../../Tooltip";

export interface FirmwareWithVersionsProps {
    title: string;
    currentVersion: string;
    newVersion: string;
    onCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isCheckboxDisabled?: boolean;
    hasCheckbox?: boolean;
}

const FirmwareWithVersions: React.FunctionComponent<
    FirmwareWithVersionsProps
> = ({
    title,
    currentVersion,
    newVersion,
    onCheckboxChange,
    isCheckboxDisabled,
    hasCheckbox,
}) => {
    const { t } = useContext(LanguageContext);

    return (
        <div>
            <span
                css={css({
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px", // 142.857%
                    letterSpacing: "0.1px",
                })}
            >
                {title}
            </span>
            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                    marginBottom: "20px",
                    gap: "8px",
                })}
            >
                {hasCheckbox && (
                    <Checkbox
                        onChange={onCheckboxChange}
                        disabled={isCheckboxDisabled}
                    />
                )}
                <div
                    css={css({
                        display: "flex",
                        gap: "8px",
                    })}
                >
                    <Tooltip
                        title={currentVersion.length > 9 ? currentVersion : ""}
                        small
                    >
                        <span>
                            <Badge color="light">
                                <div
                                    css={css({
                                        maxWidth: "122px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                    })}
                                >
                                    {t.FirmwareVersionCurrent}: {currentVersion}
                                </div>
                            </Badge>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={newVersion.length > 9 ? newVersion : ""}
                        small
                    >
                        <span>
                            <Badge color="success">
                                <div
                                    css={css({
                                        maxWidth: "122px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                    })}
                                >
                                    {t.FirmwareVersionNew}: {newVersion}
                                </div>
                            </Badge>
                        </span>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default FirmwareWithVersions;
