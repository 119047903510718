import {
    GO_TO_NEXT_VIEW,
    HANDLE_CURRENT_PIN,
    HANDLE_NEW_PIN,
    HANDLE_NEW_REPEATED_PIN,
    SET_LOADING,
    WRONG_CURRENT_PIN,
} from "./changePinActions";
import LanguageContext from "../../../../../../../context/language/languageContext";
import { useContext } from "react";
import { chooseValue } from "../../../../../../../utils/helpers";

export interface InitialState {
    isLoading: boolean;
    isDisabled: boolean;
    step: number;
    currentPin: string | null;
    newPin: string | null;
    newRepeatedPin: string | null;
    explanatoryText: string;
    submitButtonText: string;
    errorMsg: string | null;
    retriesLeft: number | null;
}

export type Action =
    | {
        type: typeof SET_LOADING;
        payload: boolean;
    }
    | {
        type: typeof HANDLE_CURRENT_PIN;
        payload: string | null;
    }
    | {
        type: typeof HANDLE_NEW_PIN;
        payload: string | null;
    }
    | {
        type: typeof HANDLE_NEW_REPEATED_PIN;
        payload: string | null;
    }
    | {
        type: typeof GO_TO_NEXT_VIEW;
    }
    | {
        type: typeof WRONG_CURRENT_PIN;
        payload: number;
    };

export default (state: InitialState, action: Action) => {
    const { t } = useContext(LanguageContext);

    const goToNextView = () => {
        return {
            ...state,
            isDisabled: true,
            step: ++state.step,
            explanatoryText: chooseValue(state.step === 3, t.RepeatNewCode, t.EnterNewCode),
            submitButtonText: chooseValue(state.step === 3, t.Change, t.Continue),
        };
    };

    const handleNewRepeatedPin = (payload: string | null) => {
        const doNotMatch =
            Boolean(state.newPin) &&
            Boolean(payload) &&
            payload !== state.newPin;

        return {
            ...state,
            isDisabled: !Boolean(payload) || doNotMatch,
            step: chooseValue(doNotMatch, 2, state.step),
            newPin: chooseValue(doNotMatch, null, state.newPin),
            newRepeatedPin: chooseValue(doNotMatch, null, payload),
            explanatoryText: chooseValue(doNotMatch, t.EnterNewCode, state.explanatoryText),
            submitButtonText: chooseValue(doNotMatch, t.Continue, state.submitButtonText),
            errorMsg: chooseValue(doNotMatch, t.NewPinCodesDoNotMatch),
        };
    };

    const handleNewPin = (payload: string | null) => {
        return {
            ...state,
            isDisabled: !Boolean(payload),
            newPin: payload,
        };
    };

    const handleCurrentPin = (payload: string | null) => {
        return {
            ...state,
            isDisabled: !Boolean(payload),
            currentPin: payload,
        };
    };

    const setLoading = (payload: boolean) => {
        return {
            ...state,
            isLoading: payload,
            errorMsg: chooseValue(payload, null, state.errorMsg),
            retriesLeft: chooseValue(payload, null, state.retriesLeft),
        };
    };

    const onWrongCurrentPin = (payload: number) => {
        return {
            ...state,
            errorMsg: t.IncorrectCurrentPinCode,
            retriesLeft: payload - 1,
        };
    };

    switch (action.type) {
        case SET_LOADING: {
            return setLoading(action.payload);
        }
        case HANDLE_CURRENT_PIN: {
            const { payload } = action;
            return handleCurrentPin(payload);
        }
        case HANDLE_NEW_PIN: {
            const { payload } = action;
            return handleNewPin(payload);
        }
        case HANDLE_NEW_REPEATED_PIN: {
            const { payload } = action;
            return handleNewRepeatedPin(payload);
        }
        case GO_TO_NEXT_VIEW: {
            return goToNextView();
        }
        case WRONG_CURRENT_PIN: {
            return onWrongCurrentPin(action.payload);
        }
        default:
            return state;
    }
};
