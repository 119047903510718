/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import moment from "moment";
import { useAtom } from "jotai";

import DownloadProgress from "./DownloadProgress";
import useUpdateFunctions from "./useUpdateFunctions";
import Timestamp from "../NotificationsList/SingleNotification/Timestamp";
import StatusBadge from "../NotificationsList/SingleNotification/StatusBadge";
import { NOTIFICATION_STATUS } from "../NotificationsList/SingleNotification/StatusBadge/types";
import TextLink from "../../../../MuiComponents/TextLink";
import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import { TctUpdateAvailableAtom } from "../../../../../context/webSockets/webSocketsState";
import { DATE_FORMATS } from "../../../../../constants/constants";

const SingleUpdateNotification = () => {
    const {
        colors: { blue100, gray200, gray700, textDark, textPlaceholder, white },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const {
        downloadingPercentage,
        latestNotifications,
        updateInformation: { isUpdateDownloading, isUpdateDownloaded },
        closeNotificationsModal,
        openVersionHistoryModal,
    } = useContext(SettingsContext);

    const [{ releaseDate, size, version, isRead }, setIsTctUpdateAvailable] =
        useAtom(TctUpdateAvailableAtom);

    const { handleUpdate } = useUpdateFunctions();

    const handleOpenChangelog = () => {
        closeNotificationsModal();
        openVersionHistoryModal();
    };

    const handleLinkClick = () => {
        setIsTctUpdateAvailable((prevState) => ({
            ...prevState,
            isRead: true,
        }));

        isUpdateDownloaded ? handleUpdate() : handleOpenChangelog();
    };

    return (
        <div>
            <div
                css={css({
                    color: textPlaceholder,
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    padding: "12px 16px 4px 16px",
                })}
            >
                {t.Update}
            </div>

            <li
                css={css(
                    {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: isRead ? white : blue100,
                        padding: "12px 16px",
                    },

                    latestNotifications.length > 0 && {
                        borderBottom: `1px solid ${gray200}`,
                    }
                )}
            >
                <div
                    css={css({
                        display: "flex",
                    })}
                >
                    <StatusBadge status={NOTIFICATION_STATUS.Update} />

                    <div>
                        <div
                            css={css({
                                color: textDark,
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                            })}
                        >
                            {t.ConfiguratorUpdateAvailable}
                        </div>
                        <div
                            css={css({
                                color: gray700,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                marginTop: "4px",
                                wordBreak: "break-word",
                            })}
                        >
                            {version}
                            <span> - </span>
                            {size}
                        </div>

                        {!isUpdateDownloading && (
                            <TextLink
                                size="small"
                                onClick={handleLinkClick}
                                idForTesting="updateLinkNotification"
                            >
                                {isUpdateDownloaded
                                    ? t.RestartAndInstall
                                    : t.UpdateNow}
                            </TextLink>
                        )}
                    </div>
                </div>

                <div
                    css={css({
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-start",
                        flexDirection: "column",
                        "& > svg, & > label": {
                            marginLeft: "8px",
                        },
                    })}
                >
                    <Timestamp
                        date={moment(releaseDate ?? undefined)
                            .utc()
                            .format(DATE_FORMATS.ISO_8601)}
                    />
                    <DownloadProgress
                        downloadText={
                            isUpdateDownloading ? t.Downloading : undefined
                        }
                        downloadingPercentage={downloadingPercentage}
                    />
                </div>
            </li>
        </div>
    );
};

export default SingleUpdateNotification;
