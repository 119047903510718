const createProgressObject = (bundleProgress: any) => ({
    [bundleProgress.stage]: {
        ...bundleProgress,
        completedPercent: bundleProgress.completedPercent || 0,
    },
    current: {
        ...bundleProgress,
        completedPercent: bundleProgress.completedPercent || 0,
    },
});

const mergeProgress = (currentProgress: any, bundleProgress: any) => ({
    ...currentProgress,
    [bundleProgress.stage]: {
        ...bundleProgress,
        completedPercent: bundleProgress.completedPercent || 0,
    },
    current: {
        ...bundleProgress,
        completedPercent: bundleProgress.completedPercent || 0,
    },
});

const getStepProgress = (bundleProgress: any, currentProgress: any) => {
    if (!bundleProgress) return currentProgress ? { ...currentProgress } : null;
    return currentProgress
        ? mergeProgress(currentProgress, bundleProgress)
        : createProgressObject(bundleProgress);
};

const createStepDetails = (progress: any) => ({
    upload: {
        status: progress?.FileUpload?.status || "Waiting",
    },
    update: {
        status: progress?.DeviceReboot?.status || "Waiting",
    },
    current: {
        status: progress?.current?.status || "Waiting",
        completedPercent: progress?.current?.completedPercent || 0,
        stage: progress?.current?.stage || "",
    },
});

export const handleSetShouldStepUpdate = (action: any, state: any) => {
    const { mcuType, fileType, shouldUpdate } = action.payload;
    const steps = state.bundleModalProgressSteps.map((step: any) =>
        step.mcuType === mcuType && step.fileType === fileType
            ? { ...step, shouldUpdate }
            : step,
    );

    return { ...state, bundleModalProgressSteps: steps };
};

export const handleSetBundleModalProgressSteps = (action: any, state: any) => {
    const { progress: bundleProgress, steps } = action.payload;

    const details = steps.map((step: any) => {
        const progressBarName = `firmware_bundle_${step.mcuType}_${step.fileType}`;
        const currentProgress = state.bundleModalProgressSteps?.find(
            (detail: any) => detail.progressBarName === progressBarName,
        )?.progress;

        const progress = getStepProgress(
            bundleProgress?.progressBarName === progressBarName
                ? bundleProgress
                : null,
            currentProgress,
        );

        return {
            ...step,
            progressBarName,
            progress,
            details: createStepDetails(progress),
        };
    });

    return { ...state, bundleModalProgressSteps: details };
};
