/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import { AccordionSummary, AccordionDetails, Accordion } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import ThemeContext from "../../../context/theme/themeContext";
import TypedGrid from "../TypedGrid";
import Tag from "../Tag";
import AccordionItem from "./AccordionItem";

export type AccordionItems = {
    itemName: string;
    itemDescription: string;
    customItemBadge: React.ReactNode;
    onItemClick: () => void;
    isFeatureOff: boolean;
    index: number;
    hasNoBadge?: boolean;
}[];

export interface AccordionWithFramesProps {
    name: string;
    description: string;
    items: AccordionItems;
    withBadges: boolean;
    expanded?: boolean;
    hasExpandIcon?: boolean;
    customTitleBadge?: React.ReactNode;
    unsetItemMinHeight?: boolean;
}

const AccordionWithFrames = ({
    name,
    description,
    items,
    withBadges,
    expanded,
    customTitleBadge,
    hasExpandIcon = true,
    unsetItemMinHeight = false,
}: AccordionWithFramesProps) => {
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);
 
    return (
        <Accordion
            sx={{
                padding: "24px 24px 16px 24px",
                borderRadius: "8px",
                "&.MuiAccordion-root:before": {
                    display: "none",
                },
            }}
            expanded={expanded}
            disableGutters
            square
        >
            <AccordionSummary
                expandIcon={
                    hasExpandIcon && (
                        <span
                            css={css({
                                display: "flex",
                                width: "36px",
                                height: "36px",
                                background: gray100,
                                borderRadius: "6px",
                                justifyContent: "center",
                                alignItems: "center",
                            })}
                        >
                            <ExpandMoreIcon />
                        </span>
                    )
                }
                sx={{
                    padding: "0px",
                    ".MuiAccordionSummary-content": { margin: "0px" },
                    ".MuiAccordionSummary-expandIconWrapper": {
                        alignSelf: "baseline",
                        transition: "unset",
                        marginLeft: "8px",
                    },
                }}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <TypedGrid container>
                    <TypedGrid
                        item
                        xs0={12}
                        css={css({
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "12px",

                            "& > div:last-of-type": {
                                alignSelf: "baseline",
                            },
                        })}
                    >
                        <div
                            css={css({
                                fontWeight: 600,
                                lineHeight: "24px",
                                fontSize: "20px",
                                letterSpacing: "0.15px",
                                marginRight: "8px",
                            })}
                        >
                            {name}
                        </div>

                        {customTitleBadge}
                    </TypedGrid>
                    <TypedGrid item xs0={12}>
                        <div
                            css={css({
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                marginBottom: "12px",
                            })}
                        >
                            {description}
                        </div>
                    </TypedGrid>
                    <TypedGrid item xs0={12}>
                        {withBadges &&
                            items.map((item, i) => {
                                const badgeColor = () => {
                                    if (!item?.isFeatureOff) {
                                        return "primary";
                                    }
                                    return "white";
                                };
                                return (
                                    <div
                                        key={`${item.itemName}${i}`}
                                        css={css({
                                            marginRight: "12px",
                                            marginTop: "8px",
                                            display: "inline-flex",
                                        })}
                                    >
                                        <Tag
                                            size="medium"
                                            title={item.itemName}
                                            color={badgeColor()}
                                            onClick={item.onItemClick}
                                        />
                                    </div>
                                );
                            })}
                    </TypedGrid>
                </TypedGrid>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: "8px 0px",
                }}
            >
                <TypedGrid container>
                    {items.map((item, i) => (
                        <AccordionItem
                            item={item}
                            key={i}
                            unsetItemMinHeight={unsetItemMinHeight}
                        />
                    ))}
                </TypedGrid>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionWithFrames;
