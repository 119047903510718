/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

import { Fragment, useContext } from "react";
import Badge, { BadgeColors } from "../../../../MuiComponents/Badge";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import Divider from "../../../../MuiComponents/Divider";
import Tooltip from "../../../../MuiComponents/Tooltip";
import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import { useOverflow } from "../../../../../utils/useOverflow";
import { GnssStatusInformationData } from "../../../../../generatedTypes";

interface GnssStatusProps {
    data: GnssStatusInformationData;
    keyBaseStyles: SerializedStyles;
    valueBaseStyles: SerializedStyles;
}

const GnssStatus = ({
    data,
    keyBaseStyles,
    valueBaseStyles,
}: GnssStatusProps) => {
    const {
        colors: { gray700 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { elementRef, isOverflowing } = useOverflow();

    const {
        moduleStatus,
        gnssPackets,
        fixStatus,
        fixTime,
        gpsVisible,
        gpsInUse,
        glonassVisible,
        glonassInUse,
        beiDouVisible,
        beiDouInUse,
        galileoVisible,
        galileoInUse,
        irnssVisible,
        irnssInUse,
        totalVisible,
        totalInUse,
        gnssVersion,
        timeToFirstFix,
        timeToLastFix,
    } = data;

    return (
        <div>
            {gnssVersion && (
                <Fragment>
                    <div css={css({ margin: "6px 0" })}>
                        <TypedGrid
                            container
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.GnssVersion}
                                </div>
                            </TypedGrid>

                            <TypedGrid
                                item
                                xs0={6}
                                css={css({
                                    span: {
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "block",
                                    },
                                })}
                            >
                                <Tooltip
                                    title={isOverflowing && gnssVersion}
                                    placement="top"
                                    keepListenerEnabled={isOverflowing}
                                >
                                    <span ref={elementRef}>{gnssVersion}</span>
                                </Tooltip>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}
            <div css={css({ margin: "6px 0" })}>
                <TypedGrid container columnSpacing={2} alignItems="center">
                    <TypedGrid item xs0={6}>
                        <div css={css({ ...keyBaseStyles })}>
                            {t.ModuleStatus}
                        </div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        {moduleStatus && (
                            <Badge
                                color={
                                    moduleStatus.color as unknown as BadgeColors
                                }
                            >
                                {moduleStatus.label}
                            </Badge>
                        )}
                    </TypedGrid>
                </TypedGrid>
            </div>

            <Divider />

            <div css={css({ margin: "8px 0" })}>
                <TypedGrid container columnSpacing={2} alignItems="center">
                    <TypedGrid item xs0={6}>
                        <div css={css({ ...keyBaseStyles })}>
                            {t.GnssPackets}
                        </div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        <div css={css({ ...valueBaseStyles })}>
                            {gnssPackets}
                        </div>
                    </TypedGrid>
                </TypedGrid>
            </div>

            <Divider />

            <div css={css({ margin: "6px 0" })}>
                <TypedGrid container columnSpacing={2} alignItems="center">
                    <TypedGrid item xs0={6}>
                        <div css={css({ ...keyBaseStyles })}>{t.FixStatus}</div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        {fixStatus && (
                            <Badge
                                color={
                                    fixStatus.color as unknown as BadgeColors
                                }
                            >
                                {fixStatus.label}
                            </Badge>
                        )}
                    </TypedGrid>
                </TypedGrid>
            </div>

            <Divider />

            {fixTime && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid
                            container
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.FixTime}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <div css={css({ ...valueBaseStyles })}>
                                    {fixTime}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}
            {timeToFirstFix && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid
                            container
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.TimeToFirstFix}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <div css={css({ ...valueBaseStyles })}>
                                    {timeToFirstFix}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}
            {timeToLastFix && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid
                            container
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.TimeToLastFix}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <div css={css({ ...valueBaseStyles })}>
                                    {timeToLastFix}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            <div css={css({ margin: "34px 0 10px 0" })}>
                <TypedGrid container columnSpacing={2}>
                    <TypedGrid item xs0={6}>
                        <div
                            css={css([
                                {
                                    ...valueBaseStyles,
                                },
                                { fontWeight: 600, color: gray700 },
                            ])}
                        >
                            {t.Satellites}
                        </div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        <TypedGrid container columnSpacing={2}>
                            <TypedGrid item xs0={6}>
                                <div
                                    css={css([
                                        {
                                            ...valueBaseStyles,
                                        },
                                        { color: gray700 },
                                    ])}
                                >
                                    {t.Visible}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <div
                                    css={css([
                                        {
                                            ...valueBaseStyles,
                                        },
                                        { color: gray700 },
                                    ])}
                                >
                                    {t.InUse}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </TypedGrid>
                </TypedGrid>
            </div>

            <Divider />

            {gpsInUse >= 0 && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid container columnSpacing={2}>
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.Gps}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <TypedGrid container columnSpacing={2}>
                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {gpsVisible}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {gpsInUse}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {glonassInUse >= 0 && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid container columnSpacing={2}>
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.Glonass}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <TypedGrid container columnSpacing={2}>
                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {glonassVisible}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div
                                            css={css`
                                                ${valueBaseStyles};
                                            `}
                                        >
                                            {glonassInUse}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {beiDouInUse >= 0 && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid container columnSpacing={2}>
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.BeiDou}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <TypedGrid container columnSpacing={2}>
                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {beiDouVisible}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {beiDouInUse}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {galileoInUse >= 0 && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid container columnSpacing={2}>
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.Galileo}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <TypedGrid container columnSpacing={2}>
                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {galileoVisible}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {galileoInUse}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            {irnssInUse >= 0 && (
                <Fragment>
                    <div css={css({ margin: "8px 0" })}>
                        <TypedGrid container columnSpacing={2}>
                            <TypedGrid item xs0={6}>
                                <div css={css({ ...keyBaseStyles })}>
                                    {t.Irnss}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <TypedGrid container columnSpacing={2}>
                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {irnssVisible}
                                        </div>
                                    </TypedGrid>

                                    <TypedGrid item xs0={6}>
                                        <div css={css({ ...valueBaseStyles })}>
                                            {irnssInUse}
                                        </div>
                                    </TypedGrid>
                                </TypedGrid>
                            </TypedGrid>
                        </TypedGrid>
                    </div>

                    <Divider />
                </Fragment>
            )}

            <div css={css({ margin: "8px 0" })}>
                <TypedGrid container columnSpacing={2}>
                    <TypedGrid item xs0={6}>
                        <div
                            css={css([
                                {
                                    ...valueBaseStyles,
                                },
                                { fontWeight: 600 },
                            ])}
                        >
                            {t.Satellites}
                        </div>
                    </TypedGrid>

                    <TypedGrid item xs0={6}>
                        <TypedGrid container columnSpacing={2}>
                            <TypedGrid item xs0={6}>
                                <div
                                    css={css([
                                        {
                                            ...valueBaseStyles,
                                        },
                                        { fontWeight: 600 },
                                    ])}
                                >
                                    {totalVisible}
                                </div>
                            </TypedGrid>

                            <TypedGrid item xs0={6}>
                                <div
                                    css={css([
                                        {
                                            ...valueBaseStyles,
                                        },
                                        { fontWeight: 600 },
                                    ])}
                                >
                                    {totalInUse}
                                </div>
                            </TypedGrid>
                        </TypedGrid>
                    </TypedGrid>
                </TypedGrid>
            </div>
        </div>
    );
};

export default GnssStatus;
