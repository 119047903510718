/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import ThemeContext from "../../../context/theme/themeContext";
import { BadgeIcon } from "./BadgeIcon";

export type BadgeColors =
    | "light"
    | "neutral"
    | "info"
    | "success"
    | "warning"
    | "critical";

export interface BadgeProps {
    children: React.ReactNode;
    color: BadgeColors;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    inverted?: boolean;
}
const Badge = ({
    children,
    color, // light, neutral, info, success, warning, critical
    iconLeft,
    iconRight,
    inverted, // true, false
}: BadgeProps) => {
    const {
        colors: {
            white,
            gray100,
            gray200,
            blue100,
            blue200,
            blue700,
            green50,
            green100,
            green700,
            green900,
            orange50,
            orange100,
            orange700,
            red50,
            red100,
            red700,
            red800,
            textWarning,
            textDark,
        },
    } = useContext(ThemeContext);

    const lightBadge = {
        text: inverted ? white : textDark,
        border: inverted ? textDark : gray200,
        background: inverted ? textDark : white,
    };

    const neutralBadge = {
        text: textDark,
        border: gray200,
        background: gray100,
    };

    const infoBadge = {
        text: inverted ? white : blue700,
        border: inverted ? blue700 : blue200,
        background: inverted ? blue700 : blue100,
    };

    const successBadge = {
        text: inverted ? white : green900,
        border: inverted ? green700 : green100,
        background: inverted ? green700 : green50,
    };

    const warningBadge = {
        text: inverted ? white : textWarning,
        border: inverted ? orange700 : orange100,
        background: inverted ? orange700 : orange50,
    };

    const criticalBadge = {
        text: inverted ? white : red800,
        border: inverted ? red700 : red100,
        background: inverted ? red700 : red50,
    };

    const styleVariants = {
        light: lightBadge,
        neutral: neutralBadge,
        info: infoBadge,
        success: successBadge,
        warning: warningBadge,
        critical: criticalBadge,
    };

    const textColor = styleVariants[color].text;
    const borderColor = styleVariants[color].border;
    const bgColor = styleVariants[color].background;

    return (
        <div
            css={css({
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.4px",
                borderRadius: "12px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "3px 8px",
                color: textColor,
                backgroundColor: bgColor,
                border: `1px solid ${borderColor}`,
                whiteSpace: "nowrap",
                span: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    svg: {
                        fontSize: "16px",
                    },
                },
            })}
        >
            <BadgeIcon icon={iconLeft} cssStyle={{marginRight: "4px"}} />
            <span>{children}</span>
            <BadgeIcon icon={iconRight} cssStyle={{marginLeft: "4px"}}/>
        </div>
    );
};

export default Badge;
