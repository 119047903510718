/** @jsxRuntime classic */

/** @jsx jsx */
import React, { SyntheticEvent, useContext } from "react";

import { css, jsx } from "@emotion/react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import LanguageContext from "../../../context/language/languageContext";
import Button from "../Button";
import Divider from "../Divider";

interface ConfirmModalProps {
    open: boolean;
    close: () => void;
    submit: (e: SyntheticEvent) => any;
    title: string;
    description?: string;
    primaryBtnLabel?: string;
    secondaryBtnLabel?: string;
    additionalStyles?: any;
    onSecondaryButtonClick?: (e: SyntheticEvent) => void;
    children?: React.ReactNode;
    withDivider?: boolean;
}

const ConfirmModal = ({
    open,
    close,
    submit,
    title,
    description,
    primaryBtnLabel,
    secondaryBtnLabel,
    additionalStyles,
    onSecondaryButtonClick,
    children,
    withDivider = true,
    ...props
}: ConfirmModalProps) => {
    const { t } = useContext(LanguageContext);

    return (
        <Dialog
            onClose={close}
            open={open}
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: "448px",
                    ...additionalStyles,
                },
            }}
            {...props}
        >
            <DialogTitle
                sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    marginRight: "24px",
                    paddingBottom: description ? "8px" : "12px",
                }}
            >
                {title}

                <Button
                    variant="iconOnly"
                    size="small"
                    color="white"
                    icon={<CloseIcon onClick={close} />}
                    css={css({
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                    })}
                    idForTesting={"closeConfirmModal"}
                />
            </DialogTitle>

            <form noValidate onSubmit={submit}>
                {description && (
                    <DialogContent
                        sx={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            fontWeight: "600",
                            padding: "0 16px 12px 16px",
                        }}
                    >
                        {description}
                    </DialogContent>
                )}
                {withDivider && <Divider />}
                {children}
                <DialogActions
                    sx={{
                        "button:last-of-type": {
                            marginLeft: "12px",
                        },
                    }}
                >
                    <Button
                        variant="textOnly"
                        size="normal"
                        color="secondary"
                        fullWidth
                        onClick={onSecondaryButtonClick || close}
                        idForTesting={"cancelConfirmModal"}
                    >
                        {secondaryBtnLabel ?? t.No}
                    </Button>

                    <Button
                        focus
                        variant="textOnly"
                        size="normal"
                        color="primary"
                        fullWidth
                        onClick={submit}
                        idForTesting={"confirmConfirmModal"}
                    >
                        {primaryBtnLabel ?? t.Yes}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ConfirmModal;
