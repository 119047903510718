import React, { useMemo } from "react";
import ThemeContext from "./themeContext";

interface ThemeStateProps {
    children: React.ReactNode;
}

const ThemeState = ({ children }: ThemeStateProps) => {
    const state = {
        colors: {
            white: "#FFFFFF",
            black: "#000000",
            gray50: "#F7F9FB",
            gray100: "#F1F4F8",
            gray200: "#E2E7EE",
            gray300: "#CCD4DF",
            gray400: "#96A2B5",
            gray500: "#677488",
            gray600: "#4A5567",
            gray700: "#334155",
            gray800: "#20293A",
            blue100: "#EFF5FD",
            blue200: "#DDE9FB",
            blue300: "#C3D9FA",
            blue400: "#A6C8FF",
            blue500: "#78A9FF",
            blue600: "#0061FC",
            blue700: "#003FB4",
            blue800: "#001A77",
            blue900: "#06113B",
            purple300: "#A483D8",
            purple600: "#8D64CE",
            blueSideNavigation: "#0B265A",
            grey80: "#263238",
            cyan300: "#67E8F9",
            cyan600: "#0891B2",
            green50: "#F0FDF4",
            green100: "#DCFCE7",
            green200: "#BBF7D0",
            green600: "#16A34A",
            green700: "#15803D",
            green800: "#166534",
            green900: "#14532D",
            orange50: "#FFF7ED",
            orange100: "#FFEDD5",
            orange200: "#FED7AA",
            orange300: "#FDBA74",
            orange400: "#FB923C",
            orange500: "#F97316",
            orange600: "#EA580C",
            orange700: "#C2410C",
            red50: "#FEF2F2",
            red100: "#FEE2E2",
            red200: "#FECACA",
            red300: "#FCA5A5",
            red600: "#DC2626",
            red700: "#B91C1C",
            red800: "#991B1B",
            red900: "#7F1D1D",
            textIconDark: "#162D3D",
            textDark: "#111729",
            textSecondary: "#4A5567",
            textPlaceholder: "#697D95",
            textPrimaryBlue: "#003FB4",
            textDarkDisabled: "#A6B6C8",
            textWhite: "#FFFFFF",
            textSuccess: "#14532D",
            textWarning: "#7C2D12",
            inkNormal: "#252A31",
            geozoneGray: "#DDDDDD44",
            sliderGreen: "#6FEB9D",
            purple50: "#F4F0FA",
            purple100: "#DDD1F0",
        },
        boxShadows: {
            alertBoxShadow:
                "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 3px rgba(0, 0, 0, 0.1)",
            boxComponentShadow:
                "0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12)",
            blueBoxShadow:
                "inset 0px -1px 0px #C5E2FF, inset 0px 1px 0px #C5E2FF",
            grayBoxShadow: "inset 0px -1px 0px #DFE5EB",
            deviceGuideBoxShadow: "0px 4px 16px rgba(15, 23, 42, 0.16)",
            ioTableCardBoxShadow: `inset 0px -1px 0px #CBD5E1`,
            focusBoxShadow:
                "-2px -2px 0px #C5E2FF, -2px 2px 0px #C5E2FF, 2px -2px 0px #C5E2FF, 2px 2px 0px #C5E2FF",
            paperBoxShadow:
                "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)",
        },
    };

    const contextValue = useMemo(() => {
        return {
            colors: { ...state.colors },
            boxShadows: { ...state.boxShadows },
        };
    }, [state]);

    return (
        <ThemeContext.Provider value={contextValue}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeState;
