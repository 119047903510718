/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import TerminalContext from "../../../context/components/terminal/terminalContext";
import { ReactComponent as DragHandleIcon } from "../../../assets/icons/terminal/DragHandle.svg";
import useMediaQueries from "../../../utils/useMediaQueries";

export interface RndWindowProps {
    children: React.ReactNode;
}

const RndWindow: React.FC<RndWindowProps> = ({ children }) => {
    const {
        terminalSize: { width, height },
        setTerminalSize,
    } = useContext(TerminalContext);

    const location = useLocation();
    const { toMd } = useMediaQueries();

    const [isResizing, setIsResizing] = useState(false);
    const [startY, setStartY] = useState(0);

    const currentPath = location.pathname;
    const isStartup = currentPath === "/";

    const footerHeight = isStartup ? 0 : 56;
    const minTerminalHeight = 88;
    const maxTerminalHeight = window.innerHeight * 0.8;
    const leftBlockWidth = 236;

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsResizing(true);
        setStartY(e.clientY);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isResizing) {
            const newHeight = Math.max(
                minTerminalHeight,
                Math.min(maxTerminalHeight, height - (e.clientY - startY))
            );
            setTerminalSize({
                width: window.innerWidth,
                height: newHeight,
            });
            setStartY(e.clientY);
        }
    };

    const handleMouseUp = () => {
        setIsResizing(false);
    };

    const handleResize = () => {
        setTerminalSize({
            width: window.innerWidth,
            height: Math.min(height, window.innerHeight - footerHeight),
        });
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("resize", handleResize);
        };
    }, [isResizing, height]);

    useEffect(() => {
        const initialHeight = Math.min(
            window.innerHeight / 2,
            window.innerHeight - footerHeight
        );
        setTerminalSize({
            width: window.innerWidth,
            height: initialHeight,
        });
    }, []);

    return (
        <div
            css={css({
                position: "fixed",
                bottom: `${footerHeight}px`,
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "flex-end",
                isolation: "isolate",
                zIndex: 10,
                marginLeft: isStartup || toMd ? 0 : `${leftBlockWidth}px`,
            })}
        >
            <div
                css={css({
                    flex: 1,
                    maxWidth: `${width}px`,
                    height: `${height}px`,
                    minHeight: `${minTerminalHeight}px`,
                    maxHeight: `calc(100vh - ${footerHeight}px)`,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow:
                        "0px -6px 14px -6px rgba(24, 39, 75, 0.08), 0px -10px 32px -4px rgba(24, 39, 75, 0.06)",
                    position: "relative",
                    zIndex: 2,
                    isolation: "isolate",
                    background: "white",
                })}
            >
                <div
                    css={css({
                        height: "4px",
                        width: "100%",
                        cursor: "ns-resize",
                        position: "relative",
                        top: "-4px",
                        zIndex: 3,
                    })}
                    onMouseDown={handleMouseDown}
                >
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "center",
                            background: "white",
                        })}
                    >
                        <DragHandleIcon />
                    </div>
                </div>
                <div
                    css={css({
                        flex: 1,
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        zIndex: 1,
                    })}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default RndWindow;
