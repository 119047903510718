/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, Theme } from "@emotion/react";
import {
    ListItemText,
    MenuItem,
    SelectChangeEvent,
    SxProps,
} from "@mui/material";
import { useContext } from "react";

import { useStyles } from "./styles/styles";
import Select from "../../../../../../../MuiComponents/Select";
import Checkbox from "../../../../../../../MuiComponents/Checkbox";
import {
    IoTableColumns,
    priorityColumns,
} from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import ThemeContext from "../../../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../../../context/language/languageContext";

const PrioritySelect = () => {
    const { setActivePriority, activePriority, activeColumns } =
        useContext(InputOutputContext);

    const {
        colors: { white, gray100, textPlaceholder },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { listItemTextCSS, menuItemCSS } = useStyles(white, gray100);

    const handleChange = (event: SelectChangeEvent<any>) => {
        const value = event.target.value;
        setActivePriority(value);
    };
    const isCheckboxChecked = (value: string) => activePriority.includes(value);

    const isDisabled = !activeColumns.some(
        (item) => item === IoTableColumns.priority
    );

    return (
        <Select
            multiple
            value={activePriority}
            onChange={handleChange}
            wrapperStyle={css({ marginBottom: "12px" })}
            placeholder={t.Priority}
            renderValue={() => {
                return (
                    <div css={css({ color: textPlaceholder })}>
                        {t.Priority}
                    </div>
                );
            }}
            disabled={isDisabled}
        >
            {priorityColumns.map((column: any) => (
                <MenuItem
                    disableRipple
                    key={column.name}
                    value={column.value}
                    css={css(menuItemCSS)}
                >
                    <ListItemText
                        primary={column.name}
                        sx={listItemTextCSS as SxProps<Theme>}
                    />

                    <Checkbox checked={isCheckboxChecked(column.value)} />
                </MenuItem>
            ))}
        </Select>
    );
};

export default PrioritySelect;
