/** @jsxRuntime classic */
/** @jsx jsx */
import { SyntheticEvent, useContext } from "react";
import { css, jsx } from "@emotion/react";

import { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import { CircularProgress } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Button from "../Button";
import CloseIcon from "@mui/icons-material/CloseRounded";
import LinkButton from "../Button/LinkButton";
import { noop } from "../../../utils/helpers";
import Tooltip from "../Tooltip";
import LanguageContext from "../../../context/language/languageContext";
import ThemeContext from "../../../context/theme/themeContext";

interface DialogModalProps {
    close?: () => void;
    illustration?: string;
    title?: string;
    hideCloseIcon?: boolean;
    description?: string | React.ReactNode;
    onPrimaryBtnClick?: (e: SyntheticEvent) => any;
    primaryButtonText?: string;
    onSecondaryBtnClick?: (e: SyntheticEvent) => any;
    secondaryButtonText?: string;
    withCancel?: boolean;
    customButtons?: React.ReactNode;
    isPrimaryButtonDisabled?: boolean;
    primaryButtonDisabledTooltipText?: string;
    secondaryButtonDisabledTooltipText?: string;
    isSecondaryButtonDisabled?: boolean;
    sx?: any;
    isPrimaryButtonLoading?: boolean;
    children?: React.ReactNode;
    secondaryButtonColor?: "secondary" | "primarySubtle";
}

const DialogModal = ({
    close,
    illustration,
    title,
    hideCloseIcon,
    description,
    onPrimaryBtnClick,
    primaryButtonText,
    onSecondaryBtnClick,
    secondaryButtonText,
    withCancel,
    customButtons,
    isPrimaryButtonDisabled,
    primaryButtonDisabledTooltipText,
    isSecondaryButtonDisabled,
    secondaryButtonDisabledTooltipText,
    sx,
    isPrimaryButtonLoading,
    children,
    secondaryButtonColor = "primarySubtle",
    ...props
}: DialogModalProps & MuiDialogProps) => {
    const { t } = useContext(LanguageContext);

    const {
        colors: { white },
    } = useContext(ThemeContext);

    return (
        <MuiDialog
            onClose={close}
            sx={{
                "& .MuiDialog-paper": {
                    padding: "16px",
                    maxWidth: "448px",
                    boxSizing: "border-box",
                    ...sx,
                },

                "& .MuiDialogTitle-root + .MuiDialogContent-root": {
                    paddingTop: "4px",
                },
            }}
            {...props}
        >
            {!hideCloseIcon && (
                <Button
                    variant={"iconOnly"}
                    size="small"
                    color="white"
                    icon={<CloseIcon onClick={close} />}
                    css={css`
                        position: absolute;
                        top: 4px;
                        right: 4px;
                    `}
                    idForTesting={"closeDialogModal"}
                />
            )}

            {illustration && (
                <div
                    css={css`
                        width: 120px;
                        height: 120px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-sizing: border-box;
                        margin-bottom: 8px;
                        margin: 0 auto 8px auto;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    `}
                >
                    <img src={illustration} alt="" />
                </div>
            )}

            <MuiDialogTitle
                sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    padding: "8px 0 4px 0",
                    marginRight: !hideCloseIcon && !illustration ? "24px" : "0",
                }}
            >
                {title}
            </MuiDialogTitle>

            {description && (
                <DialogContent
                    sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        padding: "4px 0",
                    }}
                >
                    {description}
                </DialogContent>
            )}
            {children}
            <MuiDialogActions
                sx={{
                    alignSelf: "stretch",
                    display: "block",
                    padding: "12px 0 0 0",

                    "& > button": {
                        width: "100%",
                    },
                    "& > button:not(:first-of-type)": {
                        marginLeft: "0px",
                    },

                    "& > button + button": {
                        marginTop: "8px",
                    },
                }}
            >
                {onPrimaryBtnClick && primaryButtonText && (
                    <Tooltip
                        title={primaryButtonDisabledTooltipText || ""}
                        small
                        placement="top"
                    >
                        <span
                            css={css({
                                display: "block",
                                width: "100%",
                                height: "100%",
                            })}
                        >
                            <Button
                                variant="textOnly"
                                size="normal"
                                color="primary"
                                fullWidth
                                onClick={onPrimaryBtnClick}
                                disabled={isPrimaryButtonDisabled}
                                idForTesting={"primaryBtn"}
                            >
                                {isPrimaryButtonLoading ? (
                                    <div
                                        css={css({
                                            width: "20px",
                                            height: "20px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        })}
                                    >
                                        <CircularProgress
                                            size={20}
                                            css={css`
                                                color: ${white};
                                            `}
                                        />
                                    </div>
                                ) : (
                                    primaryButtonText
                                )}
                            </Button>
                        </span>
                    </Tooltip>
                )}

                {onSecondaryBtnClick && secondaryButtonText && (
                    <Tooltip
                        title={secondaryButtonDisabledTooltipText || ""}
                        small
                        placement="top"
                    >
                        <span
                            css={css({
                                display: "block",
                                width: "100%",
                                height: "100%",
                                marginLeft: "0px !important",
                            })}
                        >
                            <Button
                                variant="textOnly"
                                size="normal"
                                color={secondaryButtonColor}
                                fullWidth
                                onClick={onSecondaryBtnClick}
                                disabled={isSecondaryButtonDisabled}
                                idForTesting={"secondaryBtn"}
                            >
                                {secondaryButtonText}
                            </Button>
                        </span>
                    </Tooltip>
                )}
                {customButtons ? customButtons : ""}
                {withCancel && (
                    <span
                        css={css({
                            display: "block",
                            width: "100%",
                            height: "100%",
                            marginLeft: "0px !important",
                        })}
                    >
                        <LinkButton
                            variant="textOnly"
                            size="normal"
                            color="primary"
                            fullWidth
                            onClick={close ? close : noop}
                            idForTesting={"cancelBtn"}
                        >
                            {t.Cancel}
                        </LinkButton>
                    </span>
                )}
            </MuiDialogActions>
        </MuiDialog>
    );
};

export default DialogModal;
