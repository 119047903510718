import React, { useContext, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";

import { ReactComponent as TerminalArrowIcon } from "../../../../assets/icons/terminal/TerminalArrow.svg";
import { PortMessageType } from "../../../../constants/constants";
import TerminalContext from "../../../../context/components/terminal/terminalContext";
import LanguageContext from "../../../../context/language/languageContext";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";
import ThemeContext from "../../../../context/theme/themeContext";
import useDebounce from "../../../../utils/useDebounce";
import useApi from "../../../../utils/useApi";
import Tooltip from "../../../MuiComponents/Tooltip";
import Button from "../../../MuiComponents/Button";

export interface CommandInputProps {
    messagesEndRef: React.RefObject<HTMLDivElement>;
    autoFocus: boolean;
}

const CommandInput: React.FunctionComponent<CommandInputProps> = ({
    messagesEndRef,
    autoFocus,
}) => {
    const {
        colors: {
            blue700,
            gray100,
            gray300,
            gray400,
            gray700,
            textDark,
            white,
        },
        boxShadows: { focusBoxShadow },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const {
        activeTab,
        command,
        commandHistory,
        commandHistoryIndex,
        clearPortMessagesByPort,
        setCommand,
        setCommandHistory,
        setCommandHistoryIndex,
        setPortMessages,
    } = useContext(TerminalContext);

    const { isDeviceDisconnected } = useContext(UserInfoContext);

    const [readOnly, setReadOnly] = useState(false);

    const { postData } = useApi();

    const handleScrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const debouncedScrollToBottom = useDebounce(handleScrollToBottom, 500);
    const isCommandEmpty = command === "";

    const handleSendMessage = async () => {
        if (!isCommandEmpty) {
            setCommandHistory(command);
            setCommandHistoryIndex(-1);
            setCommand("");

            if (command === "/clear") {
                clearPortMessagesByPort(activeTab?.port?.address);
                return;
            }
            setReadOnly(true);
            await postData(`/terminal/deviceCommand`, {
                port: activeTab?.port?.address,
                command,
            });
            setPortMessages([
                {
                    message: `${t.CommandSent}: “${command}“`,
                    port: activeTab?.port?.address,
                    type: PortMessageType.Command,
                },
            ]);

            setReadOnly(false);
            debouncedScrollToBottom();
        }
    };

    const handleKeyDown = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSendMessage();
        } else if (event.key === "ArrowUp") {
            event.preventDefault();
            if (commandHistoryIndex < commandHistory.length - 1) {
                setCommandHistoryIndex(commandHistoryIndex + 1);
                setCommand(commandHistory[commandHistoryIndex + 1]);
            }
        } else if (event.key === "ArrowDown") {
            event.preventDefault();
            if (commandHistoryIndex > 0) {
                setCommandHistoryIndex(commandHistoryIndex - 1);
                setCommand(commandHistory[commandHistoryIndex - 1]);
            } else {
                setCommandHistoryIndex(-1);
                setCommand("");
            }
        }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommand(event.target.value);
        if (event.target.value === "") {
            setCommandHistoryIndex(-1);
        }
    };

    const renderTooltipTitle = () => {
        if (isDeviceDisconnected) {
            return t.DeviceIsDisconnected;
        }
        return "";
    };

    const inputBgColor = readOnly ? gray100 : white;

    return (
        <Tooltip title={renderTooltipTitle()} small placement="top">
            <TextField
                autoFocus={autoFocus}
                fullWidth
                variant="filled"
                placeholder={t.CommandInputPlaceholder}
                value={command}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                disabled={isDeviceDisconnected}
                InputProps={{
                    readOnly: readOnly,
                    spellCheck: "false",
                    disableUnderline: true,

                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant="iconOnly"
                                size="tiny"
                                color="white"
                                fullWidth
                                disabled={isCommandEmpty}
                                onClick={handleSendMessage}
                                icon={
                                    <TerminalArrowIcon width={16} height={16} />
                                }
                                idForTesting="send-command-btn-terminal"
                            />
                        </InputAdornment>
                    ),
                    sx: {
                        background: inputBgColor,
                        color: textDark,
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        padding: "6px 12px",
                        borderRadius: "6px",
                        fontSize: "14px",
                        border: `1px solid ${gray300}`,
                        "&:hover": {
                            borderColor: gray400,
                            backgroundColor: inputBgColor,
                        },

                        "&.Mui-disabled": {
                            backgroundColor: gray100,
                        },

                        "&.Mui-focused": {
                            ...(!readOnly
                                ? {
                                      borderColor: blue700,
                                      borderWidth: "1px",
                                      boxShadow: focusBoxShadow,
                                  }
                                : {}),
                            backgroundColor: inputBgColor,
                        },

                        "& .MuiFilledInput-input": {
                            padding: "0",
                            "&::placeholder": {
                                color: gray700,
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                            },
                        },

                        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)":
                            {
                                marginTop: "0",
                            },

                        "& .MuiInputAdornment-root": {
                            color: gray700,
                            height: "unset",
                        },
                    },
                }}
            />
        </Tooltip>
    );
};

export default CommandInput;
