/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";
import { useContext } from "react";
import Button from "@mui/material/Button";
import RightIcon from "@mui/icons-material/ChevronRightRounded";
import LeftIcon from "@mui/icons-material/ChevronLeftRounded";

import ThemeContext from "../../context/theme/themeContext";

type TextLinkSizes = "small" | "normal" | "large";

export interface TextLinkProps {
    children: React.ReactNode;
    size: TextLinkSizes;
    critical?: boolean;
    endIcon?: boolean;
    startIcon?: boolean;
    onClick?: (e?: any) => void;
    css?: SerializedStyles;
    idForTesting: string;
    isDisabled?: boolean;
    component?: any;
    href?: string;
    target?: string;
}
const TextLink = ({
    children,
    size,
    critical,
    endIcon,
    startIcon,
    idForTesting,
    isDisabled,
    ...props
}: TextLinkProps) => {
    const {
        colors: { blue800, blue900, blue700, red800, red900 },
    } = useContext(ThemeContext);

    const styleVariants = {
        fontSize: {
            small: "12px",
            normal: "14px",
            large: "16px",
        },
        lineHeight: {
            small: "16px",
            normal: "20px",
            large: "20px",
        },
        letterSpacing: {
            small: "0.4px",
            normal: "0.1px",
            large: "0.15px",
        },
    };

    const fontSize = styleVariants.fontSize[size];
    const lineHeight = styleVariants.lineHeight[size];
    const letterSpacing = styleVariants.letterSpacing[size];

    return (
        <Button
            {...props}
            disableRipple
            data-test={idForTesting}
            startIcon={startIcon && <LeftIcon />}
            endIcon={endIcon && <RightIcon />}
            sx={{
                "& .MuiButton-endIcon": {
                    marginRight: "0",
                    marginLeft: "2px",

                    "& svg": {
                        fontSize: "16px",
                    },
                },
                "& .MuiButton-startIcon": {
                    marginRight: "2px",
                    marginLeft: "0",

                    "& svg": {
                        fontSize: "16px",
                    },
                },
            }}
            css={css({
                color: critical ? red800 : blue700,
                padding: "0",
                fontSize,
                lineHeight,
                letterSpacing,
                "&:hover": {
                    textDecoration: "underline",
                    color: critical ? red800 : blue800,
                    backgroundColor: "unset",
                },

                "&:active": {
                    color: critical ? red900 : blue900,
                    backgroundColor: "unset",
                },
            })}
            disabled={isDisabled}
        >
            {children}
        </Button>
    );
};

export default TextLink;
