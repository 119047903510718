import { createContext, MouseEvent } from "react";
import { InitialState, NotificationType } from "./settingsReducer";
import { FEElement } from "../../generatedTypes";

interface SettingsContextState extends InitialState {
    openNotificationsModal: (e: MouseEvent<HTMLButtonElement>) => void;
    closeNotificationsModal: () => void;
    openSettingsModal: (e?: MouseEvent<HTMLButtonElement>) => void;
    closeSettingsModal: () => void;
    openActivateKeyModal: (e?: MouseEvent<HTMLButtonElement>) => void;
    closeActivateKeyModal: () => void;
    openGeneralSettingsModal: () => void;
    closeGeneralSettingsModal: () => void;
    openTerminal: () => void;
    closeTerminal: () => void;
    openInterfaceSettingsModal: () => void;
    closeInterfaceSettingsModal: () => void;
    openLanguageSettingsModal: () => void;
    closeLanguageSettingsModal: () => void;
    openVersionHistoryModal: () => void;
    openVersionInfoModal: () => void;
    closeVersionInfoModal: () => void;
    closeVersionHistoryModal: () => void;
    openPrivacyPolicyModal: () => void;
    setNotification: (
        type: NotificationType,
        title: string,
        description: string,
        dynamicContent?: string
    ) => void;
    readAllCommonNotifications: () => void;
    readNotification: (id: string) => void;
    hideExplanatoryTexts: (isHidden: boolean) => void;
    hideParameterIds: (isHidden: boolean) => void;
    setReleaseNotes: (releaseNotes: FEElement[] | null) => void;
    setUpdateDownloaded: () => void;
    setUpdateDownloading: (state: boolean) => void;
    setDownloadingPercentage: (state: number | null) => void;
    openSystemInformationModal: () => void;
    closeSystemInformationModal: () => void;
    openDeviceSetUpModal: (e: MouseEvent<HTMLButtonElement>) => void;
    closeDeviceSetUpModal: () => void;
    closeDeviceGuideSteps: () => void;
    setDeviceGuideStepIndex: (step: number | null) => void;
    openDeviceGuideSteps: () => void;
    setDeviceGuideButtonDisabled: (disabled: boolean) => void;
    setIsDeviceGuideInProgress: (isInProgress: boolean) => void;
}

const SettingsContext = createContext({} as SettingsContextState);

export default SettingsContext;
