import { createContext } from "react";

export interface ExperimentalConfiguration {
    active: boolean;
    default: boolean;
    description: string;
    name: string;
    path: string;
}

interface ConfigurationContextState {
    selectedConfiguration: any[];
    offlineConfigurations: any[];
    filteredValues: any[];
    searchValue: string;
    technologies: any[];
    loading: boolean;
    experimentalModalValue: {
        value: string | null;
        default: boolean;
        active: boolean;
        path: string;
    };
    experimentalConfigurations: ExperimentalConfiguration[] | undefined;
    setOfflineConfigurations: (configurations: {}) => void;
    toggleLegacyConfigurations: (showLegacy: boolean) => void;
    filterConfigurations: (filteredValues: any[]) => void;
    searchConfigurations: (searchValue: string) => void;
    setExperimentalConfigurations: (
        configurations: ExperimentalConfiguration[]
    ) => void;
    isExperimentalModalOpen: boolean;
    hasExperimentalModalClose: boolean;
    setIsExperimentalModalOpen: (isOpen: boolean, hasClose?: boolean) => void;
    getExperimentalConfigurations: (
        onSuccess?: (data: ExperimentalConfiguration[]) => void
    ) => void;
    setExperimentalModalValue: (value: any) => void;
    hasExperimentalFirmwareApplied: boolean;
}

const ConfigurationContext = createContext({} as ConfigurationContextState);

export default ConfigurationContext;
