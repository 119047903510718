/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect } from "react";
import { useAtom } from "jotai";
import * as Sentry from "@sentry/react";
import Stack from "@mui/material/Stack";
import SettingsIcon from "@mui/icons-material/Settings";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import NotificationsModal from "./NotificationsModal";
import DeviceSetUpModal from "./DeviceSetUpModal";
import SettingsModal from "./SettingsModal";
import Button from "../../MuiComponents/Button";
import LengthBubble from "../../MuiComponents/LengthBubble";
import Tooltip from "../../MuiComponents/Tooltip";
import NavigationListButton from "../../MuiComponents/NavigationListButton";
import ThemeContext from "../../../context/theme/themeContext";
import LanguageContext from "../../../context/language/languageContext";
import SettingsContext from "../../../context/settings/settingsContext";
import LayoutContext from "../../../context/layout/layoutContext";
import useMediaQueries from "../../../utils/useMediaQueries";
import HelpIcon from "../../../assets/icons/HelpIcon";
import ReportIcon from "../../../assets/icons/ReportIcon";
import DeviceSetUpIcon from "../../../assets/icons/DeviceSetUpIcon";
import UserInfoContext from "../../../context/userInfo/userInfoContext";
import { TctUpdateAvailableAtom } from "../../../context/webSockets/webSocketsState";
import useDeviceGuideProvider from "../../../utils/useDeviceGuide";

export interface SettingsProps {
    startup: boolean;
    disabled?: boolean;
}

const Settings = ({ startup, disabled }: SettingsProps) => {
    const {
        colors: { red600, white, textDarkDisabled, gray700 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const {
        unreadNotificationsCount,
        isDeviceGuideStepsOpen,
        isDeviceGuideInProgress,
        openNotificationsModal,
        openSettingsModal,
        openDeviceSetUpModal,
    } = useContext(SettingsContext);

    const { enableSentryCrashReport } = useContext(UserInfoContext);

    const { actionLoading, deviceGuide } = useContext(LayoutContext);

    const [{ available, isViewed }, setIsTctUpdateAvailable] = useAtom(
        TctUpdateAvailableAtom
    );

    const { fromMd } = useMediaQueries();

    const { getDeviceGuide } = useDeviceGuideProvider();

    useEffect(() => {
        !startup && !isDeviceGuideInProgress && getDeviceGuide();
        // eslint-disable-next-line
    }, [isDeviceGuideStepsOpen]);

    const handleOpenSentryModal = () => {
        // Because in Sentry it's only possible to show a dialog
        // when error occurs as dialog is connected to eventID, fake error
        // is thrown here, this should be used for internal feedback only,
        // should be changed after internal feedback
        const randomId = Math.random().toString(36).slice(2);
        const eventId = Sentry.captureException(
            new Error(`Feedback${randomId}`)
        );
        Sentry.showReportDialog({
            title: "Leave your feedback or report an issue.",
            subtitle: "If you'd like to help, add your feedback below.",
            subtitle2: "",
            eventId,
        });
    };

    const buttonView = startup || fromMd;

    const buttonVariantDepOnScreen = "iconOnly";

    const updateNotificationStatus = Number(available && !isViewed);

    const NotificationsIcon = (
        <Badge
            badgeContent={updateNotificationStatus + unreadNotificationsCount}
            sx={{
                "& .MuiBadge-badge": {
                    backgroundColor: red600,
                    color: white,
                    fontSize: "12px",
                    fontWeight: "700",
                    padding: "0 5px",
                    height: "16px",
                    minWidth: "17px",
                    transform: "unset",
                    right: "-3px",
                },
            }}
        >
            <NotificationsRoundedIcon />
        </Badge>
    );

    const handleOpenNotifications = (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        setIsTctUpdateAvailable((prevState) => ({
            ...prevState,
            isViewed: true,
        }));

        openNotificationsModal(e);
    };

    return (
        <Fragment>
            <Stack
                direction={buttonView ? "row" : "column"}
                css={css({
                    gap: buttonView ? "4px" : "unset",
                })}
            >
                {buttonView ? (
                    <Fragment>
                        {!startup &&
                            (deviceGuide?.guideSteps.length ?? 0) > 0 && (
                                <Fragment>
                                    <LengthBubble
                                        topPosition={6}
                                        leftPosition={36}
                                        isHidden={
                                            !deviceGuide?.notCompletedSteps
                                        }
                                    >
                                        {""}
                                    </LengthBubble>
                                    <Tooltip
                                        title={t.DeviceSetup}
                                        small
                                        placement="bottom"
                                    >
                                        <Button
                                            variant={buttonVariantDepOnScreen}
                                            size="small"
                                            color="white"
                                            icon={
                                                <DeviceSetUpIcon
                                                    iconColor={
                                                        actionLoading ||
                                                        disabled
                                                            ? textDarkDisabled
                                                            : gray700
                                                    }
                                                />
                                            }
                                            onClick={openDeviceSetUpModal}
                                            disabled={actionLoading || disabled}
                                            idForTesting="deviceSetUpButton"
                                        />
                                    </Tooltip>
                                </Fragment>
                            )}

                        <Tooltip
                            title={t.Notifications}
                            small
                            placement="bottom"
                        >
                            <Button
                                variant={buttonVariantDepOnScreen}
                                size="small"
                                color="white"
                                icon={NotificationsIcon}
                                onClick={handleOpenNotifications}
                                disabled={actionLoading || disabled}
                                idForTesting="notificationsButton"
                            />
                        </Tooltip>

                        <Tooltip title={t.Settings} small placement="bottom">
                            <Button
                                variant={buttonVariantDepOnScreen}
                                size="small"
                                color="white"
                                icon={<SettingsIcon />}
                                onClick={openSettingsModal}
                                disabled={actionLoading || disabled}
                                idForTesting="settingsButton"
                            />
                        </Tooltip>

                        {!disabled && (
                            <Tooltip title={t.Help} small placement="bottom">
                                <Button
                                    variant={buttonVariantDepOnScreen}
                                    size="small"
                                    color="white"
                                    icon={<HelpIcon />}
                                    component={Link}
                                    href="https://wiki.teltonika-gps.com/view/Main_Page"
                                    target="_blank"
                                    rel="noopener"
                                    disabled={actionLoading}
                                    idForTesting="helpButton"
                                />
                            </Tooltip>
                        )}

                        {enableSentryCrashReport && (
                            <Button
                                variant={buttonVariantDepOnScreen}
                                size="small"
                                color="white"
                                onClick={handleOpenSentryModal}
                                icon={<ReportIcon />}
                                disabled={actionLoading}
                                idForTesting="reportButton"
                            />
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {!startup &&
                            (deviceGuide?.guideSteps.length ?? 0) > 0 && (
                                <Fragment>
                                    <LengthBubble
                                        topPosition={18}
                                        leftPosition={22}
                                        isHidden={
                                            !deviceGuide?.notCompletedSteps
                                        }
                                    >
                                        {""}
                                    </LengthBubble>
                                    <NavigationListButton
                                        fullWidth
                                        idForTest={"deviceSetUpButton"}
                                        icon={
                                            <DeviceSetUpIcon
                                                iconColor={
                                                    actionLoading || disabled
                                                        ? "rgba(255, 255, 255, 0.3)"
                                                        : white
                                                }
                                            />
                                        }
                                        onClick={openDeviceSetUpModal}
                                        disabled={actionLoading || disabled}
                                    >
                                        {t.DeviceSetup}
                                    </NavigationListButton>
                                </Fragment>
                            )}
                        <NavigationListButton
                            fullWidth
                            icon={NotificationsIcon}
                            onClick={handleOpenNotifications}
                            disabled={actionLoading || disabled}
                            idForTest={"notificationsButton"}
                            css={css({
                                gap: "8px",
                            })}
                        >
                            {t.Notifications}
                        </NavigationListButton>

                        <NavigationListButton
                            fullWidth
                            icon={<SettingsIcon />}
                            idForTest={"settingsButton"}
                            onClick={openSettingsModal}
                            disabled={actionLoading || disabled}
                        >
                            {t.Settings}
                        </NavigationListButton>

                        {!disabled && (
                            <NavigationListButton
                                fullWidth
                                icon={<HelpIcon />}
                                component={Link}
                                idForTest={"helpButton"}
                                href="https://wiki.teltonika-gps.com/view/Main_Page"
                                target="_blank"
                                rel="noopener"
                                disabled={actionLoading}
                            >
                                {t.Help}
                            </NavigationListButton>
                        )}
                    </Fragment>
                )}
            </Stack>

            <NotificationsModal />

            <SettingsModal />
            <DeviceSetUpModal startup={startup} />
        </Fragment>
    );
};

export default Settings;
