export const SET_DUMP_READING_DATA = "SET_DUMP_READING_DATA";
export const SET_DALLAS_SENSOR_DATA = "SET_DALLAS_SENSOR_DATA";
export const SET_LLS_SENSOR_DATA = "SET_LLS_SENSOR_DATA";
export const SET_ACTIVE_DALLAS_SENSOR_VALUES =
    "SET_ACTIVE_DALLAS_SENSOR_VALUES";
export const SET_ACTIVE_LLS_SENSOR_VALUES = "SET_ACTIVE_LLS_SENSOR_VALUES";
export const SET_PROGRESS_BAR = "SET_PROGRESS_BAR";
export const SET_UPDATE_FIRMWARE_MODAL_INFO = "SET_UPDATE_FIRMWARE_MODAL_INFO";
export const RESET_PROGRESS_BARS = "RESET_PROGRESS_BARS";
export const SET_BUNDLE_PROGRESS = "SET_BUNDLE_PROGRESS";
export const SET_UPDATE_BUNDLE_MODAL_INFO = "SET_UPDATE_BUNDLE_MODAL_INFO";
export const SET_BUNDLE_MODAL_PROGRESS_STEPS =
    "SET_BUNDLE_MODAL_PROGRESS_STEPS";
export const SET_SHOULD_STEP_UPDATE = "SET_SHOULD_STEP_UPDATE";
