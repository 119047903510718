/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import moment, { Moment } from "moment";

import ThemeContext from "../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import { replacePlaceholders } from "../../../../../../utils/helpers";

interface TimestampProps {
    date: string | Moment;
}

const Timestamp = ({ date }: TimestampProps) => {
    const {
        colors: { textPlaceholder },
    } = useContext(ThemeContext);

    const { selectedLanguage, t } = useContext(LanguageContext);

    //Prevents undefined translation error
    const getTranslation = (text: string | undefined, difference: number) =>
        text ? replacePlaceholders(text, difference) : "";

    const renderTimestamp = () => {
        const now = moment().utc();
        const startDate = moment.utc(date);
        const minutesDiff = now.diff(startDate, "minutes");

        // Less than 1 minute
        if (minutesDiff < 1) {
            return t.JustNow;
        }

        // Range: 1 minute to 59 minutes
        if (minutesDiff < 60) {
            return getTranslation(t.MinutesAgo, minutesDiff);
        }

        // Range: 1 hour to 23 hours
        if (minutesDiff < 1440) {
            const hoursDiff = Math.floor(minutesDiff / 60);
            return getTranslation(t.HoursAgo, hoursDiff);
        }

        // Range: 1 day to 6 days
        if (minutesDiff < 10080) {
            const daysDiff = Math.floor(minutesDiff / 1440);
            return getTranslation(t.DaysAgo, daysDiff);
        }

        // Range: 7 days and older
        const dateLocale =
            selectedLanguage === "en" ? "en-gb" : moment.locale();

        return moment.utc(date).locale(dateLocale).format("LL");
    };

    return (
        <div
            css={css({
                alignSelf: "flex-end",
                color: textPlaceholder,
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.4px",
                margin: "0 0 4px 4px",
                whiteSpace: "nowrap",
                userSelect: "none",
            })}
        >
            {renderTimestamp()}
        </div>
    );
};

export default Timestamp;
