/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { ReactComponent as FolderOpenIcon } from "../../../../../assets/icons/FolderOpenIcon.svg";

import { useContext } from "react";
import InputField from "../../../../MuiComponents/InputField";
import LanguageContext from "../../../../../context/language/languageContext";
import useDesktopHost from "../../../../../utils/useDesktopHost";
import Button from "../../../../MuiComponents/Button";
import TextLink from "../../../../MuiComponents/TextLink";
import ThemeContext from "../../../../../context/theme/themeContext";

export interface ReadRecordsProps {
    path: string;
    change: any;
}

const ReadRecords = ({ path, change }: ReadRecordsProps) => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);

    const { openFileDialog, openDirectory } = useDesktopHost();

    const handleBrowseClick = () =>
        openFileDialog(
            {
                isDirectory: true,
            },
            (result: any) =>
                result.length > 0 && change("recordsPath", result[0])
        );

    return (
        <InputField
            fullWidth
            name="recordsPath"
            value={path}
            size="medium"
            label={t.TctReadRecords}
            iconRight={
                <TextLink
                    size="normal"
                    onClick={handleBrowseClick}
                    idForTesting="browseButton"
                >
                    {t.Change}
                </TextLink>
            }
            additionalContent={
                <Button
                    variant="iconOnly"
                    size="normal"
                    color="white"
                    icon={<FolderOpenIcon />}
                    onClick={() => openDirectory(path)}
                    css={css({
                        backgroundColor: gray100,
                        borderRadius: "6px",
                        marginLeft: "auto",
                    })}
                    idForTesting="readRecordsButton"
                />
            }
            readOnly
        />
    );
};

export default ReadRecords;
