/** @jsxRuntime classic */

/** @jsx jsx */
import React, { Fragment, useContext, useEffect, useMemo } from "react";

import {
    TableOptions,
    UseTableRowProps,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useRowState,
    useTable,
} from "react-table";

import { css, jsx } from "@emotion/react";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

import { useAtom } from "jotai";

import InfoIcon from "../../../../../../assets/icons/InfoIcon";
import { IoTableColumns } from "../../../../../../constants/constants";
import InputOutputContext from "../../../../../../context/inputOutput/inputOutputContext";
import LayoutContext from "../../../../../../context/layout/layoutContext";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { IoStatusAtom } from "../../../../../../context/webSockets/webSocketsState";
import { IoColumnLocalized } from "../../../../../../generatedTypes";
import { OverwriteTableFilterProps } from "../../../../../../utils/types";
import useMediaQueries from "../../../../../../utils/useMediaQueries";
import Tooltip from "../../../../../MuiComponents/Tooltip";

import { getCanColumns, getCanFilters } from "./columns/canColumns";
import { getIoFilters, getIoTableColumns } from "./columns/ioTableColumns";
import MobileTableCard from "./components/MobileTableCard";
import MobileTableCardCan from "./components/MobileTableCardCan";

export interface IoTableProps {
    data: any[];
    name: string;
    isFirstTable: boolean;
    isCanTable?: boolean;
    ioColumnLocalization: IoColumnLocalized[];
}

const IoTable = ({
    name,
    data,
    isFirstTable,
    isCanTable,
    ioColumnLocalization,
}: IoTableProps) => {
    const {
        colors: { textDark, blue100, white, gray100, blue200, blue700 },
        boxShadows: { blueBoxShadow, grayBoxShadow },
    } = useContext(ThemeContext);

    const {
        activeColumns,
        activeFilters,
        activePriority,
        activeOperand,
        activeStatus,
        searchValue,
        setColumns,
        columns: defaultColumns,
    } = useContext(InputOutputContext);

    const { isExplanatoryTextsHidden } = useContext(SettingsContext);
    const { layoutData } = useContext(LayoutContext);

    const [currentValues] = useAtom(IoStatusAtom);

    const memoizedData = useMemo(() => data, []);

    const columns = isCanTable
        ? getCanColumns(name, data)
        : getIoTableColumns(name, currentValues, gray100);

    const memoizedColumns = useMemo(
        () => columns,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setHiddenColumns,
        setAllFilters,
        setGlobalFilter,
    } = useTable(
        {
            columns: memoizedColumns,
            data: memoizedData,
            initialState: {
                hiddenColumns: isCanTable
                    ? []
                    : [
                          IoTableColumns.eventOnly,
                          IoTableColumns.avgConst,
                          IoTableColumns.sendSmsTo,
                          IoTableColumns.smsText,
                          IoTableColumns.currentValue,
                      ],
            },
            globalFilter: (
                allRows: UseTableRowProps<any>[],
                _: any[],
                value: string,
            ) =>
                allRows.filter((row) => {
                    const parameterName = row.original?.name?.toLowerCase();
                    const filterValue = value?.toLowerCase();
                    return parameterName?.includes(filterValue);
                }),
            useControlledState: (state) => {
                return useMemo(
                    () => ({
                        ...state,
                        currentValues,
                    }),
                    [state, currentValues],
                );
            },
        } as TableOptions<any>,
        useFlexLayout,
        useFilters,
        useGlobalFilter,
        useRowState,
    ) as OverwriteTableFilterProps;

    const { toMd } = useMediaQueries();
    const getColumnTooltip = (column: any): React.ReactElement => {
        if (isExplanatoryTextsHidden) {
            return <Fragment />;
        }

        const removePrefix = (str: string) => {
            if (isCanTable) {
                return str.replace("CanColumn_", "");
            }
            return str.replace("IoColumn_", "");
        };

        const tooltip = ioColumnLocalization.find(
            (item: any) =>
                removePrefix(item.localizationKey) === column.id &&
                removePrefix(item.localizationKey) !== "name" &&
                removePrefix(item.localizationKey) !== "currentValue",
        )?.tooltip;

        if (!tooltip) {
            return <Fragment />;
        }

        const tooltipComponent = (
            <div dangerouslySetInnerHTML={{ __html: tooltip }} />
        );

        return (
            <Tooltip title={tooltipComponent} small placement="top">
                <span
                    css={css({
                        alignItems: "center",
                        marginLeft: "5px",
                        display: "flex",
                        "& > svg": {
                            fontSize: "16px",
                            color: blue700,
                        },
                    })}
                >
                    <InfoIcon />
                </span>
            </Tooltip>
        );
    };

    useEffect(() => {
        const firstIoElement = data[0];
        const hasPhone = !!firstIoElement.phone;
        const hasSmsText = !!firstIoElement.text;
        const hasAvgConst =
            !!firstIoElement.averagingConstant || !!firstIoElement.avgConst;

        let filteredColumns = defaultColumns;

        if (!hasPhone && !hasSmsText) {
            filteredColumns = filteredColumns.filter(
                (item: any) =>
                    item.value !== IoTableColumns.sendSmsTo &&
                    item.value !== IoTableColumns.smsText,
            );
        }

        if (!hasAvgConst) {
            filteredColumns = filteredColumns.filter(
                (item: any) => item.value !== IoTableColumns.avgConst,
            );
        }

        if (!layoutData?.isOnline) {
            filteredColumns = filteredColumns.filter(
                (item: any) => item.value !== IoTableColumns.currentValue,
            );
        }

        setColumns(filteredColumns);

        const hideCurrentValueColumn = (item: string) =>
            layoutData?.isOnline ? item !== IoTableColumns.currentValue : true;

        const hiddenColumns = Object.values(IoTableColumns).filter(
            (item) =>
                !activeColumns.includes(item) &&
                item !== IoTableColumns.name &&
                hideCurrentValueColumn(item),
        );

        !isCanTable && setHiddenColumns(hiddenColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeColumns]);

    useEffect(() => {
        const filters = isCanTable
            ? getCanFilters(activeOperand, activeStatus)
            : getIoFilters(
                  activeFilters,
                  activePriority,
                  activeOperand,
                  activeStatus,
              );
        setAllFilters(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters, activePriority, activeOperand, activeStatus]);

    useEffect(() => {
        setGlobalFilter(searchValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    if (toMd) {
        return (
            <div
                css={css({
                    marginBottom: "72px",
                })}
            >
                {rows.map((row) => {
                    const currentValue = currentValues
                        ? currentValues[row.original.parameterValue?.id || ""]
                        : "";
                    prepareRow(row);
                    if (row.original.canId) {
                        return (
                            <MobileTableCardCan
                                key={row.original.parameterValue.id}
                                row={row.original}
                            />
                        );
                    }
                    return (
                        <MobileTableCard
                            key={row.original.parameterValue.id}
                            row={row.original}
                            currentValue={currentValue}
                        />
                    );
                })}
            </div>
        );
    }

    return (
        <div
            css={css({
                maxWidth: "100%",
                overflowY: "auto",
                height: "70vh",
                marginBottom: "56px",
            })}
        >
            <Table
                css={css({
                    margin: isFirstTable ? "unset" : "10px 0px",
                    filter: "drop-shadow(0px 1px 4px rgba(15, 23, 42, 0.12))",
                    backgroundColor: white,
                })}
                {...getTableProps()}
            >
                <TableHead
                    css={css({
                        backgroundColor: blue100,
                        boxShadow: blueBoxShadow,
                        borderBottom: `1px solid ${blue200}`,
                        borderTop: `1px solid ${blue200}`,
                    })}
                >
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <TableCell
                                    css={css({
                                        color: textDark,
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        borderBottom: "unset",
                                        position:
                                            index === 0 ? "sticky" : "unset",
                                        left: index === 0 ? 0 : "unset",
                                        backgroundColor: blue100,
                                        zIndex: index === 0 ? 1 : 0,
                                        display: "flex",
                                    })}
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                    {getColumnTooltip(column)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow
                                sx={{
                                    minHeight: "56px",
                                    boxShadow: grayBoxShadow,
                                }}
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <TableCell
                                            sx={{
                                                padding: "0px",
                                                borderLeft:
                                                    "1px solid rgba(224, 224, 224, 1)",
                                                display: "flex",
                                                alignItems: "center",
                                                position:
                                                    index === 0
                                                        ? "sticky"
                                                        : "unset",
                                                left: index === 0 ? 0 : "unset",
                                                zIndex: index === 0 ? 1 : 0,
                                                background:
                                                    index === 0
                                                        ? white
                                                        : "unset",
                                                borderBottom:
                                                    "1px solid rgba(224, 224, 224, 1)",
                                            }}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

export default IoTable;
