import { CSSObject } from "@emotion/react";

export const useStyles = (white: string, gray100: string) =>
    ({
        listItemTextCSS: {
            ".MuiListItemText-primary": {
                fontWeight: "600",
                fontSize: "14px",
            },
        },
        menuItemCSS: {
            padding: "14px 2px 14px 16px",
            background: white,

            "&.Mui-selected": {
                background: white,
            },

            "&:hover, &.Mui-selected:hover": {
                background: gray100,
            },
        },
    } as CSSObject);
