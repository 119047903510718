/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";
import MenuItem from "@mui/material/MenuItem";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { Fragment, ReactNode, useContext, useState } from "react";

import Checkbox from "./Checkbox";
import ThemeContext from "../../context/theme/themeContext";
import useFeElementParser from "../../utils/useFeElementParser";
import { noop } from "../../utils/helpers";

interface ListChoiceProps {
    isLast?: boolean;
    timeStamp?: React.ReactNode;
    title: string;
    description?: React.ReactNode;
    iconLeft?: React.ReactElement<SvgIconProps>;
    checkbox?: boolean;
    iconRight?: ReactNode;
    selected?: boolean;
    disabled?: boolean;
    css?: SerializedStyles;
    dynamicContent?: any;
    isNotification?: boolean;
    isNotClickable?: boolean;
    id?: string;
    onClick?: () => void;
}

const ListChoice = ({
    isLast,
    timeStamp,
    title,
    description,
    iconLeft,
    checkbox,
    iconRight,
    selected,
    disabled,
    dynamicContent,
    isNotification,
    isNotClickable,
    id,
    onClick,
    ...props
}: ListChoiceProps) => {
    const {
        colors: {
            white,
            gray700,
            gray100,
            gray200,
            textDark,
            textDarkDisabled,
        },
    } = useContext(ThemeContext);

    const [isSelected, setIsSelected] = useState(selected);

    const { renderFeElement } = useFeElementParser();

    const handleToggleSelect = () => {
        setIsSelected(!isSelected);
        onClick && onClick();
    };

    const renderContent = () => {
        if (dynamicContent) {
            return renderFeElement(
                dynamicContent,
                undefined,
                "notification",
                id
            );
        }

        const cursorType = isNotification
            ? { userSelect: "text", cursor: "text" }
            : {};

        return (
            <Fragment>
                <div
                    css={css(
                        {
                            color: disabled ? textDarkDisabled : textDark,
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                        },
                        cursorType as any
                    )}
                >
                    {title}
                </div>

                {description && (
                    <div
                        css={css([
                            {
                                color: disabled ? textDarkDisabled : gray700,
                                fontSize: isNotification ? "14px" : "12px",
                                lineHeight: isNotification ? "20px" : "16px",
                                letterSpacing: isNotification
                                    ? "0.1px"
                                    : "0.4px",
                                fontWeight: isNotification ? 400 : "initial",
                                marginTop: "4px",
                                wordBreak: "break-word",
                            },
                            cursorType as any,
                        ])}
                    >
                        {description}
                    </div>
                )}
            </Fragment>
        );
    };
    return (
        <MenuItem
            {...props}
            selected={isSelected && !checkbox}
            onClick={isNotClickable ? noop : handleToggleSelect}
            disableTouchRipple={isNotClickable}
            css={css(
                {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: white,
                    borderRadius: isNotification ? "0" : "3px",
                    cursor: disabled || isNotClickable ? "default" : "pointer",
                    pointerEvents: disabled ? "none" : "auto",
                    padding: "12px 16px",
                    borderBottom:
                        isNotification && !isLast
                            ? `1px solid ${gray200}`
                            : "none",
                    transition:
                        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
                },
                !isNotification && {
                    "&:hover": {
                        backgroundColor: gray100,
                    },
                }
            )}
        >
            <div
                css={css({
                    display: "flex",
                    alignItems: description ? "normal" : "center",
                })}
            >
                {iconLeft && (
                    <span
                        css={css({
                            display: "inherit",
                            alignSelf: isNotification
                                ? "flex-start"
                                : "inherit",
                            "& > svg": {
                                color: disabled ? textDarkDisabled : textDark,
                            },
                        })}
                    >
                        {iconLeft}
                    </span>
                )}

                <div>
                    {timeStamp ? timeStamp : ""}

                    {renderContent()}
                </div>
            </div>

            {checkbox && (
                <Checkbox
                    checked={isSelected}
                    disabled={disabled}
                    css={css({
                        marginLeft: "8px",
                    })}
                />
            )}

            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "flex-start",

                    "& > svg, & > label": {
                        marginLeft: "8px",
                    },
                })}
            >
                {iconRight}
            </div>
        </MenuItem>
    );
};

export default ListChoice;
