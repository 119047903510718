/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox, { CheckboxProps } from "@mui/material/Checkbox";
import ThemeContext from "../../context/theme/themeContext";
import CheckboxBlankIcon from "../../assets/icons/CheckboxBlankIcon";
import CheckboxCheckedIcon from "../../assets/icons/CheckboxCheckedIcon";
import CheckboxIndeterminateIcon from "../../assets/icons/CheckboxIndeterminateIcon";

interface MuiCheckboxProps extends CheckboxProps {
    label?: string;
    description?: string;
    error?: boolean;
}

const Checkbox = ({
    label,
    description,
    error,
    ...props
}: MuiCheckboxProps) => {
    const {
        colors: {
            textDark,
            gray300,
            blue700,
            blue800,
            gray400,
            gray700,
            textDarkDisabled,
        },
    } = useContext(ThemeContext);

    const labelColor = props.disabled ? textDarkDisabled : textDark;
    const descriptionColor = props.disabled ? textDarkDisabled : gray700;

    const isDefault = !props.disabled && !error;

    const getHoverColor = () => {
        if (!isDefault) {
            return blue700;
        }

        return props.indeterminate ? blue800 : gray400;
    };

    const hoverColor = getHoverColor();

    return (
        <FormControlLabel
            control={
                <MuiCheckbox
                    data-testid="checkbox"
                    {...props}
                    disableRipple
                    size="small"
                    icon={<CheckboxBlankIcon error={error} />}
                    checkedIcon={<CheckboxCheckedIcon error={error} />}
                    indeterminateIcon={
                        <CheckboxIndeterminateIcon error={error} />
                    }
                    sx={{
                        padding: "0",
                        color: gray300,
                        transition:
                            "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                        "&.Mui-checked, &.MuiCheckbox-indeterminate": {
                            color: blue700,
                        },

                        "& > svg": {
                            fontSize: "24px",
                        },

                        "&:hover": {
                            color: hoverColor,

                            "&.Mui-checked": {
                                color: isDefault ? blue800 : blue700,
                            },
                        },
                    }}
                />
            }
            label={
                label ? (
                    <span
                        css={css({
                            "& > span": {
                                display: "block",
                            },
                        })}
                    >
                        <span
                            css={css({
                                color: labelColor,
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                            })}
                        >
                            {label}
                        </span>

                        {description && (
                            <span
                                css={{
                                    color: descriptionColor,
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    letterSpacing: "0.4px",
                                }}
                            >
                                {description}
                            </span>
                        )}
                    </span>
                ) : (
                    ""
                )
            }
            sx={{
                alignItems: description ? "flex-start" : "center",
                margin: "0",

                "& .MuiFormControlLabel-label": {
                    marginLeft: label ? "8px" : "0",
                },
            }}
        />
    );
};

export default Checkbox;
