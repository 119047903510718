/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import TypedGrid from "../../../../../MuiComponents/TypedGrid";
import { Fragment, useContext } from "react";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { useAtom } from "jotai";
import { DeviceStatusAtom } from "../../../../../../context/webSockets/webSocketsState";
import LanguageContext from "../../../../../../context/language/languageContext";
import Button from "../../../../../MuiComponents/Button";
import BundleUpdateModal from "../../../../../MuiComponents/Modals/BundleUpdateModal/BundleUpdateModal";
import BundleUpdateState from "../../../../../MuiComponents/Modals/BundleUpdateModal/context/BundleUpdateState";
import DeviceStatusContext from "../../../../../../context/deviceStatus/deviceStatusContext";

export interface FirmwareUpdateProps {
    onUpdate: (isDtbUpdate: boolean) => void;
}

const FirmwareUpdate: React.FunctionComponent<FirmwareUpdateProps> = ({
    onUpdate,
}) => {
    const {
        colors: { gray700, textDark },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);

    const [deviceStatus] = useAtom(DeviceStatusAtom);
    const { updateBundleModalInfo, setUpdateBundleModalInfo } =
        useContext(DeviceStatusContext);

    const firmwareVersions = deviceStatus?.deviceInformation.mcuData;
    const firmwareVersion = deviceStatus?.deviceInformation.firmwareVersion;

    const bleFirmwareVersion = firmwareVersions?.find(
        (fw) => fw.type === "Ble"
    )?.firmwareVersion;

    const canFirmwareVersion = firmwareVersions?.find(
        (fw) => fw.type === "Can"
    )?.firmwareVersion;

    const specId = deviceStatus?.deviceInformation.specId;
    const isBundleUpdateSupported = deviceStatus?.isBundleUpdateSupported;
    const keyBaseStyles = css({
        color: gray700,
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
    });

    const valueBaseStyles = css({
        color: textDark,
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0.15px",
        wordBreak: "break-all",
    });

    const renderTitleAndVersion = (
        title: string,
        version: string | undefined,
        specId?: number,
        children?: React.ReactNode
    ) => {
        return (
            <Fragment>
                <TypedGrid item xs0={12} sm={6}>
                    <div
                        css={css`
                            ${keyBaseStyles};
                        `}
                    >
                        {title}
                    </div>
                </TypedGrid>
                <TypedGrid item xs0={12} sm={6}>
                    <div>
                        {version && (
                            <div
                                css={css`
                                    ${valueBaseStyles};
                                    margin-bottom: 8px;
                                `}
                            >
                                {version}
                                {(specId ?? 0) > 1 && `(Spec ID: ${specId})`}
                            </div>
                        )}
                        {!!children && children}
                    </div>
                </TypedGrid>
            </Fragment>
        );
    };
    const renderContent = () => {
        if (isBundleUpdateSupported) {
            return (
                <Fragment>
                    {firmwareVersion &&
                        renderTitleAndVersion(
                            t.MainFirmwareVersion,
                            firmwareVersion,
                            specId
                        )}
                    {canFirmwareVersion &&
                        renderTitleAndVersion(
                            t.CanFirmwareVersion,
                            canFirmwareVersion
                        )}
                    {bleFirmwareVersion &&
                        renderTitleAndVersion(
                            t.BleFirmwareVersion,
                            bleFirmwareVersion
                        )}
                    {deviceStatus?.dtbVersion &&
                        renderTitleAndVersion(
                            t.DtbFirmwareVersion,
                            deviceStatus?.dtbVersion
                        )}
                    <TypedGrid item xs0={12} sm={6}>
                        <div
                            css={css`
                                ${keyBaseStyles};
                            `}
                        />
                    </TypedGrid>
                    <TypedGrid item xs0={12} sm={6}>
                        <div>
                            <Button
                                fullWidth
                                variant="textOnly"
                                size="small"
                                color="primary"
                                onClick={() =>
                                    setUpdateBundleModalInfo({
                                        isOpen: true,
                                        step: 0,
                                    })
                                }
                                idForTesting="bundleUpdateButton"
                            >
                                {t.Update}
                            </Button>
                        </div>
                    </TypedGrid>
                </Fragment>
            );
        }
        return (
            <Fragment>
                {renderTitleAndVersion(
                    t.FirmwareVersion,
                    firmwareVersion,
                    specId,
                    <Button
                        fullWidth
                        variant="textOnly"
                        size="small"
                        color="primary"
                        onClick={() => onUpdate(false)}
                        idForTesting="updateFirmwareButton"
                    >
                        {t.Update}
                    </Button>
                )}
            </Fragment>
        );
    };
    return (
        <Fragment>
            <div
                css={css`
                    margin: 8px 0;
                `}
            >
                <TypedGrid
                    container
                    columnSpacing={2}
                    rowSpacing={1}
                    alignItems="center"
                >
                    {renderContent()}
                </TypedGrid>
            </div>
            {updateBundleModalInfo.isOpen && (
                <BundleUpdateState>
                    <BundleUpdateModal />
                </BundleUpdateState>
            )}
        </Fragment>
    );
};

export default FirmwareUpdate;
