import { TableInstance } from "react-table";

import { ListConfig } from "../components/Editor/MenuItemView/Segment/Component/List";
import { AlertStatus } from "../constants/constants";
import {
    Banner,
    DeviceStatusWindow,
    ModifiedParameters,
    ParameterConfiguration,
    TranslatableName,
} from "../generatedTypes";

export type Modify<T, R> = Omit<T, keyof R> & R;

export interface AccordionProperties {
    name: string;
    description: string;
    tooltip: any;
    accordionItemName?: string;
    accordionItemDescription?: string;
    isFeatureOff?: boolean;
    switchId?: number;
}

export interface IoContainerProperties {
    name: string;
    description: string;
    IoContainerItemName?: string;
    IoContainerDescription?: string;
    turnedOnCount?: number;
    totalCount?: number;
    index?: number;
}
export interface GroupBoxData {
    name: string;
    description: string;
    tooltip: string;
    switchId: number;
    switchValue: string;
    switchDefaultValue: string;
    border: boolean;
    topBorder: boolean;
    separator: boolean;
    orientation: string;
    components: ComponentData[];
    ioItems: any; //TODO: add
    disabledTooltip: DisabledTooltip;
}

export interface SingleTableColumnData {
    components: ComponentData[];
    description: string;
    index: number;
    name: string;
    tooltip: any;
    dataModeIndex: number;
}
export interface TableData {
    name?: string;
    description?: string;
    tooltip?: string;
    border?: boolean;
    columns: SingleTableColumnData[];
}
export interface SegmentData {
    index: number;
    component?: ComponentData;
    groupBox?: GroupBoxData;
    modal?: ModalData;
    ecoCalculator: EcoCalculatorData;
    schedule?: ScheduleModalData;
    ioItem?: any;
    table?: TableData;
    passwordBox?: any;
}

export interface CollectionItem {
    dependenceName: string;
    isDisabled: boolean;
    text: string;
    value: string;
    visibilityDependOnId: string;
    visibilityDependOnValues: string;
    visibilityFlags: number;
    disabledTooltip?: DisabledTooltip;
}

export interface CollectionConfig {
    collectionType: string;
    mask: any;
    isRequired: boolean;
    collectionItems: CollectionItem[];
}

export interface ComponentData {
    collectionConfig?: CollectionConfig;
    componentType?: string;
    default?: string;
    sourceBindToList?: string;
    dependsOnList?: string;
    description?: string;
    disabledTooltip?: {
        btnTitle: string;
        goTo: number;
        title: string;
        searchPath: any;
    };
    enabledProperties?: any;
    index: number;
    isDisabled?: boolean;
    label: string;
    listConfig?: ListConfig;
    name?: string;
    orientation?: string;
    parameterId: number;
    parameterValue?: string;
    placeholder?: string;
    tooltip?: string;
    min?: number;
    max?: number;
    increment?: number;
    textMinLength?: number;
    textMaxLength?: number;
}

export interface BlockData {
    border: boolean;
    description: string;
    index: number;
    name: string;
    segments: SegmentData[];
    separator: boolean;
    switchDefaultValue: string;
    switchId: number;
    switchValue: string;
    tooltip: any;
    disabledTooltip: DisabledTooltip;
}

export interface FrameData {
    accordionName: string;
    accordionProperties: AccordionProperties;
    ioContainerProperties: IoContainerProperties;
    ioContainerName: string;
    blocks: BlockData[];
    description: string;
    index: number;
    layoutType: number;
    name: string;
    orientation: "Horizontal" | "Vertical";
    switchDefaultValue: string;
    switchId: number;
    switchValue: string;
    tooltip: any;
    turnedOnCount: number;
    totalCount: number;
    disabledTooltip: DisabledTooltip;
    switchOnValue: string;
    switchOffValue: string;
    priorityParameterIds: any[];
}

export interface DisabledTooltip {
    btnTitle: string | undefined;
    title: string;
    searchPath: any;
    goTo?: number;
}

export interface ModalData {
    border: boolean;
    cancelCaption: string;
    components: ComponentData[];
    description: string;
    modalName: string;
    name: string;
    saveCaption: string;
    tooltip: string;
}

export interface ScheduleModalData extends ModalData {
    endSettings: {
        day: ComponentData;
        description: string;
        month: ComponentData;
        name: string;
        time: ComponentData;
        tooltip: any;
        week: ComponentData;
    };
    settings: {
        daylight: ComponentData;
        timeZone: ComponentData;
    };
    startSettings: {
        day: ComponentData;
        description: string;
        month: ComponentData;
        name: string;
        time: ComponentData;
        tooltip: any;
        week: ComponentData;
    };
    weekdays: {
        description: string;
        end: ComponentData;
        index: number;
        name: string;
        start: ComponentData;
        status: ComponentData;
    }[];
}
export interface EcoCalculatorData {
    components: ComponentData[];
    ecoCalculator: {
        border: boolean;
        modalName: string;
        saveCaption: string;
        cancelCaption: string;
    };
    border: boolean;
    cancelCaption: string;
    description: string;
    inputParameters: {
        defaultTransmissionType: number;
        defaultDrivingMode: number;
        defaultDriveType: number;
    };
    modalName: string;
    name: string;
    outputParameters: {
        accelerationId: number;
        brakingId: number;
        corneringId: number;
    };
    saveCaption: string;
    tooltip: any;
}

export interface DumpData {
    percents?: number;
    currentFile?: number;
    filesCount?: number;
}

export interface OfflineOnlineData {
    fmType: string;
    id: number;
    specId: number;
    menuItems: TranslatableName[];
    title: string;
    version: string;
    hwVersion: string;
    isConfigurationSupported: boolean;
    initialValues: {
        bindableLists: { listName: string; initialValues: any[] }[];
        visibilities: { parameterId: number; isDisabled: boolean }[];
    };
    alerts: AlertData[];
    deviceFamily: DeviceFamily;
    deviceStatusWindow: DeviceStatusWindow;
    parameterSaveBanners: Banner[];
    parameterConfiguration: ParameterConfiguration;
}

export enum DeviceFamily {
    Undefined = "Undefined",
    FMB = "FMB",
    FMB640 = "FMB640",
    TAT = "TAT",
    FTC = "FTC",
}

export interface AlertData {
    type: AlertStatus;
    key: string;
    title: string;
    description: string;
    visible: boolean;
}

export interface OfflineOnlineDataWithSettings extends OfflineOnlineData {
    isOnline?: boolean;
    port?: string;
    connectionType?: string;
    settings?: any;
    configurationId?: number;
    fromFile?: any;
    path?: string;
    fileName?: string;
    hideOptions?: any;
    imei?: string;
    readRecordsEnabled: boolean;
}

export interface DiscoveredDeviceData {
    isAuthorized: boolean;
    hwversion: string;
    fmType: string;
    imei: string;
    firmware: string;
    specId: number;
    connectionType: number;
    portName: string;
    technologies: string[];
    isConfigurable: boolean;
}

export interface ListItemInterface {
    index: number;
    value: string;
}

export interface OverwriteTableFilterProps extends TableInstance<any> {
    setAllFilters: (filters: any[]) => void;
    setGlobalFilter: (filterValue: string) => void;
    selectedFlatRows: any[];
    state: any;
    toggleAllRowsSelected: (isSelected: boolean) => void;
    toggleRowSelected: any;
}

export enum GeozoneType {
    None,
    Circle,
    Rectangle,
    Polygon,
}

export interface Geozone {
    index: number;
    name: string;
    shape?: any;
    type: GeozoneType;
    radius?: number;
    geozoneId?: number;
    coordinates: any[];
    isHidden?: boolean;
}

export interface TopBarProps {
    disabled: boolean;
}

export interface ErrorDetails {
    title: string;
    description: string;
}

export interface RecordReadStatus {
    totalCount: number;
    currentBadCount: number;
    currentValidCount: number;
    totalPayloadLength: number;
}

export interface Problem {
    title: string;
    detail: string;
    status: number;
}

export interface ParamFail {
    id: number;
    failReason: number;
    value: string;
}

export enum ConfigurationPasswordState {
    Same,
    Set,
    Changed,
}

export enum TerminalPortType {
    Debug = 2,
    Device = 1,
}

//there is an issue with generated enum

export type ModifiedParametersFixedType = Omit<
    ModifiedParameters,
    "configurationPasswordState" | "recoveryConfigurationPasswordState"
> & {
    recoveryConfigurationPasswordState: ConfigurationPasswordState;
    configurationPasswordState: ConfigurationPasswordState;
};

export interface ProgressBarItem {
    progressBarName: string;
    completedPercent: number;
    status: string;
    stage: string;
    label: string;
    statusText: string;
    details: { label: string; value: string }[];
    dtb?: any;
    state?: string;
    title?: string;
}

export interface IoValue {
    name: string;
    value: string;
    minValue?: number;
    maxValue?: number;
    validate?: string;
    validationMessage?: string;
}

export interface IoOverwriteData {
    values: IoValue[];
    operandOverride: any;
}
