/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MenuItemViewParams } from ".";
import Accordion from "./Accordion";
import Frame from "./Frame";
import IoContainer from "./IoContainer";
import Container from "../../Container";
import MenuItemContext from "../../../context/menuItem/menuItemContext";
import { Frame as FrameData } from "../../../generatedTypes";

const MenuItem = () => {
    const { frames } = useContext(MenuItemContext);
    const { menuItem } = useParams<MenuItemViewParams>();

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    const isAccordion = frames.find((frame: FrameData) => frame.accordionName);
    const isIoContainer = frames.find(
        (frame: FrameData) => frame.ioContainerName
    );
    const isBluetoothView = frames.find(
        (frame: FrameData) => frame.menuItemName === "Bluetooth"
    );

    const deviceGuideSelector = `menuItem${String(menuItem)}`;

    const renderComponent = () => {
        if (isAccordion) {
            return (
                <div id={deviceGuideSelector}>
                    <Accordion />
                </div>
            );
        }
        if (isIoContainer) {
            return <IoContainer />;
        }
        return frames.map((frame: FrameData) => (
            <Frame key={frame.index} data={frame}></Frame>
        ));
    };
    return (
        <Container
            css={css({
                paddingTop: "24px",
                paddingBottom: isBluetoothView ? "80px" : "216px",
                maxWidth: isBluetoothView ? "unset" : "1156px",
                width: isBluetoothView ? "95%" : "100%",
                maxHeight: "unset",
                overflow: "unset",
                "& > div + div": {
                    marginTop: "16px",
                },
                "& > div > div + div": {
                    marginTop: isAccordion ? "16px" : "unset",
                },
            })}
        >
            {renderComponent()}
        </Container>
    );
};

export default MenuItem;
