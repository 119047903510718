/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { forwardRef, useContext, useState } from "react";
import MenuItemContext from "../../../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import { noop } from "../../../../../../../utils/helpers";
import useDebounce from "../../../../../../../utils/useDebounce";
import useMediaQuries from "../../../../../../../utils/useMediaQueries";

type Ref = HTMLInputElement;

export enum TableSwitchDefaultValue {
    ON = "1",
    OFF = "0",
}

interface TableSwitchInputProps {
    id: number;
    label: string | number;
    onChange?: (isChecked: boolean) => void;
}

const TableSwitchInput = forwardRef<Ref, TableSwitchInputProps>(
    ({ id, label, onChange }, ref) => {
        const {
            colors: { blue700, blue400 },
        } = useContext(ThemeContext);
        const { setParameter, updateParameter, findParameterValueById } =
            useContext(MenuItemContext);

        const [switchedOn, setSwitchedOn] = useState(
            findParameterValueById(id) !== TableSwitchDefaultValue.OFF
        );

        const debouncedChangeHandler = useDebounce(updateParameter, 500);

        const { fromMd } = useMediaQuries();

        const onFailedRequest = (isChecked: boolean): void => {
            setSwitchedOn(isChecked);
        };

        const onCancelRequest = (isChecked: boolean): void => {
            setSwitchedOn(isChecked);
        };

        const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>): void => {
            const isSwitchedOn: boolean = e.target.checked;
            const payload: string = isSwitchedOn
                ? TableSwitchDefaultValue.ON
                : TableSwitchDefaultValue.OFF;
            setSwitchedOn(isSwitchedOn);
            setParameter(id, payload);
            onChange && onChange(isSwitchedOn);
            debouncedChangeHandler(
                id,
                payload,
                label,
                ref,
                () => noop,
                () => onFailedRequest(!isSwitchedOn),
                () => noop,
                false,
                () => onCancelRequest(!isSwitchedOn)
            );
        };

        return (
            <label
                css={css({
                    position: "relative",
                    display: "block",
                    width: fromMd ? "30px" : "36px",
                    height: fromMd ? "16px" : "20px",
                })}
            >
                <input
                    type="checkbox"
                    onChange={handleSwitch}
                    checked={switchedOn}
                    data-test={String(id)}
                    css={css({
                        opacity: 0,
                        width: 0,
                        height: 0,
                        "&:checked + span": {
                            backgroundColor: blue700,
                        },
                        "&:checked + span:before": {
                            transform: "translateX(14px)",
                        },
                    })}
                />
                <span
                    css={css({
                        position: "absolute",
                        cursor: "pointer",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: blue400,
                        transition: ".4s",
                        borderRadius: "34px",
                        display: "flex",
                        alignItems: "center",
                        "&:before": {
                            borderRadius: "50%",
                            position: "absolute",
                            content: `""`,
                            width: fromMd ? "13px" : "18px",
                            height: fromMd ? "13px" : "18px",
                            left: "2px",
                            top: "1px",
                            backgroundColor: "white",
                            transition: ".4s",
                        },
                    })}
                />
            </label>
        );
    }
);

export default TableSwitchInput;
