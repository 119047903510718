/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { Fragment, useContext, useState } from "react";
import DialogModal from "../../../../../MuiComponents/Modals/DialogModal";
import BluetoothViewContext from "../../../../../../context/bluetoothView/bluetoothViewContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import SearchInput from "../../../../../MuiComponents/SearchInput";
import Button from "../../../../../MuiComponents/Button";
import { ReactComponent as StartArrowIcon } from "../../../../../../assets/icons/bluetooth/StartArrow.svg";
import { ReactComponent as PauseIcon } from "../../../../../../assets/icons/bluetooth/PauseLog.svg";
import { ReactComponent as RefreshIcon } from "../../../../../../assets/icons/bluetooth/Refresh.svg";
import Table from "../../../../../MuiComponents/Table";
import usePolling from "../../../../../../utils/usePolling";
import useTct from "../../../../../../utils/useTct";
import { TableTypes } from "../../../../../../constants/constants";
import Tooltip from "../../../../../MuiComponents/Tooltip";
import { ReactComponent as EmptyBluetoothStateIcon } from "../../../../../../assets/icons/bluetooth/EmptyBluetoothState.svg";
import { ReactComponent as EmptyStateIcon } from "../../../../../../assets/BluetoothEmptyStateIcon.svg";
import { useMockData } from "../../../../../../utils/useMockData";

export interface AddDeviceModalProps {}

const AddDeviceModal: React.FunctionComponent<AddDeviceModalProps> = () => {
    const { t } = useContext(LanguageContext);
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);

    const { isAddDeviceModalOpen, setAddDeviceModal } =
        useContext(BluetoothViewContext);

    const [searchValue, setSearchValue] = useState("");
    const [isScanning, setIsScanning] = useState(true);
    const { getBluetoothDevicesScanAsync } = useTct();
    const [data, setData] = useState<any>([]);

    usePolling(
        async () => {
            const newData = await getBluetoothDevicesScanAsync();
            setData((prevData: any) => {
                const dataMap = new Map(
                    prevData.map((item: any) => [item.macAddress, item])
                );
                newData.forEach((item: any) => {
                    dataMap.set(item.macAddress, item);
                });
                return Array.from(dataMap.values());
            });
        },
        5000,
        isScanning
    );

    const handleRefresh = () => {
        setData([]);
    };

    const renderEmptyState = () => {
        if (isScanning && !searchValue) {
            return (
                <Fragment>
                    <EmptyBluetoothStateIcon />
                    <div
                        css={css({
                            fontFamily: "Open Sans",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px", // 125%
                            letterSpacing: "0.15px",
                            marginBottom: "8px",
                        })}
                    >
                        {t.LookingForDevices}
                    </div>
                    <div
                        css={css({
                            fontFamily: "Open Sans",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px", // 142.857%
                            letterSpacing: "0.1px",
                            marginBottom: "8px",
                        })}
                    >
                        {t.NewDevicesWillAppearHere}
                    </div>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <EmptyStateIcon />
                <div
                    css={css({
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px", // 125%
                        letterSpacing: "0.15px",
                        marginBottom: "8px",
                    })}
                >
                    {t.NoDevices}
                </div>
                <div
                    css={css({
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px", // 142.857%
                        letterSpacing: "0.1px",
                        marginBottom: "8px",
                    })}
                >
                    {t.NoDevicesFound}
                </div>
            </Fragment>
        );
    };
    return (
        <DialogModal
            open={isAddDeviceModalOpen}
            close={() => setAddDeviceModal(false)}
            onSecondaryBtnClick={() => setAddDeviceModal(false)}
            secondaryButtonText={t.Close}
            title={t.AddBluetoothDeviceModalTitle}
            description={t.AddBluetoothDeviceModalDescription}
            secondaryButtonColor="secondary"
            sx={{
                maxWidth: "820px",
                padding: "16px 24px 24px 24px",
            }}
        >
            <div
                css={{
                    marginTop: "16px",
                    display: "block",
                }}
            />
            <div
                css={css({
                    borderRadius: "8px",
                    border: `1px solid ${gray100}`,
                })}
            >
                <div
                    css={css({
                        padding: "16px 24px",
                        display: "flex",
                        justifyContent: "space-between",
                    })}
                >
                    <div css={css({ maxWidth: "215px" })}>
                        <SearchInput
                            onChange={(e) => setSearchValue(e.target.value)}
                            searchValue={searchValue}
                            resetValue={() => setSearchValue("")}
                        />
                    </div>
                    <div
                        css={css({
                            display: "flex",
                            gap: "16px",
                        })}
                    >
                        <Tooltip
                            title={t.BluetoothAddDeviceModalScan}
                            small
                            placement="top"
                        >
                            <Button
                                variant="iconOnly"
                                size="small"
                                color="secondary"
                                idForTesting="startScanButton"
                                icon={
                                    isScanning ? (
                                        <PauseIcon />
                                    ) : (
                                        <StartArrowIcon />
                                    )
                                }
                                onClick={() => setIsScanning(!isScanning)}
                            />
                        </Tooltip>
                        <Tooltip
                            title={t.BluetoothAddDeviceModalReset}
                            small
                            placement="top"
                        >
                            <Button
                                variant="iconOnly"
                                size="small"
                                color="secondary"
                                idForTesting="refreshButton"
                                icon={<RefreshIcon />}
                                onClick={handleRefresh}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div>
                    <Table
                        data={
                            data?.map((item: any, index: number) => ({
                                ...item,
                                id: index,
                            })) || []
                        }
                        searchValue={searchValue}
                        tableType={TableTypes.BluetoothScan}
                        onDeleteClick={() => {}}
                        emptyState={renderEmptyState()}
                        hasStickyCell={false}
                        hasNoSelectRows
                    />
                </div>
            </div>
        </DialogModal>
    );
};

export default AddDeviceModal;
