/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

import ThemeContext from "../../../../../context/theme/themeContext";

export interface PortListItemProps {
    deviceName: string;
    imei: string;
    onClick?: (e: any) => void;
}

const PortListItem: React.FunctionComponent<PortListItemProps> = ({
    deviceName,
    imei,
    onClick,
}) => {
    const {
        colors: { textDark, gray700, gray100 },
    } = useContext(ThemeContext);

    return (
        <div
            css={{
                padding: " 12px 16px",
                display: "flex",
                justifyContent: "space-between",
                "&:hover": {
                    borderRadius: "3px",
                    background: gray100,
                },
                cursor: "pointer",
            }}
            onClick={onClick}
        >
            <div>
                <div
                    css={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px" /* 142.857% */,
                        letterSpacing: "0.1px",
                        color: textDark,
                    }}
                >
                    {deviceName}
                </div>
                <div
                    css={{
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px",
                        letterSpacing: "0.4px",
                        color: gray700,
                    }}
                >
                    IMEI: {imei}
                </div>
            </div>
            <div
                css={css({
                    transform: "rotate(-90deg)",
                    wdith: "24px",
                    height: "24px",
                })}
            >
                <ExpandMoreIcon />
            </div>
        </div>
    );
};

export default PortListItem;
