/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useEffect } from "react";
import TopBar from "../TopBar/StartUpTopBar";
import Container from "../Container";
import Online from "./Online";
import Offline from "./Offline";
import UserInfoContext from "../../context/userInfo/userInfoContext";
import FeaturesContext from "../../context/features/featuresContext";
import ReactGA from "react-ga4";
import DeviceAuthorizationState from "../../context/deviceAuthorization/deviceAuthorizationState";
import BluetoothViewContext from "../../context/bluetoothView/bluetoothViewContext";
import ExperimentalFirmwareModal from "./ExperimentalFirmwareModal";
import ConfigurationContext from "../../context/configuration/configurationContext";

const StartUp = () => {
    const {
        isDesktopApplication,
        isDeviceDisconnected,
        setDeviceDisconnected,
        configuratorVersion,
        setVisitCount,
        isFirstTimeOnStartup,
    } = useContext(UserInfoContext);
    const { resetBluetoothView } = useContext(BluetoothViewContext);

    const { setEcoCalculatorValues } = useContext(FeaturesContext);
    const {
        isExperimentalModalOpen,
        getExperimentalConfigurations,
        setIsExperimentalModalOpen,
    } = useContext(ConfigurationContext);

    useEffect(() => {
        ReactGA.gtag("event", "tct_launch", {
            version: configuratorVersion,
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setEcoCalculatorValues(null);
        resetBluetoothView();
        setVisitCount();
        if (isDeviceDisconnected) {
            setDeviceDisconnected(false);
        }
    }, []);

    useEffect(() => {
        const handleSuccess = (data: any[]) => {
            const defaultItem =
                data.find((item) => item.default === true) || false;

            if (defaultItem) {
                return () => {};
            } else {
                !!data.length && setIsExperimentalModalOpen(true, false);
            }
        };

        isFirstTimeOnStartup && getExperimentalConfigurations(handleSuccess);
    }, [isFirstTimeOnStartup]);

    return (
        <Fragment>
            <TopBar />

            <Container
                className="remaining-height"
                css={css`
                    padding: 24px;

                    @media (max-width: 599px) {
                        padding: 24px 16px;
                    }
                `}
            >
                {isDesktopApplication && (
                    <DeviceAuthorizationState>
                        <Online />
                    </DeviceAuthorizationState>
                )}
                <Offline />
            </Container>
            {isExperimentalModalOpen && <ExperimentalFirmwareModal />}
        </Fragment>
    );
};

export default StartUp;
