/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext } from "react";

import {
    FEBadge,
    FEElement,
    FEHyperlink,
    FEList,
    FEParagraph,
    FEText,
} from "../generatedTypes";
import FeParagraph from "../components/TopBar/Settings/SettingsModal/VersionInfoModal/VersionHistoryModal/FeParagraph";
import FeText from "../components/TopBar/Settings/SettingsModal/VersionInfoModal/VersionHistoryModal/FeText";
import FeList from "../components/TopBar/Settings/SettingsModal/VersionInfoModal/VersionHistoryModal/FeList";
import ThemeContext from "../context/theme/themeContext";
import Badge, { BadgeColors } from "../components/MuiComponents/Badge";
import FeHyperlink from "../components/TopBar/Settings/SettingsModal/VersionInfoModal/VersionHistoryModal/FeHyperlink";

const useFeElementParser = () => {
    const {
        colors: { gray700, textDark },
    } = useContext(ThemeContext);
    const renderFeElement = (
        element: FEElement,
        index?: number,
        type?: "notification" | "version",
        elementId?: string
    ) => {
        switch (element?.type.toString()) {
            case "paragraph":
                return (
                    <FeParagraph
                        key={index}
                        paragraph={element as FEParagraph}
                        type={type}
                        id={elementId}
                    />
                );
            case "text":
                return (
                    <FeText key={index} text={element as FEText} type={type} />
                );
            case "list":
                return <FeList key={index} list={element as FEList} />;
            case "badge": {
                const badge = element as FEBadge;
                return (
                    <Badge key={index} color={badge.color as BadgeColors}>
                        {badge.text}
                    </Badge>
                );
            }
            case "hyperlink": {
                return (
                    <FeHyperlink
                        key={index}
                        hyperlink={element as FEHyperlink}
                        id={elementId}
                    />
                );
            }
            case undefined:
            case null:
                return <Fragment></Fragment>;
            default:
                return (
                    <div
                        css={css({ color: "red" })}
                    >{`Unhandled rendering of ${element.type}`}</div>
                );
        }
    };
    const parseTextStyle = (textStyle: string) => {
        switch (textStyle) {
            case "notificationheader1": {
                return {
                    color: textDark,
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                };
            }
            case "notificationinherit": {
                return {
                    color: textDark,
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "400",
                    letterSpacing: "0.1px",
                };
            }
            case "header1": {
                return {
                    fontSize: "16px",
                    letterSpacing: "0.15px",
                };
            }
            case "header2": {
                return {
                    fontSize: "14px",
                    color: gray700,
                };
            }
            case "strong": {
                return {
                    fontWeight: 800,
                };
            }
        }
    };

    const isParagraph = (element: FEElement) =>
        element.type.toString() === "paragraph";

    const isList = (element: FEElement) => element.type.toString() === "list";

    const isText = (element: FEElement) => element.type.toString() === "text";

    const isBadge = (element: FEElement) => element.type.toString() === "badge";

    const isHeader1 = (element: FEParagraph) =>
        element.textStyle.toString() === "header1";

    const isHeader2 = (element: FEParagraph) =>
        element.textStyle.toString() === "header2";

    return {
        renderFeElement,
        parseTextStyle,
        isParagraph,
        isList,
        isText,
        isBadge,
        isHeader1,
        isHeader2,
    };
};

export default useFeElementParser;
