/** @jsxRuntime classic */
/** @jsx jsx */
import { useContext } from "react";
import { jsx, css } from "@emotion/react";
import LanguageContext from "../../../../context/language/languageContext";
import ThemeContext from "../../../../context/theme/themeContext";
import FilterIcon from "../../../../assets/icons/FilterIcon";

const MoreFilters = () => {
    const {
        colors: { gray200, gray100, gray700 },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    return (
        <div
            css={css({
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                padding: "8px 12px",
                cursor: "pointer",
                position: "relative",
                borderRadius: "6px",
                color: gray700,

                "&:hover": {
                    backgroundColor: gray100,
                },

                "&:active": {
                    backgroundColor: gray200,
                },
            })}
        >
            <FilterIcon css={css({ width: "16px", height: "16px" })} />

            <div
                css={css({
                    marginLeft: "12px",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                })}
            >
                {t.MoreFilters}
            </div>
        </div>
    );
};

export default MoreFilters;
