/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext, useEffect, useState } from "react";

import { css, jsx } from "@emotion/react";

import LanguageContext from "../../../../../context/language/languageContext";
import MenuItemContext from "../../../../../context/menuItem/menuItemContext";
import ThemeContext from "../../../../../context/theme/themeContext";
import Button from "../../../../MuiComponents/Button";
import Divider from "../../../../MuiComponents/Divider";
import Tooltip from "../../../../MuiComponents/Tooltip";

import PasswordBoxModal, { ModalType } from "./PasswordBoxModal";

export interface PasswordBoxContentContentProps {
    data: any;
    isFtc?: boolean; // Flag to determine if it's Ftc or Fmb
}

const PasswordBoxContent: React.FunctionComponent<
    PasswordBoxContentContentProps
> = ({ data, isFtc = false }) => {
    const {
        component: {
            parameterValue,
            localizationKey,
            dependentParameterIds,
            visibilityDependOnId,
        },
    } = data;

    const { t } = useContext(LanguageContext);

    const {
        colors: { gray200, textDark },
    } = useContext(ThemeContext);
    const { findParameterValueById } = useContext(MenuItemContext);

    const [passwordIsSet, setPassword] = useState(parameterValue.length > 0);
    const [modalType, setModalType] = useState<ModalType | null>(null);

    useEffect(() => {
        setPassword(parameterValue.length > 0);
    }, [parameterValue]);

    const handleOpenModal = (type: ModalType) => setModalType(type);
    const handleCloseModal = () => setModalType(null);

    const renderDisableButtonText = () => t.DisablePassword;

    const handlePaswordSet = (value: boolean) => setPassword(value);

    const handleDisable = () => handleOpenModal("disablePassword");

    const isSetButtonDisabled =
        !findParameterValueById(Number(visibilityDependOnId)) &&
        localizationKey === "Recovery password";

    const isDisablePasswordButtonDisabled =
        !!findParameterValueById(dependentParameterIds[0]) &&
        (localizationKey === "Configuration password" ||
            localizationKey === "Primary password");

    const renderDescription = () => {
        if (localizationKey === "Recovery password") {
            return t.RecoveryPasswordDescription;
        } else if (
            localizationKey === "Configuration password" ||
            localizationKey === "Primary password"
        ) {
            return t.ConfigurationPasswordDescription;
        }
    };

    const toLowerCaseAndRemoveWhiteSpace = (str: string | undefined) =>
        (str || "").toLowerCase().replace(/\s/g, "");

    return (
        <Fragment>
            {isFtc && (
                <div
                    css={css`
                        border-bottom: 1px solid ${gray200};
                        border-radius: 8px;
                        scroll-margin: 16px;
                    `}
                >
                    <div
                        css={css`
                            padding: 24px 24px 16px 24px;
                            color: ${textDark};

                            & > p {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                            }
                        `}
                    >
                        <div
                            css={css`
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.15px;
                                margin-bottom: 8px;
                            `}
                        >
                            {data?.component?.name}
                        </div>
                        <p>{renderDescription()}</p>
                    </div>
                    <Divider hasLighterGray />
                </div>
            )}

            <div
                css={css({
                    display: "flex",
                    padding: isFtc ? "16px 24px 24px 24px" : "0",
                    "& > button + button": {
                        marginLeft: "24px",
                    },
                })}
            >
                {passwordIsSet ? (
                    <div
                        css={css({
                            display: "flex",
                            width: "100%",
                            "& > button + span": {
                                marginLeft: "24px",
                            },
                            "& > button + button": {
                                marginLeft: "24px",
                            },
                        })}
                    >
                        <Button
                            fullWidth
                            size="normal"
                            variant="textOnly"
                            color="primarySubtle"
                            onClick={() => handleOpenModal("changePassword")}
                            idForTesting={`change-password-button-${toLowerCaseAndRemoveWhiteSpace(
                                localizationKey,
                            )}`}
                        >
                            {t.ChangePassword}
                        </Button>
                        {isFtc && (
                            <Tooltip
                                title={
                                    isDisablePasswordButtonDisabled
                                        ? t.DisablePasswordDisabled
                                        : ""
                                }
                                small
                                placement="top"
                            >
                                <span
                                    css={css({
                                        display: "block",
                                        width: "100%",
                                        height: "100%",
                                        position: "relative",
                                    })}
                                >
                                    <Button
                                        fullWidth
                                        size="normal"
                                        variant="textOnly"
                                        color="primarySubtle"
                                        onClick={handleDisable}
                                        idForTesting={`ftc-disable-password-button-${toLowerCaseAndRemoveWhiteSpace(
                                            localizationKey,
                                        )}`}
                                        disabled={
                                            isDisablePasswordButtonDisabled
                                        }
                                    >
                                        {renderDisableButtonText()}
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                        {!isFtc && (
                            <Button
                                fullWidth
                                size="normal"
                                variant="textOnly"
                                color="primarySubtle"
                                onClick={handleDisable}
                                idForTesting={`disable-password-button-${toLowerCaseAndRemoveWhiteSpace(
                                    localizationKey,
                                )}`}
                            >
                                {renderDisableButtonText()}
                            </Button>
                        )}
                    </div>
                ) : (
                    <Tooltip
                        title={isSetButtonDisabled ? t.SetPasswordDisabled : ""}
                        small
                        placement="top"
                    >
                        <span
                            css={css({
                                display: "block",
                                width: "100%",
                                height: "100%",
                                position: "relative",
                            })}
                        >
                            <Button
                                fullWidth
                                size="normal"
                                variant="textOnly"
                                color="primarySubtle"
                                onClick={() => handleOpenModal("setPassword")}
                                idForTesting={`set-password-button-${toLowerCaseAndRemoveWhiteSpace(
                                    localizationKey,
                                )}`}
                                disabled={isSetButtonDisabled}
                            >
                                {t.SetPassword}
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </div>

            <PasswordBoxModal
                type={modalType}
                data={data}
                close={handleCloseModal}
                togglePassword={handlePaswordSet}
                isRecoveryPassword={
                    isFtc && localizationKey === "Recovery password"
                }
            />
        </Fragment>
    );
};

export default PasswordBoxContent;
