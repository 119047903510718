/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from "@emotion/react";

import { useContext, useMemo, useReducer } from "react";

import { AlertStatus } from "../../constants/constants";
import { getParametersFromLayout } from "../../utils/helpers";
import useApi from "../../utils/useApi";
import useTct from "../../utils/useTct";

import AlertContext from "../alert/alertContext";
import LanguageContext from "../language/languageContext";
import LayoutContext from "../layout/layoutContext";
import SettingsContext from "../settings/settingsContext";

import {
    RESET_PARAMETERS,
    SET_ACTIVE_FRAME,
    SET_BREADCRUMB_ITEMS,
    SET_DISABLED_PARAMETERS,
    SET_ERROR,
    SET_ERRORS,
    SET_FRAMES,
    SET_MANUAL_GEOFENCE_PARAMETERS,
    SET_PARAMETER,
    SET_PARAMETERS,
} from "./menuItemActions";
import MenuItemContext from "./menuItemContext";
import MenuItemReducer from "./menuItemReducer";

export type DisabledParameterType = {
    parameterId: number;
    isDisabled: boolean;
    collection?: any;
}[];

export interface MenuItemStateProps {
    children: React.ReactNode;
}

const MenuItemState = ({ children }: MenuItemStateProps) => {
    const { setAlert } = useContext(AlertContext);

    const { t } = useContext(LanguageContext);

    const { setNotification } = useContext(SettingsContext);

    const { layoutData } = useContext(LayoutContext);

    const { isCanceled } = useApi();
    const { updateParameterAsync, updateParametersAsync } = useTct();

    const initialState = {
        frames: null,
        activeFrame: null,
        parameters: {},
        disabledParameters: [],
        errors: {},
        breadcrumbItems: [],
    };

    const controllers: any[] = [];

    const [state, dispatch] = useReducer(MenuItemReducer, initialState);

    const setFrames = async (data: any) => {
        const allParameters = await getParametersFromLayout(data);
        dispatch({
            type: SET_FRAMES,
            payload: {
                allData: data,
                allParameters: allParameters,
            },
        });
    };

    const setBreadCrumbItems = (data: any) =>
        dispatch({ type: SET_BREADCRUMB_ITEMS, payload: data });

    const setActiveFrame = async (data: any) => {
        const allActiveFrameParameters = data
            ? await getParametersFromLayout([data])
            : {};
        dispatch({
            type: SET_ACTIVE_FRAME,
            payload: {
                allData: data,
                allParameters: allActiveFrameParameters,
            },
        });
    };

    const setManualGeofenceParameters = async (data: any) => {
        const allActiveFrameParameters = data
            ? await getParametersFromLayout([{ blocks: [data.manualGeozones] }])
            : {};

        dispatch({
            type: SET_MANUAL_GEOFENCE_PARAMETERS,
            payload: allActiveFrameParameters,
        });
    };
    const setParameter = (id: number, value: string | number) =>
        dispatch({ type: SET_PARAMETER, payload: { id, value } });

    const setParameters = (value: any) =>
        dispatch({ type: SET_PARAMETERS, payload: value });

    const setDisabledParameters = (value: DisabledParameterType) =>
        dispatch({ type: SET_DISABLED_PARAMETERS, payload: value });

    const hasParameterErrors = () => {
        return Boolean(
            Object.keys(state.errors).find((key) => Boolean(state.errors[key])),
        );
    };

    const setParameterError = (id: number, error: any) => {
        dispatch({ type: SET_ERROR, payload: { id, error } });
    };

    const setParameterErrors = (errors: any) => {
        dispatch({ type: SET_ERRORS, payload: errors });
    };

    const findParameterValueById = (id: string | number | undefined) => {
        return state.parameters[id || ""];
    };

    const findDisabledParameterById = (id: number): boolean | undefined => {
        return state.disabledParameters.find(
            (item: any) => item.parameterId === id,
        )?.isDisabled;
    };

    const findDisabledCollectionItemsById = (id: number): any | undefined => {
        return state.disabledParameters.find(
            (item: any) => item.parameterId === id,
        )?.collection;
    };

    const updateParameter = async (
        parameterId: any,
        newValue: string | number,
        label: string,
        elementRef: any,
        successCallback: any = null,
        errorCallback: any = null,
        loadingEnd: any = null,
        setParams: boolean = false,
        canceledCallback: any = null,
    ) => {
        //Check if there are any previous pending requests
        controllers[parameterId] &&
            controllers[parameterId].abort(
                "Operation canceled due to new request.",
            );

        //Save the cancel token for the current request
        controllers[parameterId] = new AbortController();

        try {
            const data = await updateParameterAsync(
                layoutData?.id || 0,
                parameterId,
                newValue,
                controllers,
                label === "Shape type",
            );

            data?.visibilities && setDisabledParameters(data.visibilities);
            setParams && setParameter(parameterId, newValue);
            successCallback && successCallback();
            loadingEnd && loadingEnd();
        } catch (error) {
            if (!isCanceled(error)) {
                handleError(
                    error,
                    parameterId,
                    label,
                    elementRef,
                    errorCallback,
                    loadingEnd,
                );
            }

            handleCanceledError(error, loadingEnd, canceledCallback);
        }
    };

    const renderErrorMessage = (
        error: any,
        parameterId: number,
        label: string,
    ) => {
        if (error.response?.data?.detail) {
            return error.response.data.detail;
        } else {
            return `${label} value (ID: ${parameterId}) has been reset to previous value`;
        }
    };

    const handleError = (
        error: any,
        parameterId: number,
        label: string,
        elementRef: any,
        errorCallback: any,
        loadingEnd: any,
    ) => {
        errorCallback && errorCallback();
        setAlert(
            AlertStatus.Warning,
            renderErrorMessage(error, parameterId, label),
            "Changes that were made recently could not be saved due to an error and therefore the value has been reset to the previous.",
            null,
            null,
            `Go to ${label} parameter`,
            () =>
                elementRef.current.scrollIntoView({
                    behavior: "smooth",
                }),
        );

        loadingEnd && loadingEnd();

        setNotification(
            "warning",
            renderErrorMessage(error, parameterId, label),
            "Changes that were made recently could not be saved due to an error and therefore the value has been reset to the previous.",
        );
    };

    const handleCanceledError = (
        error: any,
        loadingEnd: any,
        canceledCallback: any,
    ) => {
        if (isCanceled(error) && canceledCallback) {
            canceledCallback();
            loadingEnd && loadingEnd();
        }
    };

    const convertArrayToErrorObject = (arr: any[]) => {
        const result: any = {};

        for (const item of arr) {
            if (item.id !== undefined && item.failReason !== undefined) {
                result[item.id] = item.failReason;
            }
        }

        return result;
    };

    const resetParameters = async (parameterIds: number[]) => {
        dispatch({ type: RESET_PARAMETERS, payload: parameterIds });
    };

    const updateParameters = async (
        values: any,
        successCallback?: any,
        errorCallback?: any,
    ) => {
        try {
            const data = await updateParametersAsync(
                layoutData?.id || 0,
                values,
                true,
            );
            successCallback && successCallback();
            const valuesWithoutError = values.map((item: any) => ({
                [item.id]: "",
            }));
            setParameterErrors(valuesWithoutError);
            setParameters(values);
            data?.visibilities && setDisabledParameters(data.visibilities);
        } catch (error: any) {
            const jsonObjectArray = JSON.parse(error.response?.data?.detail);
            const formatArrayForContext =
                convertArrayToErrorObject(jsonObjectArray);
            errorCallback && errorCallback();
            setParameters(jsonObjectArray);
            setParameterErrors(formatArrayForContext);

            const geozoneIsOutOfBounds = jsonObjectArray.some((item: any) =>
                item.localizationKey.includes("Longitude"),
            );
            //Show geozone out of bounds error. Related to CONF-3809

            if (geozoneIsOutOfBounds) {
                setAlert(AlertStatus.Warning, t.GeozoneOutOfBounds);
            } else {
                setAlert(AlertStatus.Warning, error?.response?.data?.title);
            }
        }
    };

    return (
        <MenuItemContext.Provider
            value={useMemo(() => {
                return {
                    activeFrame: state.activeFrame,
                    frames: state.frames,
                    parameters: state.parameters,
                    disabledParameters: state.disabledParameters,
                    setFrames,
                    setActiveFrame,
                    setDisabledParameters,
                    setParameter,
                    updateParameter,
                    setParameters,
                    updateParameters,
                    resetParameters,
                    findDisabledParameterById,
                    findDisabledCollectionItemsById,
                    findParameterValueById,
                    setParameterError,
                    hasParameterErrors,
                    setBreadCrumbItems,
                    setManualGeofenceParameters,
                    errors: state.errors,
                    breadcrumbItems: state.breadcrumbItems,
                    setParameterErrors,
                };
            }, [state])}
        >
            {children}
        </MenuItemContext.Provider>
    );
};

export default MenuItemState;
