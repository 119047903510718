/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, useState, Fragment } from "react";

import Management from "./Management";
import Filter from "./Filter";
import Search from "./Search";
import FilteredByBlock from "../../../MuiComponents/FilteredByBlock";

import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import ConfigurationContext from "../../../../context/configuration/configurationContext";

export interface ToolbarProps {
    changePage: (e: any, page: number) => void;
}

const Toolbar = ({ changePage }: ToolbarProps) => {
    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { filteredValues, filterConfigurations } =
        useContext(ConfigurationContext);

    const [showSearchOnMobile, setShowSearchOnMobile] = useState(false);

    const expandSearchOnMobile = () => setShowSearchOnMobile(true);
    const hideSearchOnMobile = () => setShowSearchOnMobile(false);

    const handleChipDelete = (value: any) => {
        const updatedChipArray = filteredValues.filter(
            (chip: any) => chip !== value
        );
        filterConfigurations(updatedChipArray);
    };

    const filteredTagsNameAndValue = filteredValues.map((value: any) => ({
        name: value,
        value: value,
    }));

    return (
        <Fragment>
            <div
                css={css`
                    padding: 16px 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        color: ${textDark};
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                        margin-right: 24px;
                    }

                    @media (max-width: 839px) {
                        display: block;
                        padding: 16px 0;

                        h2 {
                            margin-right: 0;
                            margin-bottom: 16px;
                        }
                    }
                `}
            >
                <h2>{t.OfflineConfiguration}</h2>

                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {!showSearchOnMobile && (
                        <Fragment>
                            <Management />
                            <Filter changePage={changePage} />
                        </Fragment>
                    )}

                    <Search
                        renderOnMobile={showSearchOnMobile}
                        expandOnMobile={expandSearchOnMobile}
                        shrinkOnMobile={hideSearchOnMobile}
                        changePage={changePage}
                    />
                </div>
            </div>

            {filteredTagsNameAndValue.length > 0 && (
                <FilteredByBlock
                    filteredTagsNameAndValue={filteredTagsNameAndValue}
                    onChipDelete={(value) => handleChipDelete(value)}
                    onClearAll={() => filterConfigurations([])}
                />
            )}
        </Fragment>
    );
};

export default Toolbar;
