/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext } from "react";

import { jsx } from "@emotion/react";

import LayoutContext from "../../../../../context/layout/layoutContext";
import { DeviceFamily } from "../../../../../utils/types";

import PasswordBoxContent from "./PasswordBoxContent";
import { Data } from "./PasswordBoxModal";

interface PasswordBoxProps {
    data: Data;
}

const PasswordBox = ({ data }: PasswordBoxProps) => {
    const { layoutData } = useContext(LayoutContext);

    const isFtcDeviceFamily =
        layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    return (
        <Fragment>
            <PasswordBoxContent data={data} isFtc={!!isFtcDeviceFamily} />
        </Fragment>
    );
};

export default PasswordBox;
