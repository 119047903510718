import React from "react";

import { AlertStatus } from "../../../../constants/constants";
import { Segment as SegmentData } from "../../../../generatedTypes";
import Alert from "../../../Alert";
import RangeSlider from "../../../MuiComponents/RangeSlider";

import BluetoothView from "./BluetoothView/BluetoothView";
import EcoCalculatorModalSection from "./CardSectionsWithModals/EcoCalculatorModalSection";
import ScheduleModalSection from "./CardSectionsWithModals/ScheduleModalSection";
import SetSmsModalSection from "./CardSectionsWithModals/SetSmsModalSection";
import Component from "./Component";
import CoordinatesBox from "./CoordinatesBox";
import CustomScenario from "./CustomScenario";
import GenericCommandButton from "./GenericCommandButton";
import GroupBox from "./GroupBox";
import Label from "./Label";
import LinkBoxes from "./LinkBoxes";
import ManualGeofenceView from "./ManualGeofence";
import MultiColTable from "./MultiColTable";
import NotificationOutput from "./NotificationOutput";
import PasswordBox from "./PasswordBox";
import UiWidget from "./UiWidget";

export interface SegmentProps {
    data: SegmentData;
    lastSegment: boolean;
    fullComponentWidth?: boolean;
}

const Segment = ({ data, lastSegment, fullComponentWidth }: SegmentProps) => {
    // Return null for undefined data to maintain type safety
    const safeRender = <T,>(
        component: T | undefined,
        render: (data: T) => React.JSX.Element | null,
    ): React.JSX.Element | null => {
        return component ? render(component) : null;
    };

    const componentMap = {
        uiWidget: () => safeRender(data.uiWidget, (d) => <UiWidget data={d} />),
        button: () =>
            safeRender(data.button, (d) => <GenericCommandButton data={d} />),
        groupBox: () =>
            safeRender(data.groupBox, (d) => (
                <GroupBox data={d} lastGroupBox={lastSegment} />
            )),
        table: () => safeRender(data.table, (d) => <MultiColTable data={d} />),
        notificationModal: () =>
            safeRender(data.modal, (d) =>
                d.switchComponent ? <NotificationOutput data={d} /> : null,
            ),
        modal: () =>
            safeRender(data.modal, (d) =>
                !d.switchComponent ? <SetSmsModalSection data={d} /> : null,
            ),
        customScenario: () =>
            safeRender(data.customScenario, (d) => <CustomScenario data={d} />),
        ecoCalculator: () =>
            safeRender(data.ecoCalculator, (d) => (
                <EcoCalculatorModalSection data={d} />
            )),
        schedule: () =>
            safeRender(data.schedule, (d) => <ScheduleModalSection data={d} />),
        passwordBox: () =>
            safeRender(data.passwordBox, (d) => <PasswordBox data={d} />),
        bluetoothView: () =>
            safeRender(data.bluetoothView, () => <BluetoothView />),
        label: () => safeRender(data.label, (d) => <Label data={d} />),
        linkBox: () =>
            safeRender(data.linkBox, (d) => <LinkBoxes data={d as any} />),
        manualGeofence: () =>
            safeRender(data.manualGeofence, (d) => (
                <ManualGeofenceView data={d} />
            )),
        rangeSlider: () =>
            safeRender(data.rangeSlider, (d) => <RangeSlider data={d} />),
        geoCoordinates: () =>
            safeRender(data.geoCoordinates, (d) => <CoordinatesBox data={d} />),
    };

    // Find the first non-null component
    const renderedComponent = Object.values(componentMap)
        .map((render) => render())
        .find((component) => component !== null);

    if (renderedComponent) {
        return renderedComponent;
    }

    return data.component ? (
        <Component
            data={data.component}
            lastComponent={lastSegment}
            fullComponentWidth={fullComponentWidth}
        />
    ) : (
        <Alert status={AlertStatus.Critical} title={"No segment is shown"} />
    );
};

export default Segment;
