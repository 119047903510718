import React, { Fragment, useContext } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";

import MenuItemContext from "../../../../context/menuItem/menuItemContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import { getUniqueListBy } from "../../../../utils/helpers";
import { AccordionProperties, FrameData } from "../../../../utils/types";
import AccordionWithFrames from "../../../MuiComponents/AccordionWithFrames";
import Badge from "../../../MuiComponents/Badge";

const Accordion = () => {
    const { frames } = useContext(MenuItemContext);
    const {
        isDeviceGuideStepsOpen,
        setDeviceGuideStepIndex,
        closeDeviceGuideSteps,
        isExplanatoryTextsHidden,
    } = useContext(SettingsContext);

    const history = useHistory();
    const { url } = useRouteMatch();

    const isFeatureOff = (frame: FrameData) => {
        const switchValue = frame.switchValue;
        return switchValue === frame.switchOffValue;
    };

    const allPossibleAccordionProperties = frames.map((frame: FrameData) => {
        return {
            ...frame.accordionProperties,
            accordionItemName: frame.name,
            accordionItemDescription: frame.description,
            isFeatureOff: isFeatureOff(frame),
            switchId: frame.switchId,
        };
    });

    const accordionProperties = getUniqueListBy(
        allPossibleAccordionProperties,
        "name",
    );

    const renderTagName = (accordionProperty: AccordionProperties) => {
        if (accordionProperty.switchId === 0) {
            return "";
        }
        if (accordionProperty.isFeatureOff) {
            return "Off";
        }
        return "On";
    };

    const onAccordionItemClick = (index: number) => {
        if (isDeviceGuideStepsOpen) {
            setDeviceGuideStepIndex(null);
            closeDeviceGuideSteps();
        }
        history.push(`${url}/${index}`);
    };

    const mapAccordionProperty = (
        accordionProperty: AccordionProperties,
        index: number,
    ) => {
        return {
            itemName: accordionProperty.accordionItemName,
            itemDescription: !isExplanatoryTextsHidden
                ? accordionProperty.accordionItemDescription
                : "",
            isFeatureOff: accordionProperty.isFeatureOff,
            customItemBadge: renderTagName(accordionProperty) ? (
                <Badge
                    color={accordionProperty.isFeatureOff ? "light" : "info"}
                >
                    {renderTagName(accordionProperty)}
                </Badge>
            ) : null,
            onItemClick: () => onAccordionItemClick(index),
        };
    };

    const accordionItems: any = accordionProperties.map((property) => {
        const accordionName = property.name;

        return allPossibleAccordionProperties
            .map((accordionProperty: AccordionProperties, index: number) =>
                accordionProperty.name === accordionName
                    ? mapAccordionProperty(accordionProperty, index)
                    : null,
            )
            .filter(Boolean);
    });

    return (
        <Fragment>
            {accordionProperties.map((property, index) => {
                const enabledFeatureCount = accordionItems[index].filter(
                    (item: any) => !item.isFeatureOff,
                ).length;
                return (
                    <AccordionWithFrames
                        key={property?.accordionItemName}
                        name={property.name}
                        description={
                            !isExplanatoryTextsHidden
                                ? property.description
                                : ""
                        }
                        items={accordionItems[index]}
                        customTitleBadge={
                            <Badge
                                color={
                                    enabledFeatureCount > 0 ? "info" : "light"
                                }
                            >
                                {enabledFeatureCount} /
                                {accordionItems[index].length}
                            </Badge>
                        }
                        unsetItemMinHeight={isExplanatoryTextsHidden}
                        hasExpandIcon={accordionItems.length > 1}
                        expanded={
                            accordionItems.length === 1 ? true : undefined
                        }
                        withBadges
                    />
                );
            })}
        </Fragment>
    );
};

export default Accordion;
