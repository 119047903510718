import React from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { SerializedStyles } from "@emotion/react";
import { ReactComponent as EmptyHistory } from "./EmptySearchHistoryIcon.svg";

export interface EmptySearchHistoryIconProps {
    props?: SvgIconProps;
    width?: string;
    height?: string;
    css?: SerializedStyles;
}

const EmptySearchHistoryIcon = ({ ...props }: EmptySearchHistoryIconProps) => (
    <EmptyHistory />
);

export default EmptySearchHistoryIcon;
