/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import { Moment } from "moment";
import Timestamp from "./Timestamp";
import StatusBadge from "./StatusBadge";
import ListChoice from "../../../../../MuiComponents/ListChoice";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { NotificationType } from "../../../../../../context/settings/settingsReducer";

interface SingleNotificationProps {
    isLast: boolean;
    id: string;
    type: NotificationType;
    timeStamp: string | Moment;
    title: string;
    description: string;
    isRead?: boolean;
    dynamicContent?: string;
}

const SingleNotification = ({
    isLast,
    id,
    type,
    timeStamp,
    title,
    description,
    isRead,
    dynamicContent,
}: SingleNotificationProps) => {
    const {
        colors: { blue100, white },
    } = useContext(ThemeContext);

    return (
        <ListChoice
            isNotClickable
            isNotification
            isLast={isLast}
            title={title}
            iconLeft={<StatusBadge status={type} />}
            iconRight={<Timestamp date={timeStamp} />}
            dynamicContent={dynamicContent}
            description={description}
            id={id}
            css={css({
                background: isRead ? white : blue100,

                "&:hover": {
                    backgroundColor: isRead ? white : blue100,
                },
            })}
        />
    );
};

export default SingleNotification;
