/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useContext, useEffect, useState } from "react";
import { GeofenceRow, ManualGeofence } from "../../../../../generatedTypes";
import TypedGrid from "../../../../MuiComponents/TypedGrid";
import ThemeContext from "../../../../../context/theme/themeContext";
import ManualGeofenceTable from "./ManualGeofenceTable";
import ManualGeofenceMap from "./ManualGeofenceMap";
import { GeozoneType } from "../../../../../utils/types";
import { geozoneTypes, } from "../../../../../constants/constants";
import LayoutContext from "../../../../../context/layout/layoutContext";
import { findMissingGeozoneIndex, formatCoordinatesIntoMultiArrayBasedOnType, } from "../../../../../utils/helpers";
import ManualGeofenceContext from "../../../../../context/manualGeofence/manualGeofenceContext";
import useTct from "../../../../../utils/useTct";

export interface ManualGeofenceProps {
    data: ManualGeofence;
}

export interface GeozoneEditState {
    currentIndex: number;
    previousIndex: number;
    currentEditorState: EditorState;
}

export enum EditorState {
    None,
    Start,
    Cancel,
    Commit,
}

export interface GeozoneOptions {
    AllowedShapes: GeozoneType[];
    MaxPolygonVerticesCount: number;
}

const ManualGeofenceView: React.FunctionComponent<ManualGeofenceProps> = ({
    data,
}: ManualGeofenceProps) => {
    const {
        colors: { textDark, gray200 },
    } = useContext(ThemeContext);
    const { layoutData } = useContext(LayoutContext);

    const {
        geofenceSearchValue,
        activePriority,
        activeShape,
        activeOperand,
        setRefetchList,
        refetchList,
        setGeozones,
    } = useContext(ManualGeofenceContext);

    const [unfilteredGeozones, setUnfilteredGeozones] = useState<any[]>([]);

    const [geozoneData, setGeozoneData] = useState<any>([]);
    const [maxGeozones, setMaxGeozones] = useState(0);

    const newGeozoneId = findMissingGeozoneIndex(geozoneData);

    const { getGeozoneTableAsync } = useTct();

    useEffect(() => {
        const getGeozones = async () => {
            try {
                if (!layoutData) {
                    throw new Error("Cannot get the geozone table without a layout");
                }

                const data = await getGeozoneTableAsync(layoutData.id);

                setGeozoneData([
                    ...data.rows.map((geozone: GeofenceRow, index: number) => {
                        return {
                            ...geozone,
                            radius: Number(geozone.radius),
                            type: geozoneTypes[geozone.shape],
                            index: geozone.geozoneId,
                            coordinates:
                                formatCoordinatesIntoMultiArrayBasedOnType(
                                    geozone.coordinates,
                                    geozoneTypes[geozone.shape]
                                ),
                            isHidden: true,
                        };
                    }),
                ]);
                const formatedGeozones = [
                    ...data.rows.map((geozone: GeofenceRow, index: number) => {
                        return {
                            ...geozone,
                            radius: Number(geozone.radius),
                            type: geozoneTypes[geozone.shape],
                            index: geozone.geozoneId,
                            coordinates:
                                formatCoordinatesIntoMultiArrayBasedOnType(
                                    geozone.coordinates,
                                    geozoneTypes[geozone.shape]
                                ),
                        };
                    }),
                    {
                        //this one is for adding new geofence
                        isHidden: true,
                        name: "Geozone",
                        radius: 0,
                        framBorder: "0",
                        coordinates: [[0, 0]],
                        geozoneId: findMissingGeozoneIndex(
                            data.rows.map((item: GeofenceRow) => ({
                                ...item,
                                index: item.geozoneId,
                            }))
                        ),
                        type: 1,
                        index: findMissingGeozoneIndex(
                            data.rows.map((item: GeofenceRow) => ({
                                ...item,
                                index: item.geozoneId,
                            }))
                        ),
                    },
                ];
                setUnfilteredGeozones(formatedGeozones);
                setGeozones(formatedGeozones);
                setMaxGeozones(data?.maxRowsCount);
            } catch (error) {
                console.log(error);
            }
        };

        getGeozones();
        setRefetchList(false);
    }, [data, refetchList]);

    const executeFullRowFiltering = (
        rows: any[],
        searchValue: string,
        priorities: any[],
        shapes: any[],
        operand: any[]) => rows.filter((row) =>
            row.name.includes(searchValue) &&
            priorities.includes(row.priorityStatus) &&
            shapes.includes(row.shape) &&
            operand.includes(row.generateEventStatus)
        );

    const filterRowsBySearchValueOnly = (rows: any[], searchValue: string) => rows.filter((row) => row.name.includes(searchValue));

    const filterRowsBySearchValueAndOperand = (rows: any[], searchValue: string, operand: any[]) => rows.filter((row) =>
        row.name.includes(searchValue) &&
        operand.includes(row.generateEventStatus));

    const filterRowsBySearchValueAndShape = (rows: any[], searchValue: string, shapes: any[]) => rows.filter((row) =>
        row.name.includes(searchValue) &&
        shapes.includes(row.shape));

    const filterRowsBySearchValueAndPriority = (rows: any[], searchValue: string, priorities: any[]) => rows.filter((row) =>
        row.name.includes(searchValue) &&
        priorities.includes(row.priorityStatus));

    const filterRowsWhenNoPriorityOnly = (rows: any[], searchValue: string, shapes: any[], operand: any[]) => rows.filter((row) =>
        row.name.includes(searchValue) &&
        shapes.includes(row.shape) &&
        operand.includes(row.generateEventStatus));

    const filterRowsWhenNoShapeOnly = (rows: any[], searchValue: string, priorities: any[], operand: any[]) => rows.filter((row) =>
        row.name.includes(searchValue) &&
        priorities.includes(row.priorityStatus) &&
        operand.includes(row.generateEventStatus));

    const filterRowsWhenNoOperandOnly = (rows: any[], searchValue: string, priorities: any[], shapes: any[]) => rows.filter((row) =>
        row.name.includes(searchValue) &&
        priorities.includes(row.priorityStatus) &&
        shapes.includes(row.shape));

    const filterRows = (
        rows: any[],
        searchValue: string,
        priorities: any[],
        shapes: any[],
        operand: any[]
    ) => {

        if (
            priorities.length === 0 &&
            shapes.length === 0 &&
            operand.length === 0
        ) {
            return filterRowsBySearchValueOnly(rows, searchValue);
        }

        if (priorities.length === 0 && shapes.length === 0) {
            return filterRowsBySearchValueAndOperand(rows, searchValue, operand);
        }

        if (priorities.length === 0 && operand.length === 0) {
            return filterRowsBySearchValueAndShape(rows, searchValue, shapes);
        }

        if (shapes.length === 0 && operand.length === 0) {
            return filterRowsBySearchValueAndPriority(rows, searchValue, priorities);
        }

        if (priorities.length === 0) {
            return filterRowsWhenNoPriorityOnly(rows, searchValue, shapes, operand);
        }

        if (shapes.length === 0) {
            return filterRowsWhenNoShapeOnly(rows, searchValue, priorities, operand);
        }

        if (operand.length === 0) {
            return filterRowsWhenNoOperandOnly(rows, searchValue, priorities, shapes);
        }

        return executeFullRowFiltering(rows, searchValue, priorities, shapes, operand);
    };

    useEffect(() => {
        setGeozones(
            filterRows(
                unfilteredGeozones,
                geofenceSearchValue,
                activePriority,
                activeShape,
                activeOperand
            )
        );
    }, [geofenceSearchValue, activeShape, activePriority, activeOperand]);

    return (
        <div
            css={css({
                color: textDark,
                border: `1px solid ${gray200}`,
                borderRadius: "8px",
                scrollMargin: "16px",
            })}
        >
            <TypedGrid container>
                <TypedGrid
                    key={0}
                    item
                    xs0={12}
                    xl={5}
                    css={css({
                        overflow: "hidden",
                    })}
                >
                    <ManualGeofenceTable
                        finalGeozoneId={newGeozoneId}
                        maxGeozones={maxGeozones}
                    />
                </TypedGrid>
                <TypedGrid key={1} item xs0={12} xl={7}>
                    <ManualGeofenceMap />
                </TypedGrid>
            </TypedGrid>
        </div>
    );
};

export default ManualGeofenceView;
