/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Popover } from "@mui/material";
import { Fragment, useContext, useEffect } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";

import useDeviceGuideProvider from "../../../../utils/useDeviceGuide";
import { DeviceGuideType } from "../../../../constants/constants";
import SettingsContext from "../../../../context/settings/settingsContext";
import LanguageContext from "../../../../context/language/languageContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import LayoutContext from "../../../../context/layout/layoutContext";
import useNavigation from "../../../../utils/useNavigation";
import ThemeContext from "../../../../context/theme/themeContext";
import Button from "../../../MuiComponents/Button";
import ListItem from "./ListItem";

const DeviceSetUpModal = ({ startup }: any) => {
    const {
        colors: { textDark, textPlaceholder },
    } = useContext(ThemeContext);

    const {
        anchorEl,
        isDeviceSetUpModalOpen,
        isDeviceGuideInProgress,
        closeDeviceSetUpModal,
        setIsDeviceGuideInProgress,
    } = useContext(SettingsContext);

    const { t } = useContext(LanguageContext);
    const { deviceGuide } = useContext(LayoutContext);

    const { toMd } = useMediaQueries();
    const { handleDeviceGuideNavigation } = useNavigation();
    const { getDeviceGuide, setGuideStepCompleted } = useDeviceGuideProvider();

    useEffect(() => {
        !startup && !isDeviceGuideInProgress && getDeviceGuide();
        // eslint-disable-next-line
    }, [isDeviceSetUpModalOpen]);

    const necessarySteps = deviceGuide?.guideSteps.filter(
        (item) => item.guideType === (DeviceGuideType.Necessary as string)
    );

    const optionalSteps = deviceGuide?.guideSteps.filter(
        (item) => item.guideType === (DeviceGuideType.Optional as string)
    );

    const handleNecessaryClick = async (searchPath: any, stepIndex: number) => {
        setIsDeviceGuideInProgress(true);
        handleDeviceGuideNavigation(searchPath, stepIndex);
    };

    const handleOptionalClick = async (searchPath: any, stepIndex: number) => {
        setIsDeviceGuideInProgress(true);
        await setGuideStepCompleted(stepIndex);
        handleDeviceGuideNavigation(searchPath, stepIndex);
    };

    return (
        <Fragment>
            <Popover
                open={isDeviceSetUpModalOpen}
                anchorEl={anchorEl}
                onClose={closeDeviceSetUpModal}
                anchorReference={toMd ? "anchorPosition" : "anchorEl"}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                marginThreshold={toMd ? 0 : 16}
                sx={{
                    "& .MuiPaper-root": {
                        maxHeight: toMd ? "unset" : "626px",
                        height: toMd ? "100%" : "unset",
                        maxWidth: toMd ? "unset" : "448px",
                        width: toMd ? "100%" : "calc(100% - 284px)",
                        marginTop: toMd ? "0" : "10px",
                        overflowY: toMd ? "auto" : "hidden",
                    },
                }}
            >
                <div
                    css={css({
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px 30px 16px 24px",

                        "& > h2": {
                            color: textDark,
                            fontSize: "20px",
                            lineHeight: "24px",
                            fontWeight: "600",
                            letterSpacing: "0.15px",
                            alignSelf: "flex-end",
                        },
                    })}
                >
                    <h2>{t.DeviceSetup}</h2>

                    <Button
                        variant="iconOnly"
                        size="small"
                        color="white"
                        icon={<CloseIcon onClick={closeDeviceSetUpModal} />}
                        idForTesting="closeDeviceSetUpModal"
                    />
                </div>

                <div>
                    <div
                        css={css({
                            paddingLeft: "24px",
                            fontSize: "12px",
                            color: textPlaceholder,
                            fontWeight: 600,
                            marginBottom: "8px",
                        })}
                    >
                        {t.Necessary}
                    </div>
                    {necessarySteps?.map((item, index) => {
                        return (
                            <ListItem
                                key={item.index}
                                onClick={() =>
                                    handleNecessaryClick(
                                        item.searchPath,
                                        item.index
                                    )
                                }
                                label={item.name || ""}
                                isChecked={item.isChecked}
                                isFinalItem={
                                    index === necessarySteps.length - 1
                                }
                            />
                        );
                    })}

                    <div
                        css={css({
                            paddingLeft: "24px",
                            fontSize: "12px",
                            color: textPlaceholder,
                            fontWeight: 600,
                            marginBottom: "8px",
                        })}
                    >
                        {t.Optional}
                    </div>
                    {optionalSteps?.map((item, index) => {
                        return (
                            <ListItem
                                key={item.index}
                                onClick={() =>
                                    handleOptionalClick(
                                        item.searchPath,
                                        item.index
                                    )
                                }
                                label={item.name || ""}
                                isChecked={item.isChecked}
                                isFinalItem={index === optionalSteps.length - 1}
                            />
                        );
                    })}
                </div>
            </Popover>
        </Fragment>
    );
};

export default DeviceSetUpModal;
