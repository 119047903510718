/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext, useEffect, Fragment, useRef } from "react";
import { useLocation } from "react-router-dom";

import Tooltip from "../MuiComponents/Tooltip";
import ConfirmModal from "../MuiComponents/Modals/ConfirmModal";
import RndWindow from "./components/RndWindow";
import EmptyTerminalBlock from "./components/EmptyTerminalBlock";
import CommandInput from "./components/CommandInput/CommandInput";
import PortList from "./components/PortList";
import ActiveTerminalPort from "./components/ActiveTerminalPort";
import PortHeaderActionButton from "./components/PortHeaderActionButton";
import ActivePortMessages from "./components/ActivePortMessages";
import PortHeader from "./components/PortHeader";

import { ReactComponent as RemoveIcon } from "../../assets/icons/terminal/Dismiss.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/icons/terminal/Minimize.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/Add.svg";
import ThemeContext from "../../context/theme/themeContext";
import SettingsContext from "../../context/settings/settingsContext";
import LanguageContext from "../../context/language/languageContext";
import TerminalContext from "../../context/components/terminal/terminalContext";
import UserInfoContext from "../../context/userInfo/userInfoContext";
import AlertContext from "../../context/alert/alertContext";
import { AlertStatus, PortStates } from "../../constants/constants";
import useWebSockets from "../../utils/useWebSockets";
import { replacePlaceholders } from "../../utils/helpers";
import { TerminalMessage } from "../../generatedTypes";
import { TerminalPortType } from "../../utils/types";

const Terminal = () => {
    const {
        colors: { gray100, gray200, gray300, white },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { closeTerminal } = useContext(SettingsContext);

    const {
        activeTab,
        isOpenConfirmationModal,
        portListAnchorEl,
        activeTerminalPorts,
        removePortModalInfo,
        portMessages,
        setIsOpenConfirmationModal,
        setPortListAnchorEl,
        removeActiveTerminalPort,
        setRemovePortModalInfo,
        removeAllActiveTerminalPorts,
        setPortMessages,
        setPortKeepFolder,
        setPortIsLogging,
    } = useContext(TerminalContext);

    const { isDeviceDisconnected } = useContext(UserInfoContext);
    const { setAlert } = useContext(AlertContext);

    const { connection, startConnection, stopConnection } =
        useWebSockets("/terminalHub");

    const location = useLocation();

    const mainDivRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const terminalMessageRef = useRef<HTMLDivElement>(null);
    const activeTerminalPortsRef = useRef(activeTerminalPorts);

    const actionButtons = [
        {
            icon: <MinimizeIcon />,
            tooltip: t.Minimize,
            onClick: () => closeTerminal(),
        },
        {
            icon: <RemoveIcon />,
            tooltip: t.CloseTerminal,
            onClick: activeTerminalPorts.length
                ? () => setIsOpenConfirmationModal(true)
                : () => closeTerminal(),
        },
    ];

    const filteredPortMessages = portMessages.filter(
        (message: any) => message.port === activeTab?.port?.address
    );

    useEffect(() => {
        activeTerminalPortsRef.current = activeTerminalPorts;
    }, [activeTerminalPorts]);

    useEffect(() => {
        if (connection) {
            if (isDeviceDisconnected) {
                connection.off("NotifyConnectionLost");
            }
            connection.on(
                "SendPortAvailabilityEventAsync",
                (data: {
                    portName: string;
                    state: number;
                    portState: string;
                }) => {
                    const hasPortOpen = activeTerminalPortsRef.current.some(
                        (item: any) => item.id === data.portName
                    );

                    const portInfo = activeTerminalPortsRef.current.find(
                        (item: any) => item.id === data.portName
                    );

                    if (data.portState === PortStates.Removed && hasPortOpen) {
                        connection.invoke(
                            "DisconnectFromPortAsync",
                            data.portName
                        );
                        const description = replacePlaceholders(
                            t.ConnectionToDeviceLostAlertDescription,
                            portInfo?.deviceName,
                            portInfo?.imei,
                            portInfo?.id
                        );
                        setAlert(
                            AlertStatus.Critical,
                            t.ConnectionToDeviceLostTitle,
                            description
                        );
                    }
                    if (data.portState === PortStates.Arrived && hasPortOpen) {
                        connection.invoke("ConnectToPortAsync", data.portName);
                        const description = replacePlaceholders(
                            t.ConnectionToDeviceReconnectedAlertDescription,
                            portInfo?.deviceName,
                            portInfo?.imei,
                            portInfo?.id
                        );
                        setAlert(
                            AlertStatus.Success,
                            t.ConnectionToDeviceReconnectedTitle,
                            description
                        );
                    }
                }
            );
            connection.on(
                "SendPortMessagesEventAsync",
                (data: TerminalMessage[]) => {
                    setPortMessages(data);
                }
            );

            startConnection();
        }

        return () => {};

        // eslint-disable-next-line
    }, [connection, isDeviceDisconnected]);

    const heightBeforeTerminalBecomesFixedHeight = 366;

    const isBelowAllowedHeight =
        Number(mainDivRef?.current?.clientHeight) <
        heightBeforeTerminalBecomesFixedHeight;

    const checkIfAllowedToScrollToBottom = (messagesEnd: HTMLDivElement) => {
        !isBelowAllowedHeight &&
            messagesEnd.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        //scroll on initial tab render
        const messagesEnd = messagesEndRef.current;
        if (messagesEnd) {
            checkIfAllowedToScrollToBottom(messagesEnd);
        }
    }, []);

    useEffect(() => {
        const devicePort = activeTerminalPorts.find(
            (port: any) => port.port.type === TerminalPortType.Device
        );
        if (location.pathname === "/" && devicePort) {
            removeActiveTerminalPort(devicePort);
        }
    }, [location]);

    const handlePortRemove = () => {
        try {
            if (
                connection &&
                removePortModalInfo.port.port.type === TerminalPortType.Debug
            ) {
                connection.invoke(
                    "DisconnectFromPortAsync",
                    removePortModalInfo.port.id
                );
            }
            removeActiveTerminalPort(removePortModalInfo.port);
        } catch (err) {
            throw new ReferenceError(
                `Cannot invoke 'DisconnectFromPortAsync'. Web sockets connection is ${connection}`
            );
        } finally {
            setRemovePortModalInfo({ isOpen: false, port: null });
        }
    };

    const handleConfirmModalClick = () => {
        removeAllActiveTerminalPorts();
        setIsOpenConfirmationModal(false);
        closeTerminal();
        stopConnection();
    };

    return (
        <Fragment>
            <RndWindow>
                <div
                    ref={mainDivRef}
                    css={css({
                        display: "flex",
                        flexDirection: "column",
                        height: "100%", // Ensure it takes full height of RndWindow
                        padding: "24px",
                        backgroundColor: white,
                        overflow: "hidden", // Add this to prevent content from overflowing
                    })}
                >
                    <div
                        css={css({
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "16px",
                            marginTop: "4px",
                            alignItems: "flex-end",
                        })}
                    >
                        <div css={css({ display: "flex" })}>
                            {Boolean(activeTerminalPorts.length) &&
                                activeTerminalPorts.map((tab: any) => (
                                    <ActiveTerminalPort
                                        tab={tab}
                                        key={tab.port.address}
                                    />
                                ))}
                            <Tooltip
                                title={t.TerminalNewPort}
                                small
                                placement="top"
                            >
                                <span>
                                    <div
                                        css={css({
                                            cursor: "pointer",
                                            "&:hover": {
                                                background: gray200,
                                            },
                                            padding: "6px",
                                            borderRadius: "6px",
                                            marginLeft: "8px",
                                            background: Boolean(
                                                portListAnchorEl
                                            )
                                                ? gray200
                                                : "unset",
                                        })}
                                        onClick={(e) =>
                                            setPortListAnchorEl(
                                                e?.currentTarget
                                            )
                                        }
                                    >
                                        <AddIcon />
                                    </div>
                                </span>
                            </Tooltip>
                        </div>

                        <div
                            css={css({
                                display: "flex",
                                justifyContent: "space-between",
                            })}
                        >
                            {actionButtons.map((actionButton, index) => {
                                return (
                                    <PortHeaderActionButton
                                        portHeaderButton={actionButton}
                                        key={index}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <div
                        css={css({
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            padding: "12px",
                            borderRadius: "6px",
                            border: `1px solid ${gray300}`,
                            background: gray100,
                            overflow: "hidden",
                            minHeight: "184px",
                        })}
                    >
                        {activeTab ? (
                            <Fragment>
                                <PortHeader
                                    activeTab={activeTab}
                                    setPortKeepFolder={setPortKeepFolder}
                                    setPortIsLogging={setPortIsLogging}
                                />

                                <ActivePortMessages
                                    activeTab={activeTab}
                                    filteredPortMessages={filteredPortMessages}
                                    messagesEndRef={messagesEndRef}
                                    terminalMessageRef={terminalMessageRef}
                                    handleScrollToBottom={
                                        checkIfAllowedToScrollToBottom
                                    }
                                />
                            </Fragment>
                        ) : (
                            <EmptyTerminalBlock />
                        )}
                    </div>

                    {activeTab?.port?.type === TerminalPortType.Device && (
                        <div css={css({ marginTop: "10px" })}>
                            <CommandInput
                                messagesEndRef={messagesEndRef}
                                autoFocus={!isBelowAllowedHeight}
                            />
                        </div>
                    )}
                </div>
            </RndWindow>

            <ConfirmModal
                open={isOpenConfirmationModal}
                close={() => setIsOpenConfirmationModal(false)}
                submit={handleConfirmModalClick}
                title={t.TerminalClose}
                description={t.TerminalCloseDescription}
            />

            <ConfirmModal
                open={removePortModalInfo.isOpen}
                close={() =>
                    setRemovePortModalInfo({ isOpen: false, port: null })
                }
                submit={handlePortRemove}
                title={t.TerminalPortClose}
            />

            <PortList connection={connection} />
        </Fragment>
    );
};

export default Terminal;
