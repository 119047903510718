/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Button from "../../../../MuiComponents/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "../../../../MuiComponents/Tooltip";
import { useContext } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import LayoutContext from "../../../../../context/layout/layoutContext";
import ThemeContext from "../../../../../context/theme/themeContext";

export interface SaveButtonProps {
    isDisabled: boolean;
    setModalOpen: (isOpen: boolean) => void;
    isSaving: boolean;
}

export interface SaveButtonInternalProps extends SaveButtonProps {
    btnLabel: string;
    isPrimaryColor: boolean;
    idForTesting?: string;
}

const SaveButton = ({
    isDisabled,
    setModalOpen,
    isSaving,
    btnLabel,
    isPrimaryColor,
    idForTesting,
}: SaveButtonInternalProps) => {
    const {
        colors: { white, blue700 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const { layoutData, actionLoading } = useContext(LayoutContext);
    const isConfigurationSupported =
        layoutData?.isConfigurationSupported ?? false;

    const { toSm } = useMediaQueries();

    const openSaveModal = () => setModalOpen(true);

    return (
        <Tooltip
            title={isDisabled ? t.CannotSaveConfigurationWithErrors : ""}
            placement="top-end"
        >
            <span>
                <Button
                    fullWidth={toSm}
                    variant={isSaving ? "iconLeft" : "textOnly"}
                    size="small"
                    color={isPrimaryColor ? "primary" : "primarySubtle"}
                    onClick={openSaveModal}
                    disabled={
                        actionLoading || isDisabled || !isConfigurationSupported
                    }
                    icon={
                        isSaving ? (
                            <div
                                css={css({
                                    width: "24px",
                                    height: "24px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                })}
                            >
                                <CircularProgress
                                    size={20}
                                    css={css({
                                        color: isPrimaryColor ? white : blue700,
                                    })}
                                />
                            </div>
                        ) : null
                    }
                    css={css({
                        pointerEvents: isSaving ? "none" : "auto",
                    })}
                    idForTesting={idForTesting || "save-button"}
                >
                    {isSaving ? t.Saving : btnLabel}
                </Button>
            </span>
        </Tooltip>
    );
};

export default SaveButton;
