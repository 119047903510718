/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, Fragment } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import SearchContext from "../../../../context/search/searchContext";
import useNavigation from "../../../../utils/useNavigation";
import ListChoice from "../../../MuiComponents/ListChoice";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchParametersIcon from "../../../../assets/icons/SearchParametersIcon";
import SearchSectionsIcon from "../../../../assets/icons/SearchSectionsIcon";
import SearchHistoryIcon from "../../../../assets/icons/SearchHistoryIcon";

type SearchType = "parameters" | "sections" | "history";

interface SingleSearchResultProps {
    type: SearchType;
    result: any;
}

const SingleSearchResult = ({ type, result }: SingleSearchResultProps) => {
    const {
        colors: { textPlaceholder },
    } = useContext(ThemeContext);

    const { isLoading } = useContext(SearchContext);

    const { handleSearchNavigation } = useNavigation();

    const setIcon = () => {
        switch (type) {
            case "parameters":
                return SearchParametersIcon;
            case "sections":
                return SearchSectionsIcon;
            case "history":
                return SearchHistoryIcon;
        }
    };

    const Icon = setIcon();

    return (
        <ListChoice
            title={result.name}
            iconLeft={
                <Icon
                    viewBox="0 0 16 16"
                    css={css({
                        fontSize: "20px",
                    })}
                />
            }
            description={
                <Fragment>
                    <div>{result.description}</div>

                    <div
                        css={css({
                            color: textPlaceholder,
                            display: "flex",
                            alignItems: "center",
                            marginTop: "4px",

                            "& > svg": {
                                fontSize: "16px",
                                marginRight: "0",
                            },
                        })}
                    >
                        {result.searchPath.menuItem.name}
                        <ChevronRightIcon />
                        {result.searchPath.frame.name}
                    </div>
                </Fragment>
            }
            onClick={() => handleSearchNavigation(result, true)}
            css={css({
                pointerEvents: isLoading ? "none" : "auto",
            })}
        />
    );
};

export default SingleSearchResult;
