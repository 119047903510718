/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FirmwareBundleUpdateStep } from "../../../../../../generatedTypes";
import { useContext } from "react";
import DeviceStatusContext from "../../../../../../context/deviceStatus/deviceStatusContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import FirmwareBlock from "./FirmwareBlock";

export interface CustomCardContentProps {
    firmwareSteps: FirmwareBundleUpdateStep[];
    dtbSteps: FirmwareBundleUpdateStep[];
}

const CustomCardContent: React.FunctionComponent<CustomCardContentProps> = ({
    firmwareSteps,
    dtbSteps,
}) => {
    const { setShouldStepUpdate } = useContext(DeviceStatusContext);
    const { t } = useContext(LanguageContext);

    const renderTitle = (mcuType: string, fileType: string) => {
        if (fileType === "Firmware") {
            if (mcuType === "Ble" || mcuType === "Can") {
                return `${mcuType?.toUpperCase()} ${
                    fileType?.toLowerCase() ?? ""
                }:`;
            }
            return `${mcuType} ${fileType?.toLowerCase()}:`;
        }
        if (fileType === "Dtb") {
            if (mcuType === "Ble" || mcuType === "Can") {
                return `${mcuType.toUpperCase()} ${fileType?.toUpperCase()}:`;
            }
            return `${mcuType} ${fileType?.toUpperCase()}:`;
        }
        return `${mcuType} ${fileType}:`;
    };

    const sameGradeFirmwareSteps = firmwareSteps.filter(
        (step) => step.currentVersion === step.newVersion
    );

    const sameGradeDtbSteps = dtbSteps.filter(
        (step) => step.currentVersion === step.newVersion
    );

    const differentVersionFirmwareSteps = firmwareSteps.filter(
        (step) => step.currentVersion !== step.newVersion
    );

    const differentVersionDtbSteps = dtbSteps.filter(
        (step) => step.currentVersion !== step.newVersion
    );

    const handleCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        step: any
    ) => {
        if (step.canChooseToUpdate) {
            setShouldStepUpdate(step.mcuType, step.fileType, e.target.checked);
        }
        return undefined;
    };

    const hasSameGradeFirmwareOrDtbs =
        sameGradeFirmwareSteps.length > 0 || sameGradeDtbSteps.length > 0;

    const hasDifferentVersionFirmwareOrDtbs =
        differentVersionFirmwareSteps.length > 0 ||
        differentVersionDtbSteps.length > 0;

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
            })}
        >
            {hasDifferentVersionFirmwareOrDtbs && (
                <FirmwareBlock
                    title={t.UpdateFirmware}
                    firmwareSteps={differentVersionFirmwareSteps}
                    dtbSteps={differentVersionDtbSteps}
                    hasDivider={hasSameGradeFirmwareOrDtbs}
                    onCheckboxChange={handleCheckboxChange}
                    renderTitle={renderTitle}
                    bannerText={t.SameGradeBanner}
                />
            )}
            {hasSameGradeFirmwareOrDtbs && (
                <FirmwareBlock
                    title={t.ReflashFirmware}
                    firmwareSteps={sameGradeFirmwareSteps}
                    dtbSteps={sameGradeDtbSteps}
                    hasInfoBanner={true}
                    onCheckboxChange={handleCheckboxChange}
                    renderTitle={renderTitle}
                    bannerText={t.SameGradeBanner}
                />
            )}
        </div>
    );
};

export default CustomCardContent;
