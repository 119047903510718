/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";

import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import { ReactComponent as EmptySearchHistoryIcon } from "../../../../../assets/icons/EmptySearchHistoryIcon.svg";

const NoNotifications = () => {
    const { t } = useContext(LanguageContext);

    const {
        colors: { gray700, textDark },
    } = useContext(ThemeContext);

    return (
        <div
            css={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: "32px",
            })}
        >
            <EmptySearchHistoryIcon
                css={css({
                    width: "120px",
                    height: "120px",
                })}
            />

            <div
                css={css({
                    color: textDark,
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                    marginBottom: "8px",
                })}
            >
                {t.NoNotifications}
            </div>

            <div
                css={css({
                    color: gray700,
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                })}
            >
                {t["NoNotifications.description"]}
            </div>
        </div>
    );
};

export default NoNotifications;
