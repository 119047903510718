import { useContext } from "react";
import LanguageContext from "../../../../../context/language/languageContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import useApi from "../../../../../utils/useApi";

const useUpdateFunctions = () => {
    const { t, selectedLanguage } = useContext(LanguageContext);

    const {
        updateInformation: { isUpdateDownloaded },
        setNotification,
        setUpdateDownloading,
    } = useContext(SettingsContext);

    const { postData } = useApi();

    const handleUpdate = async () => {
        try {
            if (isUpdateDownloaded) {
                await postData(
                    `${selectedLanguage}/autoupdate/restartandinstall`
                );
            } else {
                setUpdateDownloading(true);

                await postData(
                    `${selectedLanguage}/autoupdate/startdownloading`
                );
            }
        } catch (error) {
            setUpdateDownloading(false);
            setNotification("error", t.Error, t.ErrorWhileStartingDownload);
        }
    };

    return {
        handleUpdate,
    };
};

export default useUpdateFunctions;
