/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";

import TerminalContext from "../../../context/components/terminal/terminalContext";
import ThemeContext from "../../../context/theme/themeContext";
import { TerminalPortType } from "../../../utils/types";
import { ReactComponent as RemoveIcon } from "../../../assets/icons/terminal/Dismiss.svg";

export interface ActiveTerminalPortsProps {
    tab: any;
}

const ActiveTerminalPorts: React.FunctionComponent<
    ActiveTerminalPortsProps
> = ({ tab }) => {
    const { activeTab, setActiveTabId, setRemovePortModalInfo } =
        useContext(TerminalContext);
    const {
        colors: { gray200, textPlaceholder, white },
    } = useContext(ThemeContext);

    const currentTabIsActive = tab === activeTab;

    return (
        <div
            key={tab.port.address}
            css={css({
                display: "flex",
                gap: "4px",
                padding: "10px 15px",
                cursor: "pointer",
                boxShadow: !currentTabIsActive
                    ? "0px -2px 0px 0px #F1F5F9 inset"
                    : "0px -2px 0px 0px #0054A6 inset",
                ":hover": {
                    backgroundColor: white,
                },
                position: "relative",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "20px",
                letterSpacing: "0.1px",
            })}
            onClick={() => setActiveTabId(tab.port.address)}
        >
            <b>{tab.port.address}</b>
            <span
                css={css({
                    color: textPlaceholder,
                })}
            >
                {currentTabIsActive &&
                    `(${
                        tab.port.type === TerminalPortType.Debug
                            ? "Debug"
                            : "Device"
                    } port - IMEI: ${tab.imei})`}
            </span>
            <span
                css={css({
                    cursor: "pointer",
                    "&:hover": {
                        background: gray200,
                    },
                    padding: "2px",
                    height: "16px",
                })}
                onClick={() =>
                    setRemovePortModalInfo({
                        isOpen: true,
                        port: tab,
                    })
                }
            >
                <RemoveIcon width={16} height={16} />
            </span>
        </div>
    );
};

export default ActiveTerminalPorts;
