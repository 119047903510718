/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext, Fragment, useEffect } from "react";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import ConfigurationContext from "../../../../context/configuration/configurationContext";
import NoSearchResults from "../NoSearchResults";
import SingleOfflineDevice from "./SingleOfflineDevice";
import Footer from "../Footer";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import UserInfoContext from "../../../../context/userInfo/userInfoContext";
import SettingsContext from "../../../../context/settings/settingsContext";
import useTct from "../../../../utils/useTct";

export interface DesktopTableProps {
    page: number;
    rowsPerPage: number;
    noSearchResults: boolean;
    toggleLegacyDevices: () => void;
    changePage: (event: any, newPage: number) => void;
    changeRowsPerPage: (event: any) => void;
}

const DesktopTable = ({
    page,
    rowsPerPage,
    noSearchResults,
    toggleLegacyDevices,
    changePage,
    changeRowsPerPage,
}: DesktopTableProps) => {
    const {
        colors: { gray200, blue100, blue200, textDark },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { offlineConfigurations } = useContext(ConfigurationContext);

    const { setSpeces, setDefaultSpecs } = useContext(UserInfoContext);

    const { isActivateKeyModalOpen } = useContext(SettingsContext);

    const { getDefaultSpecsAsync, getSpecsAsync } = useTct();

    const boxShadow = `inset 0px -1px 0px ${gray200}`;

    const getPageRows = () =>
        rowsPerPage > 0
            ? offlineConfigurations.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
              )
            : offlineConfigurations;

    useEffect(() => {
        const fetchDefaultSpecs = async () => {
            try {
                const data = await getDefaultSpecsAsync();

                setDefaultSpecs(data);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchSpecs = async () => {
            try {
                const data = await getSpecsAsync();
                setSpeces(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchDefaultSpecs();
        fetchSpecs();
    }, [isActivateKeyModalOpen]);

    return (
        <Fragment>
            <TableContainer
                className="remaining-height"
                css={css`
                    table {
                        height: 100%;
                    }

                    th,
                    td {
                        color: ${textDark};
                        border-bottom: none;
                        font-weight: 600;
                        line-height: 20px;
                        white-space: nowrap;
                    }

                    th {
                        background-color: ${blue100};
                        box-shadow: inset 0px 1px 0px ${blue200},
                            inset 0px -1px 0px ${blue200};
                        letter-spacing: 0.1px;
                        padding: 12px;
                    }

                    th:first-of-type {
                        padding: 12px 12px 12px 24px;
                    }

                    th:last-of-type {
                        padding: 12px 24px 12px 12px;
                    }

                    td {
                        box-shadow: ${noSearchResults ? "none" : boxShadow};
                        padding: 10px 12px;
                    }

                    td:nth-of-type(1) {
                        width: 102px;
                        padding: 10px 12px 10px 24px;
                    }

                    td:nth-of-type(2) {
                        width: 180px;
                        font-size: 16px;
                        letter-spacing: 0.15px;
                    }

                    td:nth-of-type(3) {
                        letter-spacing: 0.1px;
                    }

                    td:nth-of-type(4) {
                        width: 119px;
                        padding: 10px 24px 10px 12px;
                    }

                    @media (max-width: 1439px) {
                        td:nth-of-type(2) {
                            width: 103px;
                        }
                    }
                `}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t.Image}</TableCell>
                            <TableCell>{t.Model}</TableCell>
                            <TableCell>{t.Technology}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {noSearchResults ? (
                            <NoSearchResults />
                        ) : (
                            getPageRows().map((row, i) => (
                                <SingleOfflineDevice
                                    key={`${i}-${row.version}`}
                                    data={row}
                                />
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Footer
                page={page}
                rowsPerPage={rowsPerPage}
                toggleLegacyDevices={toggleLegacyDevices}
                changePage={changePage}
                changeRowsPerPage={changeRowsPerPage}
            />
        </Fragment>
    );
};

export default DesktopTable;
