/** @jsxRuntime classic */

/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";

import AlertWarningIcon from "../../assets/icons/AlertWarningIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import { AlertStatus } from "../../constants/constants";
import ThemeContext from "../../context/theme/themeContext";

import Alert from "../Alert";

interface BannerProps {
    data: {
        bannerType: string;
        description?: string;
    };
    descriptionCss?: any;
}

const BannerView = ({ data, descriptionCss }: BannerProps) => {
    const {
        colors: { blue100, blue500, gray700, orange50, red50 },
    } = useContext(ThemeContext);

    const renderBanner = (banner: {
        bannerType: string;
        description?: string;
    }) => {
        switch (banner.bannerType) {
            case "Info": {
                return (
                    <div
                        css={css({
                            padding: "8px",
                            backgroundColor: blue100,
                            border: `1px solid ${blue500}`,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                        })}
                    >
                        <span
                            css={css({
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                            })}
                        >
                            <InfoIcon
                                color="primary"
                                css={css({
                                    fontSize: "16px",
                                })}
                            />
                        </span>
                        <span
                            css={css({
                                fontWeight: 600,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: gray700,
                                ...descriptionCss,
                            })}
                        >
                            {banner.description}
                        </span>
                    </div>
                );
            }
            case "Warning": {
                return (
                    <div
                        css={css({
                            padding: "8px",
                            backgroundColor: orange50,
                            border: `1px solid #FDBA74`,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "stretch",
                            gap: "10px",
                        })}
                    >
                        <AlertWarningIcon />
                        <span
                            css={css({
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: gray700,
                            })}
                        >
                            {banner.description}
                        </span>
                    </div>
                );
            }
            case "Success":
            case "Error":
                return (
                    <div
                        css={css({
                            padding: "8px",
                            backgroundColor: red50,
                            border: `1px solid #FDBA74`,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "stretch",
                            gap: "10px",
                        })}
                    >
                        <AlertWarningIcon />
                        <span
                            css={css({
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: gray700,
                            })}
                        >
                            {banner.description}
                        </span>
                    </div>
                );
            default: {
                return (
                    <Alert
                        status={AlertStatus.Critical}
                        title={`No banner of type ${banner.bannerType} is shown`}
                    />
                );
            }
        }
    };

    return renderBanner(data);
};

export default BannerView;
