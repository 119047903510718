import React, { useContext } from "react";
import ThemeContext from "../../context/theme/themeContext";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export interface CheckboxBlankIconProps {
    error?: boolean;
    props?: SvgIconProps;
    disabled?: boolean;
}
const CheckboxBlankIcon = ({
    error,
    disabled,
    ...props
}: CheckboxBlankIconProps) => {
    const {
        colors: { white, gray200, blue100, red50, red700 },
    } = useContext(ThemeContext);

    return (
        <SvgIcon {...props}>
            {error && (
                <rect
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                    rx="7"
                    fill={white}
                    stroke={error ? red50 : blue100}
                    strokeWidth="2"
                />
            )}

            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="5.5"
                fill={disabled ? gray200 : white}
                stroke={error ? red700 : "currentColor"}
            />
        </SvgIcon>
    );
};

export default CheckboxBlankIcon;
