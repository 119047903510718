/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment } from "react";

import { css, jsx } from "@emotion/react";

import { FirmwareBundleUpdateStep } from "../../../../../../generatedTypes";
import BannerView from "../../../../BannerView";
import Divider from "../../../../Divider";
import TypedGrid from "../../../../TypedGrid";
import FirmwareWithVersions from "../FirmwareWithVersions";

interface FirmwareBlockProps {
    title: string;
    firmwareSteps: FirmwareBundleUpdateStep[];
    dtbSteps: FirmwareBundleUpdateStep[];
    hasInfoBanner?: boolean;
    hasDivider?: boolean;
    onCheckboxChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        step: any,
    ) => void;
    renderTitle: (mcuType: string, fileType: string) => string;
    bannerText: string;
}

const FirmwareBlock: React.FC<FirmwareBlockProps> = ({
    title,
    firmwareSteps,
    dtbSteps,
    hasInfoBanner = false,
    hasDivider = false,
    onCheckboxChange,
    renderTitle,
    bannerText,
}) => {
    if (firmwareSteps.length === 0 && dtbSteps.length === 0) return null;

    return (
        <Fragment>
            <div
                css={css({
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.15px",
                    margin: "16px 0px 10px 0px",
                })}
            >
                {title}
            </div>
            {hasInfoBanner && (
                <BannerView
                    data={{
                        bannerType: "Info",
                        description: bannerText,
                    }}
                />
            )}
            <div
                css={css({
                    display: "flex",
                    marginTop: hasInfoBanner ? "18px" : "10px",
                })}
            >
                {Boolean(firmwareSteps.length) && (
                    <TypedGrid xs0={6} xl={6} item>
                        {firmwareSteps.map((step) => (
                            <FirmwareWithVersions
                                title={renderTitle(step.mcuType, step.fileType)}
                                currentVersion={step.currentVersion}
                                newVersion={step.newVersion}
                                key={renderTitle(step.mcuType, step.fileType)}
                                isCheckboxDisabled={!step.canChooseToUpdate}
                                onCheckboxChange={(e) =>
                                    onCheckboxChange(e, step)
                                }
                                hasCheckbox={step.canChooseToUpdate}
                            />
                        ))}
                    </TypedGrid>
                )}
                {Boolean(dtbSteps.length) && (
                    <TypedGrid xs0={6} xl={6} item>
                        {dtbSteps.map((step) => (
                            <FirmwareWithVersions
                                title={renderTitle(step.mcuType, step.fileType)}
                                currentVersion={step.currentVersion}
                                newVersion={step.newVersion}
                                key={renderTitle(step.mcuType, step.fileType)}
                                isCheckboxDisabled={!step.canChooseToUpdate}
                                onCheckboxChange={(e) =>
                                    onCheckboxChange(e, step)
                                }
                                hasCheckbox={step.canChooseToUpdate}
                            />
                        ))}
                    </TypedGrid>
                )}
            </div>
            {hasDivider && (
                <Divider
                    additionalCss={{
                        margin: "16px 0px",
                    }}
                />
            )}
        </Fragment>
    );
};

export default FirmwareBlock;
