/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import moment from "moment";

import FilterSelect from "./FilterSelect";
import PortHeaderActionButton from "./PortHeaderActionButton";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/terminal/Download.svg";
import { ReactComponent as StartArrowIcon } from "../../../assets/icons/terminal/StartArrow.svg";
import { ReactComponent as PauseIcon } from "../../../assets/icons/terminal/PauseLog.svg";
import { ReactComponent as StopTerminalLogIcon } from "../../../assets/icons/terminal/StopTerminalLog.svg";

import { TerminalPortType } from "../../../utils/types";
import LanguageContext from "../../../context/language/languageContext";
import AlertContext from "../../../context/alert/alertContext";
import useApi from "../../../utils/useApi";
import { AlertStatus, DATE_FORMATS } from "../../../constants/constants";
import useDesktopHost from "../../../utils/useDesktopHost";
import ThemeContext from "../../../context/theme/themeContext";

interface PortHeaderProps {
    activeTab: any;
    setPortKeepFolder: (logPath: string[] | null, id: string) => void;
    setPortIsLogging: (isLogging: boolean, id: string) => void;
}

const PortHeader = ({
    activeTab,
    setPortKeepFolder,
    setPortIsLogging,
}: PortHeaderProps) => {
    const { t } = useContext(LanguageContext);

    const { setAlert } = useContext(AlertContext);

    const {
        colors: { gray200 },
    } = useContext(ThemeContext);

    const { postData, showErrorMsg } = useApi();
    const { saveFileDialog, openDirectory } = useDesktopHost();

    const handleDownload = async (deviceName: string, imei: string) => {
        if (Boolean(activeTab?.logPath)) {
            try {
                await postData("terminal/stopDebugLog", {
                    port: activeTab?.port?.address,
                });

                const getDirectory = (filePath: string) => {
                    const lastSlashIndex = filePath.lastIndexOf("\\");
                    return filePath.slice(0, lastSlashIndex);
                };
                const logPathWithoutFileName = getDirectory(activeTab?.logPath);

                setAlert(
                    AlertStatus.Success,
                    t.TerminalLogStopSuccess,
                    null,
                    null,
                    null,
                    t.OpenDirectory,
                    () => openDirectory(logPathWithoutFileName)
                );
                setPortKeepFolder(null, activeTab?.port?.address);
            } catch (err) {
                showErrorMsg(err as any);
            }
        } else {
            const formattedDate = moment().format(DATE_FORMATS.LOG_FILE);

            saveFileDialog(
                {
                    title: t.SaveFile,
                    defaultPath: `${deviceName}_${imei}_${formattedDate}`,
                    filters: [
                        {
                            extensions: ["log"],
                            name: t.TerminalLogFile,
                        },
                    ],
                },
                (result: string[]) => {
                    if (result.length > 0) {
                        const exportToFile = async () => {
                            try {
                                await postData("terminal/keepDebugLog", {
                                    port: activeTab?.port?.address,
                                    logFilePath: result,
                                });
                                setPortKeepFolder(
                                    result,
                                    activeTab?.port?.address
                                );
                                setAlert(
                                    AlertStatus.Success,
                                    t.TerminalLogSuccess
                                );
                            } catch (error) {
                                showErrorMsg(error as any);
                            }
                        };

                        exportToFile();
                    }
                }
            );
        }
    };

    const isTerminalLoggingStarted = activeTab?.isLogging;
    const portHeaderActionButtons =
        activeTab?.port?.type === TerminalPortType.Device
            ? []
            : [
                  {
                      icon: isTerminalLoggingStarted ? (
                          <PauseIcon />
                      ) : (
                          <StartArrowIcon />
                      ),
                      tooltip: isTerminalLoggingStarted
                          ? t.CaptureTerminalLogPause
                          : t.CaptureTerminalLogStart,
                      onClick: () =>
                          setPortIsLogging(
                              !isTerminalLoggingStarted,
                              activeTab?.port?.address
                          ),
                  },
                  {
                      icon: Boolean(activeTab?.logPath) ? (
                          <StopTerminalLogIcon />
                      ) : (
                          <DownloadIcon />
                      ),
                      tooltip: Boolean(activeTab?.logPath)
                          ? t.TerminalLogStarted
                          : t.TerminalLogStart,
                      onClick: () =>
                          handleDownload(
                              activeTab?.deviceName,
                              activeTab?.imei
                          ),
                  },
              ];

    return (
        <div
            css={css({
                paddingBottom: "4px",
                borderBottom: `1px solid ${gray200}`,
                display: "flex",
                justifyContent: "space-between",
            })}
        >
            <div
                css={css({
                    display: "flex",
                    gap: "16px",
                    width: "100%",
                })}
            >
                {activeTab?.port?.type === TerminalPortType.Debug && (
                    <FilterSelect />
                )}
            </div>
            <div
                css={css({
                    display: "flex",
                    gap: "8px",
                    alignItems: "flex-start",
                })}
            >
                {portHeaderActionButtons.map((portHeaderButton) => {
                    return (
                        <PortHeaderActionButton
                            portHeaderButton={portHeaderButton}
                            key={portHeaderButton?.tooltip}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default PortHeader;
