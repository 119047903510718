/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles } from "@emotion/react";

export interface ContainerProps {
    children: React.ReactNode;
    css: SerializedStyles;
    className?: string;
    id?: string;
    customPadding?: string;
}
const Container = ({ children, customPadding, ...props }: ContainerProps) => {
    return (
        <div
            {...props}
            css={css({
                padding: customPadding ?? "0 24px",
            })}
        >
            {children}
        </div>
    );
};

export default Container;
