/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";

import { getIdTooltipText } from "../../utils/helpers";
import SettingsContext from "../../context/settings/settingsContext";
import Tooltip from "./Tooltip";
import Tag from "./Tag";

interface IdBadgeProps {
    parameterId: number;
    avlId?: number;
}

const IdBadge = ({ parameterId, avlId }: IdBadgeProps) => {
    const { isParameterIdsHidden } = useContext(SettingsContext);

    return isParameterIdsHidden || !Boolean(parameterId) ? null : (
        <Tooltip
            title={getIdTooltipText(parameterId, avlId ?? 0)}
            small
            placement="top"
        >
            <span css={css({ wordBreak: "normal" })}>
                <Tag size="tiny" color="white" title="ID" />
            </span>
        </Tooltip>
    );
};

export default IdBadge;
