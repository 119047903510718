import {
    SET_SOURCE_COLLECTION,
    SET_SUBMIT_LOADING,
    UPDATE_SOURCE_COLLECTION,
} from "./triggerActions";

export default (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_SOURCE_COLLECTION: {
            const { id, value } = action.payload;
            const hiddenValues = {
                ...state.hiddenValues,
                [id]: value,
            };
            const formattedHiddenValues = Object.values(hiddenValues).map(
                (item: any) => item,
            );
            const withHiddenMenuItems = {
                ...state.sourceCollection,
                collectionItems: state.sourceCollection.collectionItems.map(
                    (item: any) => {
                        return {
                            ...item,
                            isInvisible: formattedHiddenValues.includes(
                                item.value,
                            ),
                        };
                    },
                ),
            };
            return {
                ...state,
                hiddenValues: {
                    ...state.hiddenValues,
                    [id]: value,
                },
                sourceCollection: withHiddenMenuItems,
            };
        }
        case SET_SOURCE_COLLECTION: {
            const { sourceCollection, hiddenValues } = action.payload;
            const formattedHiddenValues = Object.values(hiddenValues).map(
                (item: any) => item,
            );
            const withHiddenMenuItems = {
                ...sourceCollection,
                collectionItems: sourceCollection.collectionItems.map(
                    (item: any) => {
                        return {
                            ...item,
                            isInvisible: formattedHiddenValues.includes(
                                item.value,
                            ),
                        };
                    },
                ),
            };
            return {
                ...state,
                sourceCollection: withHiddenMenuItems,
                hiddenValues: hiddenValues,
            };
        }
        case SET_SUBMIT_LOADING: {
            return {
                ...state,
                submitLoading: action.payload,
            };
        }
        default:
            return state;
    }
};
