import React, { useMemo, useReducer, useState } from "react";

import { CollectionItem } from "../../../../../../generatedTypes";

import {
    SET_SOURCE_COLLECTION,
    SET_SUBMIT_LOADING,
    UPDATE_SOURCE_COLLECTION,
} from "./triggerActions";
import TriggerContext from "./triggerContext";
import triggerReducer from "./triggerReducer";

export interface TriggerStateProps {
    children: React.ReactNode;
}

const TriggerState = ({ children }: TriggerStateProps) => {
    const initialState = {
        sourceCollection: {},
        hiddenValues: [],
        submitLoading: false,
    };

    const [modalValues, setModalValues] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [modalData, setModalData] = useState<any>({});
    const [state, dispatch] = useReducer(triggerReducer, initialState);

    const setSourceCollection = (data: any, hiddenValues: any) => {
        dispatch({
            type: SET_SOURCE_COLLECTION,
            payload: {
                sourceCollection: data,
                hiddenValues: hiddenValues,
            },
        });
    };

    const getSourceCollection = (
        selectedParameterValue: string,
        isEdit?: boolean
    ) => {
        if (selectedParameterValue !== "0" && isEdit) {
            const selectionWithCurrentValue = {
                ...state.sourceCollection,
                collectionItems: state.sourceCollection.collectionItems.map(
                    (item: CollectionItem) => {
                        if (item.value === selectedParameterValue) {
                            return {
                                ...item,
                                isInvisible: false,
                            };
                        }
                        return item;
                    }
                ),
            };
            return selectionWithCurrentValue;
        }
        return state.sourceCollection;
    };

    const setValues = (values: any) => {
        setModalValues((prevValues: any) => ({
            ...prevValues,
            ...values,
        }));
    };

    const setSubmitLoading = (value: boolean) => {
        dispatch({
            type: SET_SUBMIT_LOADING,
            payload: value,
        });
    };

    const resetModalState = () => {
        setModalData({});
        setModalValues({});
        setErrors({});
    };

    const updateSourceCollection = (id: number, value: string) => {
        dispatch({
            type: UPDATE_SOURCE_COLLECTION,
            payload: {
                id,
                value,
            },
        });
    };

    const handleErrors = (errors: any) => {
        setErrors((prevErrors: any) => ({
            ...prevErrors,
            ...errors,
        }));
    };

    const hasErrors = () => {
        return Object.values(errors).some((error) => Boolean(error));
    };

    return (
        <TriggerContext.Provider
            value={useMemo(() => {
                return {
                    sourceCollection: state.sourceCollection,
                    hiddedValues: state.hiddenValues,
                    modalData: modalData,
                    setSourceCollection,
                    getSourceCollection,
                    setModalData,
                    setValues,
                    values: modalValues,
                    setSubmitLoading,
                    submitLoading: state.submitLoading,
                    resetModalState,
                    updateSourceCollection,
                    setErrors: handleErrors,
                    hasErrors: hasErrors(),
                    errors: errors,
                };
            }, [state, modalValues, errors, modalData])}
        >
            {children}
        </TriggerContext.Provider>
    );
};

export default TriggerState;
