/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useContext, useEffect } from "react";
import Joyride from "react-joyride";

import { isNullOrUndefined } from "../../utils/helpers";
import SettingsContext from "../../context/settings/settingsContext";
import MenuItemContext from "../../context/menuItem/menuItemContext";
import useDeviceGuide from "../../utils/useDeviceGuide";
import LayoutContext from "../../context/layout/layoutContext";
import GuideTooltip from "./GuideTooltip";

const DeviceGuide = () => {
    const {
        isDeviceGuideStepsOpen,
        deviceGuideStepIndex,
        setDeviceGuideButtonDisabled,
    } = useContext(SettingsContext);

    const { deviceGuide } = useContext(LayoutContext);

    const { findParameterValueById } = useContext(MenuItemContext);

    const { handleDeviceSetupClose } = useDeviceGuide();

    const steps =
        deviceGuide?.guideSteps.map(
            (
                { description, guideType, searchPath, localizationKey },
                index,
                arr
            ) => {
                const targetSelector = () => {
                    if (!isNullOrUndefined(searchPath?.componentIndex)) {
                        return `#component${String(
                            searchPath?.componentIndex
                        )}`;
                    } else if (!isNullOrUndefined(searchPath?.frame?.index)) {
                        return `#frame${String(searchPath?.frame?.index)}`;
                    } else {
                        return `#menuItem${String(
                            searchPath?.menuItem?.index
                        )}`;
                    }
                };

                const nextSearchPath = arr[index + 1]?.searchPath;
                const previousSearchPath = arr[index - 1]?.searchPath;

                const currentMenuItemIndex = searchPath?.menuItem?.index;
                const nextMenuItemIndex = nextSearchPath?.menuItem?.index;
                const previousMenuItemIndex =
                    previousSearchPath?.menuItem?.index;

                return {
                    content: description,
                    title: guideType,
                    target: targetSelector(),
                    disableBeacon: true,
                    nextSearchPath: nextSearchPath,
                    previousSearchPath: previousSearchPath,
                    hasNoRedirect: nextMenuItemIndex === currentMenuItemIndex,
                    previousStepHasNoRedirect:
                        previousMenuItemIndex === currentMenuItemIndex,
                    placement: "top" as const,
                    isButtonDisabled:
                        !arr[index]?.isChecked &&
                        !findParameterValueById(localizationKey) &&
                        !isNullOrUndefined(searchPath?.componentIndex),
                };
            }
        ) || [];

    const handleCallback = (data: any) => {
        const { action, step } = data;

        if (action === "update") {
            const setDisabled = step.isButtonDisabled;
            setDeviceGuideButtonDisabled(setDisabled);
        }
    };

    useEffect(() => {
        const closeTutorial = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                handleDeviceSetupClose();
            }
        };

        if (isDeviceGuideStepsOpen) {
            window.addEventListener("keydown", closeTutorial);
        }

        return () => {
            window.removeEventListener("keydown", closeTutorial);
        };
        //eslint-disable-next-line
    }, [isDeviceGuideStepsOpen]);

    return (
        <Joyride
            hideBackButton
            hideCloseButton
            callback={handleCallback}
            run={isDeviceGuideStepsOpen}
            stepIndex={deviceGuideStepIndex ?? -1}
            steps={steps}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
            disableOverlayClose={true}
            disableOverlay={false}
            tooltipComponent={GuideTooltip}
            floaterProps={{ hideArrow: true }}
            spotlightClicks={true}
            scrollOffset={100}
        />
    );
};

export default DeviceGuide;
