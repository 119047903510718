const useSwitchToggle = (props: {
    setParameter: (id: number, payload: string) => void,
    setRequestError: (value: React.SetStateAction<boolean>) => void,
    customSuccessCallback?: () => void,
    toggle: (state: boolean) => void
}) => {

    const onSuccessfulRequest = (params: {
        isSwitchedOn: boolean,
        payload: string,
        defaulValue: string,
        defaultOffValue: string,
        requestError: boolean,
        id: number
    }): void => {

        if (params.isSwitchedOn) {
            const payload: string = params.isSwitchedOn ? params.defaulValue : params.defaultOffValue;
            props.setParameter(params.id, payload);
        }

        if (params.requestError) {
            props.setRequestError(false);
        }

        if (props.customSuccessCallback) {
            props.customSuccessCallback();
        }

        !params.isSwitchedOn && props.setParameter(params.id, params.payload);
    };

    const onFailedRequest = (isChecked: boolean): void => {
        props.setRequestError(true);
        props.toggle(isChecked);
    };

    const onCancelRequest = (isChecked: boolean): void => {
        props.toggle(isChecked);
    };

    return {
        onSuccessfulRequest,
        onFailedRequest,
        onCancelRequest,
    }
};

export default useSwitchToggle;