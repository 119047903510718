import { useContext } from "react";

import SettingsContext from "../context/settings/settingsContext";
import { DeviceGuide } from "../generatedTypes";
import LayoutContext from "../context/layout/layoutContext";
import useApi from "./useApi";
import useTct from "./useTct";

const useDeviceGuideProvider = () => {
    const { layoutData, setDeviceGuide } = useContext(LayoutContext);

    const {
        closeDeviceGuideSteps,
        setDeviceGuideStepIndex,
        setIsDeviceGuideInProgress,
    } = useContext(SettingsContext);

    const { showErrorMsg } = useApi();

    const { getDeviceGuideAsync, setGuideStepAsCompletedAsync } = useTct();

    const getDeviceGuide = async (
        setInContext: boolean = true
    ): Promise<DeviceGuide | null> => {
        if (!layoutData) {
            throw new ReferenceError(
                "Cannot get device guide without a layout"
            );
        }

        try {
            const data = await getDeviceGuideAsync(layoutData.id);
            setInContext && setDeviceGuide(data);

            return data;
        } catch (error) {
            showErrorMsg(error as any);
        }

        return null;
    };

    const setGuideStepCompleted = async (stepIndex: number): Promise<void> => {
        if (!layoutData) {
            throw new ReferenceError(
                "Cannot set device guide step as completed without a layout"
            );
        }

        try {
            await setGuideStepAsCompletedAsync(layoutData.id, stepIndex);
        } catch (error) {
            showErrorMsg(error as any);
        }
    };

    const handleDeviceSetupClose = () => {
        closeDeviceGuideSteps();
        setDeviceGuideStepIndex(null);
        setIsDeviceGuideInProgress(false);
    };

    return { getDeviceGuide, setGuideStepCompleted, handleDeviceSetupClose };
};

export default useDeviceGuideProvider;
