/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment, useContext, useState } from "react";
import {
    Box,
    Collapse,
    ListItemButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore, Warning } from "@mui/icons-material";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import { ParamFail } from "../../../../generatedTypes";
import Tooltip from "../../../MuiComponents/Tooltip";
import DialogModal from "../../../MuiComponents/Modals/DialogModal";
import Tag from "../../../MuiComponents/Tag";
import { ModifiedParametersFixedType } from "../../../../utils/types";

export interface ProblemWithParams {
    message: string;
    parameters: ParamFail[];
}

export interface FailedParamModalProps {
    isOpen: boolean;
    modifiedParameters: ModifiedParametersFixedType;
    problem: ProblemWithParams | null;
    onFailedParameterDialogClose: () => void;
}

const FailedParamModal = ({
    isOpen,
    modifiedParameters,
    problem,
    onFailedParameterDialogClose,
}: FailedParamModalProps) => {
    const [openProblemDetails, setOpenProblemDetails] = useState(false);
    const {
        colors: { gray100 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);

    return (
        <DialogModal
            title={t.SomethingWentWrong}
            open={isOpen}
            close={onFailedParameterDialogClose}
            secondaryButtonText={t.Close}
            onSecondaryBtnClick={onFailedParameterDialogClose}
            isSecondaryButtonDisabled={false}
            description={
                <Fragment>
                    <Box
                        css={css({
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            paddingTop: "6px",
                        })}
                    >
                        {t.ConfigSaveSomeParamsFail}.
                    </Box>
                    <Box
                        css={css({
                            display: "flex",
                            margin: "10px 0",
                            padding: "6px",
                            border: "1px solid RGB(234, 88, 12)",
                            borderRadius: "8px",
                            background: "RGBA(234, 88, 12, 0.05)",
                        })}
                    >
                        <Warning
                            sx={{ color: "warning.main" }}
                            fontSize="small"
                        />
                        <Typography
                            css={css({
                                fontWeight: "bold",
                                fontSize: "0.7rem",
                                padding: "2px 0 0 10px",
                            })}
                        >
                            {t.ConfigSaveSomeParamsFailWarningFormat.replace(
                                "{0}",
                                `${problem?.parameters.length}`
                            ).replace(
                                "{1}",
                                `${modifiedParameters.parameters.length}`
                            )}
                            .
                        </Typography>
                    </Box>
                    <ListItemButton
                        onClick={() => {
                            setOpenProblemDetails(!openProblemDetails);
                        }}
                    >
                        <Box
                            css={css({
                                width: "100%",
                                justifyContent: "space-between",
                                display: "flex",
                                fontWeight: "600",
                            })}
                        >
                            <span>
                                {openProblemDetails
                                    ? `${t.HideAll} (${problem?.parameters.length}) ${t.ErrorsInThisFile}`
                                    : `${t.ShowAll} (${problem?.parameters.length}) ${t.ErrorsInThisFile}`}
                            </span>
                            {openProblemDetails ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </Box>
                    </ListItemButton>
                    <Box
                        css={css({
                            display: "block",
                            maxHeight: "40vh",
                            overflowY: "auto",
                            border: `1px solid ${gray100}`,
                            borderRadius: "8px",
                        })}
                    >
                        <Collapse in={openProblemDetails}>
                            <Table>
                                <TableBody
                                    css={css({
                                        "tr, td": { padding: "6px", border: 0 },
                                    })}
                                >
                                    {problem?.parameters.map((p) => {
                                        const parameter =
                                            modifiedParameters.parameters.find(
                                                (x) => x.parameterId === p.id
                                            );
                                        const label = parameter?.label;
                                        return (
                                            <TableRow key={p.id}>
                                                <TableCell
                                                    css={css({
                                                        verticalAlign: "top",
                                                    })}
                                                >
                                                    <Tooltip
                                                        title={p.id}
                                                        small
                                                        placement="top"
                                                    >
                                                        <Typography component="span">
                                                            <Tag
                                                                size="tiny"
                                                                color="white"
                                                                title="ID"
                                                            />
                                                        </Typography>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell
                                                    title={`${t.Value}: ${
                                                        p.value
                                                    }, ${t.Previous?.toLowerCase()}: ${
                                                        parameter?.oldParameterValue
                                                    }`}
                                                >
                                                    <Box
                                                        css={css({
                                                            fontWeight: "bold",
                                                        })}
                                                    >
                                                        {label}
                                                    </Box>
                                                    <Box
                                                        css={css({
                                                            fontSize: "0.8rem",
                                                        })}
                                                    >
                                                        {p.failReason}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </Box>
                </Fragment>
            }
        />
    );
};

export default FailedParamModal;
