import React, { MouseEvent, useMemo, useReducer } from "react";
import { ulid } from "ulid";
import moment from "moment";
import SettingsContext from "./settingsContext";
import SettingsReducer, { NotificationType } from "./settingsReducer";
import {
    CLOSE_ACTIVATE_KEY_MODAL,
    CLOSE_DEVICE_GUIDE_STEPS,
    CLOSE_DEVICE_SET_UP_MODAL,
    CLOSE_GENERAL_SETTINGS_MODAL,
    CLOSE_INTERFACE_SETTINGS_MODAL,
    CLOSE_LANGUAGE_SETTINGS_MODAL,
    CLOSE_NOTIFICATION_MODAL,
    CLOSE_SETTINGS_MODAL,
    CLOSE_SYSTEM_INFORMATION_MODAL,
    CLOSE_TERMINAL,
    CLOSE_VERSION_HISTORY_MODAL,
    CLOSE_VERSION_INFO_MODAL,
    HIDE_EXPLANATORY_TEXTS,
    HIDE_PARAMETER_IDS,
    OPEN_ACTIVATE_KEY_MODAL,
    OPEN_DEVICE_GUIDE_STEPS,
    OPEN_DEVICE_SET_UP_MODAL,
    OPEN_GENERAL_SETTINGS_MODAL,
    OPEN_INTERFACE_SETTINGS_MODAL,
    OPEN_LANGUAGE_SETTINGS_MODAL,
    OPEN_NOTIFICATION_MODAL,
    OPEN_PRIVACY_POLICY_MODAL,
    OPEN_SETTINGS_MODAL,
    OPEN_SYSTEM_INFORMATION_MODAL,
    OPEN_TERMINAL,
    OPEN_VERSION_HISTORY_MODAL,
    OPEN_VERSION_INFO_MODAL,
    READ_ALL_COMMON_NOTIFICATIONS,
    READ_NOTIFICATION,
    SET_DEVICE_GUIDE_BUTTON_DISABLED,
    SET_DEVICE_GUIDE_STEP_INDEX,
    SET_IS_DEVICE_GUIDE_IN_PROGRESS,
    SET_NOTIFICATION,
    SET_RELEASE_NOTES,
    SET_UPDATE_DOWNLOADED,
    SET_UPDATE_DOWNLOADING,
    SET_UPDATE_DOWNLOADING_PERCENTAGE,
} from "./settingsActions";
import { FEElement } from "../../generatedTypes";
import { DATE_FORMATS } from "../../constants/constants";

interface SettingsStateProps {
    children: React.ReactNode;
}

const SettingsState = ({ children }: SettingsStateProps) => {
    const setCurrentNotifications = () => {
        const getCurrentNotifications = () => {
            try {
                return JSON.parse(
                    sessionStorage.getItem("latestNotifications")!
                );
            } catch (error) {
                return [];
            }
        };

        const latest = getCurrentNotifications();
        const isArray: boolean = Array.isArray(latest);

        return isArray ? latest : [];
    };

    const initialState = {
        anchorEl: null,
        latestNotifications: setCurrentNotifications(),
        unreadNotificationsCount: 0,
        isNotificationModalOpen: false,
        isTerminalOpen: false,
        isSettingsModalOpen: false,
        isGeneralSettingsModalOpen: false,
        isInterfaceSettingsModalOpen: false,
        previousAnchorEl: null,
        isLanguageSettingsModalOpen: false,
        isVersionHistoryModalOpen: false,
        isVersionInfoModalOpen: false,
        isParameterIdsHidden: false,
        isExplanatoryTextsHidden: false,
        isActivateKeyModalOpen: false,
        isSystemInformationModalOpen: false,
        firmwareVersion: "",
        updateInformation: {
            isUpdateDownloading: false,
            isUpdateDownloaded: false,
            releaseInformation: [],
        },
        downloadingPercentage: null,
        deviceGuideStepIndex: null,
        isDeviceSetUpModalOpen: false,
        isDeviceGuideStepsOpen: false,
        isDeviceGuideButtonDisabled: false,
        isDeviceGuideInProgress: false,
    };
    const [state, dispatch] = useReducer(SettingsReducer, initialState);

    const openNotificationsModal = (e: MouseEvent<HTMLButtonElement>) =>
        dispatch({ type: OPEN_NOTIFICATION_MODAL, payload: e.currentTarget });

    const closeNotificationsModal = () =>
        dispatch({ type: CLOSE_NOTIFICATION_MODAL });

    const openDeviceSetUpModal = (e: MouseEvent<HTMLButtonElement>) =>
        dispatch({ type: OPEN_DEVICE_SET_UP_MODAL, payload: e.currentTarget });

    const closeDeviceSetUpModal = () =>
        dispatch({ type: CLOSE_DEVICE_SET_UP_MODAL });

    const openActivateKeyModal = () =>
        dispatch({ type: OPEN_ACTIVATE_KEY_MODAL });

    const closeActivateKeyModal = () =>
        dispatch({ type: CLOSE_ACTIVATE_KEY_MODAL });

    const openSettingsModal = (e?: MouseEvent<HTMLButtonElement>) =>
        dispatch({
            type: OPEN_SETTINGS_MODAL,
            payload: e?.currentTarget || state.previousAnchorEl,
        });

    const closeSettingsModal = () => dispatch({ type: CLOSE_SETTINGS_MODAL });

    const openGeneralSettingsModal = () =>
        dispatch({
            type: OPEN_GENERAL_SETTINGS_MODAL,
        });

    const openSystemInformationModal = () =>
        dispatch({
            type: OPEN_SYSTEM_INFORMATION_MODAL,
        });

    const closeSystemInformationModal = () =>
        dispatch({
            type: CLOSE_SYSTEM_INFORMATION_MODAL,
        });
    const openInterfaceSettingsModal = () =>
        dispatch({ type: OPEN_INTERFACE_SETTINGS_MODAL });

    const closeInterfaceSettingsModal = () =>
        dispatch({ type: CLOSE_INTERFACE_SETTINGS_MODAL });

    const openLanguageSettingsModal = () =>
        dispatch({ type: OPEN_LANGUAGE_SETTINGS_MODAL });

    const closeLanguageSettingsModal = () =>
        dispatch({ type: CLOSE_LANGUAGE_SETTINGS_MODAL });

    const openVersionHistoryModal = () =>
        dispatch({ type: OPEN_VERSION_HISTORY_MODAL });

    const openVersionInfoModal = () =>
        dispatch({ type: OPEN_VERSION_INFO_MODAL });

    const closeVersionInfoModal = () =>
        dispatch({ type: CLOSE_VERSION_INFO_MODAL });

    const closeVersionHistoryModal = () =>
        dispatch({ type: CLOSE_VERSION_HISTORY_MODAL });

    const openPrivacyPolicyModal = () =>
        dispatch({ type: OPEN_PRIVACY_POLICY_MODAL });

    const closeGeneralSettingsModal = () =>
        dispatch({ type: CLOSE_GENERAL_SETTINGS_MODAL });

    const setNotification = (
        type: NotificationType,
        title: string,
        description: string,
        dynamicContent?: string
    ) =>
        dispatch({
            type: SET_NOTIFICATION,
            payload: {
                id: ulid(),
                type,
                timeStamp: moment().utc().format(DATE_FORMATS.ISO_8601),
                title,
                description,
                isRead: false,
                dynamicContent,
            },
        });

    const readNotification = (id: string) =>
        dispatch({ type: READ_NOTIFICATION, payload: id });

    const readAllCommonNotifications = () =>
        dispatch({ type: READ_ALL_COMMON_NOTIFICATIONS });

    const hideExplanatoryTexts = (isHidden: boolean) =>
        dispatch({ type: HIDE_EXPLANATORY_TEXTS, payload: isHidden });

    const hideParameterIds = (isHidden: boolean) =>
        dispatch({ type: HIDE_PARAMETER_IDS, payload: isHidden });

    const setReleaseNotes = (releaseNotes: FEElement[] | null) =>
        dispatch({
            type: SET_RELEASE_NOTES,
            payload: releaseNotes,
        });

    const setUpdateDownloaded = () => dispatch({ type: SET_UPDATE_DOWNLOADED });

    const setUpdateDownloading = (state: boolean) =>
        dispatch({ type: SET_UPDATE_DOWNLOADING, payload: state });

    const setDownloadingPercentage = (state: number | null) =>
        dispatch({ type: SET_UPDATE_DOWNLOADING_PERCENTAGE, payload: state });

    const openTerminal = () => dispatch({ type: OPEN_TERMINAL });

    const closeTerminal = () => dispatch({ type: CLOSE_TERMINAL });

    const openDeviceGuideSteps = () =>
        dispatch({ type: OPEN_DEVICE_GUIDE_STEPS });

    const closeDeviceGuideSteps = () =>
        dispatch({ type: CLOSE_DEVICE_GUIDE_STEPS });

    const setDeviceGuideStepIndex = (stepIndex: number | null) =>
        dispatch({ type: SET_DEVICE_GUIDE_STEP_INDEX, payload: stepIndex });

    const setDeviceGuideButtonDisabled = (disabled: boolean) =>
        dispatch({ type: SET_DEVICE_GUIDE_BUTTON_DISABLED, payload: disabled });

    const setIsDeviceGuideInProgress = (isInProgress: boolean) => {
        dispatch({
            type: SET_IS_DEVICE_GUIDE_IN_PROGRESS,
            payload: isInProgress,
        });
    };

    const contextValue = useMemo(
        () => ({
            anchorEl: state.anchorEl,
            previousAnchorEl: state.previousAnchorEl,
            latestNotifications: state.latestNotifications,
            unreadNotificationsCount: state.unreadNotificationsCount,
            isNotificationModalOpen: state.isNotificationModalOpen,
            isSettingsModalOpen: state.isSettingsModalOpen,
            isGeneralSettingsModalOpen: state.isGeneralSettingsModalOpen,
            isInterfaceSettingsModalOpen: state.isInterfaceSettingsModalOpen,
            isLanguageSettingsModalOpen: state.isLanguageSettingsModalOpen,
            isVersionHistoryModalOpen: state.isVersionHistoryModalOpen,
            isParameterIdsHidden: state.isParameterIdsHidden,
            isExplanatoryTextsHidden: state.isExplanatoryTextsHidden,
            isDeviceSetUpModalOpen: state.isDeviceSetUpModalOpen,
            isDeviceGuideStepsOpen: state.isDeviceGuideStepsOpen,
            deviceGuideStepIndex: state.deviceGuideStepIndex,
            isDeviceGuideButtonDisabled: state.isDeviceGuideButtonDisabled,
            isActivateKeyModalOpen: state.isActivateKeyModalOpen,
            firmwareVersion: state.firmwareVersion,
            isVersionInfoModalOpen: state.isVersionInfoModalOpen,
            isSystemInformationModalOpen: state.isSystemInformationModalOpen,
            isTerminalOpen: state.isTerminalOpen,
            updateInformation: state.updateInformation,
            downloadingPercentage: state.downloadingPercentage,
            isDeviceGuideInProgress: state.isDeviceGuideInProgress,
            readAllCommonNotifications,
            openTerminal,
            closeTerminal,
            openNotificationsModal,
            openActivateKeyModal,
            closeActivateKeyModal,
            closeNotificationsModal,
            closeVersionInfoModal,
            openLanguageSettingsModal,
            closeLanguageSettingsModal,
            openVersionHistoryModal,
            closeVersionHistoryModal,
            openPrivacyPolicyModal,
            openInterfaceSettingsModal,
            closeInterfaceSettingsModal,
            openGeneralSettingsModal,
            closeGeneralSettingsModal,
            setNotification,
            readNotification,
            openSettingsModal,
            closeSettingsModal,
            hideExplanatoryTexts,
            hideParameterIds,
            setReleaseNotes,
            setUpdateDownloaded,
            setUpdateDownloading,
            openVersionInfoModal,
            openSystemInformationModal,
            closeSystemInformationModal,
            setDownloadingPercentage,
            openDeviceSetUpModal,
            closeDeviceSetUpModal,
            closeDeviceGuideSteps,
            openDeviceGuideSteps,
            setDeviceGuideStepIndex,
            setDeviceGuideButtonDisabled,
            setIsDeviceGuideInProgress,
        }),
        [
            state.anchorEl,
            state.previousAnchorEl,
            state.latestNotifications,
            state.unreadNotificationsCount,
            state.isNotificationModalOpen,
            state.isSettingsModalOpen,
            state.isGeneralSettingsModalOpen,
            state.isInterfaceSettingsModalOpen,
            state.isLanguageSettingsModalOpen,
            state.isVersionHistoryModalOpen,
            state.isParameterIdsHidden,
            state.isExplanatoryTextsHidden,
            state.isDeviceSetUpModalOpen,
            state.isDeviceGuideStepsOpen,
            state.deviceGuideStepIndex,
            state.isDeviceGuideButtonDisabled,
            state.isActivateKeyModalOpen,
            state.firmwareVersion,
            state.isVersionInfoModalOpen,
            state.isSystemInformationModalOpen,
            state.isTerminalOpen,
            state.updateInformation,
            state.downloadingPercentage,
            state.isDeviceGuideInProgress,
            hideExplanatoryTexts,
            hideParameterIds,
            setIsDeviceGuideInProgress,
        ]
    );

    return (
        <SettingsContext.Provider value={contextValue}>
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsState;
