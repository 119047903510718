/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import DialogModal from "../../../../../MuiComponents/Modals/DialogModal";
import { useContext } from "react";
import LanguageContext from "../../../../../../context/language/languageContext";

interface DevicePasswordModalProps {
    isOpen: boolean;
    closeModal: () => void;
    onClick: (savePassword: boolean) => void;
    title: string;
    description: string;
}

const DevicePasswordModal = ({
    isOpen,
    closeModal,
    onClick,
    title,
    description,
}: DevicePasswordModalProps) => {
    const { t } = useContext(LanguageContext);

    return (
        <DialogModal
            title={title}
            open={isOpen}
            close={closeModal}
            primaryButtonText={t.Yes}
            onPrimaryBtnClick={() => onClick(true)}
            secondaryButtonText={t.No}
            onSecondaryBtnClick={() => onClick(false)}
            withCancel
            description={description}
        />
    );
};

export default DevicePasswordModal;
