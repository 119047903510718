/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import {
    ChangeEvent,
    Fragment,
    MouseEvent,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import ThemeContext from "../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../context/language/languageContext";
import AlertContext from "../../../../../context/alert/alertContext";
import useApi from "../../../../../utils/useApi";
import enterPasswordModalReducer from "./enterPasswordModalReducer";
import PasswordModal from "../../../../MuiComponents/Modals/PasswordModal";
import InputField from "../../../../MuiComponents/InputField";
import Tooltip from "../../../../MuiComponents/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { noop } from "../../../../../utils/helpers";
import {
    HANDLE_CHANGE_PASSWORD,
    HANDLE_ERROR,
    HANDLE_VISIBILITY,
    SET_LOADING,
} from "./enterPasswordModalActions";
import DeviceAuthorizationContext from "../../../../../context/deviceAuthorization/deviceAuthorizationContext";
import { AlertStatus, PasswordTypes } from "../../../../../constants/constants";
import SetNewPassword from "./SetNewPassword";
import LayoutContext from "../../../../../context/layout/layoutContext";
import { useHistory } from "react-router-dom";
import { START_UP } from "../../../../../constants/routes";
import useUrlQueryParams from "../../../../../utils/useUrlQueryParams";

const EnterPasswordModal = () => {
    const {
        colors: { red800 },
    } = useContext(ThemeContext);

    const { selectedLanguage } = useContext(LanguageContext);
    const { setDeviceConfigureButtonPortName } = useContext(LayoutContext);
    const { setAlert } = useContext(AlertContext);
    const {
        isPasswordModalOpen,
        setPasswordModalOpen,
        port,
        connectionType,
        connectAction,
        openExistingUnlockDeviceModal,
        infoData,
        onSetInfoData,
    } = useContext(DeviceAuthorizationContext);
    const { postData, isAxiosError, disconnectFromDevice } = useApi();
    const history = useHistory();

    const { pathname } = useUrlQueryParams();

    const isEditorRoute = pathname !== START_UP;

    const modalTitle = infoData?.keywordInfo?.unauthorizedMessageTitle || "";
    const modalDescription =
        infoData?.keywordInfo?.unauthorizedMessageDetails || "";

    const passwordType = infoData?.keywordInfo?.keyType || "";

    const shouldRedirectToStartupOnCancel =
        infoData?.shouldRedirectToStartupOnCancel || false;

    const initialState = {
        isLoading: false,
        isDisabled: true,
        password: "",
        passwordShow: false,
        errorPassword: null,
        attemptsLeft: null,
    };

    const [isEnterNewPasswordModalOpen, setEnterNewPasswordModalOpen] =
        useState(false);

    const [state, dispatch] = useReducer(
        enterPasswordModalReducer,
        initialState
    );

    const {
        isLoading,
        isDisabled,
        password,
        passwordShow,
        errorPassword,
        attemptsLeft,
    } = state;

    const setLoading = (isLoadingSet: boolean) =>
        dispatch({ type: SET_LOADING, payload: isLoadingSet });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
        dispatch({
            type: HANDLE_CHANGE_PASSWORD,
            payload: e.target.value,
        });

    const handleToggleVisibility = (e: MouseEvent<HTMLButtonElement>) => {
        dispatch({ type: HANDLE_VISIBILITY });
        e.preventDefault();
    };

    const handleError = (errorMsg: string | null, attempts: number | null) =>
        dispatch({ type: HANDLE_ERROR, payload: { errorMsg, attempts } });

    useEffect(() => {
        handleChange({ target: { value: "" } } as any);
        handleError(null, null);
    }, [passwordType, isPasswordModalOpen]);

    const handleClose = async () => {
        await disconnectFromDevice(selectedLanguage, true);
        setDeviceConfigureButtonPortName(port);
        setPasswordModalOpen(false);
        shouldRedirectToStartupOnCancel && history.replace(START_UP);
    };

    const handleCloseNewPassword = async () => {
        await disconnectFromDevice(selectedLanguage, true);
        setDeviceConfigureButtonPortName(port);
        shouldRedirectToStartupOnCancel && history.replace(START_UP);
        setEnterNewPasswordModalOpen(false);
    };

    const sendPayload = async () => {
        try {
            setLoading(true);
            await postData(`${selectedLanguage}/device/enterpassword`, {
                port,
                password,
                connectionType,
            });
            if (passwordType === PasswordTypes.Recovery) {
                setPasswordModalOpen(false);
                setLoading(false);
                setEnterNewPasswordModalOpen(true);
            } else {
                connectAction(true);
            }
        } catch (error) {
            if (isAxiosError(error) && (error as any).response) {
                const errorStatus = (error as any).response.status;
                const errorData = (error as any).response.data;

                if (errorStatus === 401) {
                    const authorizationInfo = JSON.parse(errorData.detail);
                    onSetInfoData({
                        ...authorizationInfo,
                        shouldRedirectToStartupOnCancel:
                            shouldRedirectToStartupOnCancel,
                    });
                    if (authorizationInfo.requiresUnlock) {
                        if (isEditorRoute) {
                            history.replace(START_UP);
                        } else {
                            openExistingUnlockDeviceModal(
                                authorizationInfo.unlockCode
                            );
                        }
                        await handleClose();
                    } else {
                        handleError(
                            "Wrong password",
                            authorizationInfo.passwordAttemptsLeft
                        );
                    }

                    setLoading(false);
                } else {
                    setAlert(AlertStatus.Critical, errorData.title);
                    await handleClose();
                }
            }
        }
    };

    return (
        <Fragment>
            <PasswordModal
                isOpen={isPasswordModalOpen}
                isDisabled={isDisabled}
                isLoading={isLoading}
                title={modalTitle}
                submitBtnText="Enter"
                close={handleClose}
                sendPayload={sendPayload}
                description={modalDescription}
                hasEnterKeySubmit
            >
                <InputField
                    fullWidth
                    size="medium"
                    label="Password"
                    placeholder="Enter"
                    type={passwordShow ? "text" : "password"}
                    error={errorPassword}
                    onFocus={() =>
                        errorPassword ? handleError(null, attemptsLeft) : noop()
                    }
                    onChange={handleChange}
                    iconRight={
                        <Tooltip
                            title={
                                passwordShow ? "Hide password" : "Show password"
                            }
                            placement="top-end"
                            css={css({
                                "& .MuiTooltip-tooltip": {
                                    marginRight: "-6px",
                                },
                            })}
                        >
                            <IconButton
                                disableRipple
                                onMouseDown={handleToggleVisibility}
                                size="large"
                            >
                                {passwordShow ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </Tooltip>
                    }
                    value={password}
                />

                {attemptsLeft && (
                    <div
                        css={css({
                            color: red800,
                            fontWeight: "600",
                            textAlign: "center",
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            marginTop: "16px",
                        })}
                    >
                        {attemptsLeft} attempts left
                    </div>
                )}
            </PasswordModal>
            <SetNewPassword
                isOpen={isEnterNewPasswordModalOpen}
                close={handleCloseNewPassword}
                connectAction={connectAction}
                shouldRedirectToStartupOnCancel={
                    shouldRedirectToStartupOnCancel
                }
            />
        </Fragment>
    );
};

export default EnterPasswordModal;
