/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { useContext } from "react";
import { useHistory } from "react-router-dom";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import ModelImage from "../../ModelImage";
import Technology from "../Technology";
import Button from "../../../MuiComponents/Button";
import Divider from "@mui/material/Divider";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import { EDITOR_STATUS } from "../../../../constants/routes";
import useConfiguration from "../../../../utils/useConfiguration";

import ExpFwIndicator from "../../../MuiComponents/ExpFwIndicator";
import ConfigurationContext from "../../../../context/configuration/configurationContext";

export interface SingleMobileCardProps {
    data: any;
}

const SingleMobileCard = ({ data }: SingleMobileCardProps) => {
    const { version, fmType, technologies } = data;

    const {
        colors: { white, gray200, textDark, textPlaceholder },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);
    const { setLayoutError } = useContext(LayoutContext);
    const { hasExperimentalFirmwareApplied } = useContext(ConfigurationContext);

    const history = useHistory();
    const { getConfigurationFromOffline } = useConfiguration();

    const getLayoutData = async () => {
        history.push(EDITOR_STATUS);

        try {
            await getConfigurationFromOffline(version);
        } catch (error) {
            setLayoutError(error);
        }
    };

    return (
        <div
            css={css`
                padding: 12px 8px;
                background: ${white};
            `}
        >
            <div
                css={css`
                    display: flex;
                    margin-bottom: 16px;
                `}
            >
                <ModelImage fmType={fmType} isConfigurable={true} />

                <div>
                    <span
                        css={css({
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            marginBottom: "8px",
                        })}
                    >
                        <span
                            css={css`
                                color: ${textDark};
                                font-size: 16px;
                                line-height: 20px;
                                font-weight: 600;
                                letter-spacing: 0.15px;
                            `}
                        >
                            {fmType}
                        </span>
                        {hasExperimentalFirmwareApplied && <ExpFwIndicator />}
                    </span>
                    <div>
                        <div
                            css={css`
                                color: ${textPlaceholder};
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 600;
                                letter-spacing: 0.4px;
                                margin-bottom: 4px;
                            `}
                        >
                            {t.Technology}
                        </div>

                        <div
                            css={css`
                                margin-left: -4px;
                                margin-top: -8px;

                                & > span {
                                    display: inline-block;
                                    margin: 8px 4px 0 4px;
                                }
                            `}
                        >
                            {technologies.length > 0 ? (
                                technologies.map((tech: any) => (
                                    <Technology key={tech} name={tech} />
                                ))
                            ) : (
                                <Technology name="legacy" />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Divider
                sx={{
                    borderColor: gray200,
                    marginBottom: "16px",
                }}
            />

            <Button
                fullWidth
                variant="iconRight"
                size="small"
                color="primary"
                icon={<ChevronRightIcon />}
                onClick={getLayoutData}
                idForTesting={`configure-${version}`}
            >
                {t.Configure}
            </Button>
        </div>
    );
};

export default SingleMobileCard;
