import { useContext } from "react";

import { DeviceGuide, DeviceGuideStep } from "../../generatedTypes";
import useDeviceGuideProvider from "../../utils/useDeviceGuide";
import { DeviceGuideType } from "../../constants/constants";
import LanguageContext from "../../context/language/languageContext";
import SettingsContext from "../../context/settings/settingsContext";
import LayoutContext from "../../context/layout/layoutContext";
import useNavigation from "../../utils/useNavigation";

const useDeviceGuideButtons = (
    isLastStep: boolean,
    index: number,
    stepGroupLength: number,
    currentStepCount: number,
    currentStep: DeviceGuideStep | undefined,
    step: any
) => {
    const {
        isDeviceGuideButtonDisabled,
        closeDeviceGuideSteps,
        setDeviceGuideStepIndex,
        setIsDeviceGuideInProgress,
    } = useContext(SettingsContext);

    const { deviceGuide, setDeviceGuide } = useContext(LayoutContext);

    const { t } = useContext(LanguageContext);

    const { setGuideStepCompleted } = useDeviceGuideProvider();

    const { handleDeviceGuideNavigation } = useNavigation();

    const isLastStepInGroup = currentStepCount === stepGroupLength;
    const isNecessaryGroup =
        currentStep?.guideType === (DeviceGuideType.Necessary as string);

    const updateGuideStepToChecked = (
        indexToUpdate: number,
        updatedStepData: Partial<DeviceGuideStep>
    ) => {
        if (deviceGuide) {
            const updatedDeviceGuide: DeviceGuide = {
                ...deviceGuide,
                guideSteps: deviceGuide.guideSteps.map((step, index) =>
                    index === indexToUpdate
                        ? { ...step, ...updatedStepData }
                        : step
                ),
            };

            setDeviceGuide(updatedDeviceGuide);
        }
    };

    const handleProceedButton = async () => {
        try {
            if (isLastStep) {
                setIsDeviceGuideInProgress(false);
                closeDeviceGuideSteps();
                setDeviceGuideStepIndex(null);
            }

            if (!isLastStep) {
                if (isNecessaryGroup && !isLastStepInGroup) {
                    updateGuideStepToChecked(index, { isChecked: true });
                }

                handleDeviceGuideNavigation(
                    step.nextSearchPath,
                    index + 1,
                    step.hasNoRedirect
                );
            }
            if (
                currentStep &&
                currentStep.guideType === (DeviceGuideType.Optional as string)
            ) {
                await setGuideStepCompleted(index);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handlePreviousButton = () => {
        if (isNecessaryGroup && !isDeviceGuideButtonDisabled) {
            updateGuideStepToChecked(index, { isChecked: true });
        }

        handleDeviceGuideNavigation(
            step.previousSearchPath,
            index - 1,
            step.previousStepHasNoRedirect
        );
    };

    const getProceedButtonText = () => {
        if (isLastStepInGroup) {
            return t.Finish;
        }

        return t.Next;
    };

    const showPreviousButton = () => currentStepCount !== 1;

    return {
        getProceedButtonText,
        handleProceedButton,
        handlePreviousButton,
        showPreviousButton,
    };
};

export default useDeviceGuideButtons;
