import { useState, useEffect, useRef } from "react";

export const useOverflow = (dependencyArray: any[] = []) => {
    const elementRef = useRef<any>(null);
    const [isOverflowing, setOverflowing] = useState(false);

    const handleOverflowing = () => {
        if (elementRef.current) {
            setOverflowing(
                elementRef.current.scrollWidth > elementRef.current.clientWidth
            );
        }
    };

    useEffect(() => {
        const element = elementRef.current;
        if (element) {
            const resizeObserver = new ResizeObserver(() => {
                handleOverflowing();
            });
            resizeObserver.observe(element);

            handleOverflowing();

            return () => resizeObserver.disconnect();
        }
    }, [elementRef.current, ...dependencyArray]);

    return {
        elementRef,
        isOverflowing,
    };
};
