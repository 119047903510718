/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, Theme } from "@emotion/react";
import {
    ListItemText,
    MenuItem,
    SelectChangeEvent,
    SxProps,
} from "@mui/material";
import { useContext } from "react";

import { useStyles } from "./styles/styles";
import Checkbox from "../../../../../../../MuiComponents/Checkbox";
import Select from "../../../../../../../MuiComponents/Select";
import {
    IoTableColumns,
    operandColumns,
} from "../../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../../context/inputOutput/inputOutputContext";
import LayoutContext from "../../../../../../../../context/layout/layoutContext";
import { DeviceFamily } from "../../../../../../../../utils/types";
import ThemeContext from "../../../../../../../../context/theme/themeContext";
import LanguageContext from "../../../../../../../../context/language/languageContext";

const OperandSelect = () => {
    const { setActiveOperand, activeOperand, activeColumns } =
        useContext(InputOutputContext);

    const {
        colors: { white, gray100, textPlaceholder },
    } = useContext(ThemeContext);

    const { layoutData } = useContext(LayoutContext);

    const { t } = useContext(LanguageContext);

    const { listItemTextCSS, menuItemCSS } = useStyles(white, gray100);

    const handleChange = (event: SelectChangeEvent<any>) => {
        const value = event.target.value;
        setActiveOperand(value);
    };

    const isCheckboxChecked = (value: string) => activeOperand.includes(value);

    const isDisabled = !activeColumns.some(
        (item) => item === IoTableColumns.operand
    );

    const isFtcDeviceFamily =
        !!layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    return (
        <Select
            multiple
            value={activeOperand}
            renderValue={() => {
                return (
                    <div css={css({ color: textPlaceholder })}>{t.Operand}</div>
                );
            }}
            onChange={handleChange}
            wrapperStyle={css({ marginBottom: "12px" })}
            placeholder={t.Operand}
            disabled={isDisabled}
        >
            {operandColumns(isFtcDeviceFamily).map(
                (column: { name: string; value: string }) => (
                    <MenuItem
                        key={column.name}
                        disableRipple
                        value={column.value}
                        css={css(menuItemCSS)}
                    >
                        <ListItemText
                            primary={column.name}
                            sx={listItemTextCSS as SxProps<Theme>}
                        />

                        <Checkbox checked={isCheckboxChecked(column.value)} />
                    </MenuItem>
                )
            )}
        </Select>
    );
};

export default OperandSelect;
