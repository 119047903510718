import { SerializedStyles } from "@emotion/react";
import React, { forwardRef, useContext, useState } from "react";
import MenuItemContext from "../../../../../../context/menuItem/menuItemContext";
import useNavigation from "../../../../../../utils/useNavigation";
import useDebounce from "../../../../../../utils/useDebounce";
import TooltipLarge from "../../../../../MuiComponents/TooltipLarge";
import Switch from "../../../../../MuiComponents/Switch";
import Tooltip from "../../../../../MuiComponents/Tooltip";
import Tag from "../../../../../MuiComponents/Tag";
import { getIdTooltipText } from "../../../../../../utils/helpers";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import { DisabledTooltip, SearchPath } from "../../../../../../generatedTypes";
import useSwitchToggle from "./useSwitchToggle";

type Ref = HTMLInputElement;

interface SwitchToggleProps {
    id: number;
    avlId: number;
    defaulValue: string;
    switchedOn: boolean;
    label: string;
    elementRef: React.MutableRefObject<any>;
    toggle: (state: boolean) => void;
    dependentParameters: number[] | null;
    disabledTooltip?: DisabledTooltip;
    wrapperStyle?: SerializedStyles;
    defaultOffValue?: string;
    customSuccessCallback?: () => void;
}

const SwitchToggle = forwardRef<Ref, SwitchToggleProps>(
    (
        {
            id,
            avlId,
            defaulValue,
            switchedOn,
            label,
            elementRef,
            toggle,
            dependentParameters,
            disabledTooltip,
            wrapperStyle,
            defaultOffValue = "0",
            customSuccessCallback,
        },
        ref
    ) => {
        const {
            setParameter,
            updateParameter,
            findDisabledParameterById,
            setDisabledParameters,
        } = useContext(MenuItemContext);
        const { isParameterIdsHidden } = useContext(SettingsContext);

        const { handleSearchNavigation } = useNavigation();

        const [loading, setLoading] = useState(false);
        const [requestError, setRequestError] = useState(false);

        const isSwitchDisabled = findDisabledParameterById(id);
        const debouncedChangeHandler = useDebounce(updateParameter, 500);

        const dependenciesToDisable =
            dependentParameters &&
            dependentParameters.length > 0 &&
            dependentParameters.map((visibility: any) => {
                return {
                    parameterId: visibility.value,
                    isDisabled: true,
                };
            });
        const disableDependencies = () => {
            dependenciesToDisable &&
                setDisabledParameters(dependenciesToDisable);
        };

        const {
            onSuccessfulRequest,
            onFailedRequest,
            onCancelRequest,
        } = useSwitchToggle({
            setParameter,
            setRequestError,
            customSuccessCallback,
            toggle
        });

        const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>): void => {
            const isSwitchedOn: boolean = e.target.checked;
            const payload: string = isSwitchedOn
                ? defaulValue
                : defaultOffValue;
            toggle(isSwitchedOn);
            setLoading(true);

            disableDependencies();

            debouncedChangeHandler(
                id,
                payload,
                label,
                elementRef,
                () => onSuccessfulRequest({ isSwitchedOn, payload, defaulValue, defaultOffValue, requestError, id }),
                () => onFailedRequest(!isSwitchedOn),
                () => setLoading(false),
                false,
                () => onCancelRequest(!isSwitchedOn)
            );
        };

        return (
            <TooltipLarge
                title={
                    isSwitchDisabled && disabledTooltip
                        ? disabledTooltip.title
                        : ""
                }
                buttonText={
                    disabledTooltip?.btnTitle ? disabledTooltip.btnTitle : ""
                }
                onBtnClick={
                    disabledTooltip
                        ? () =>
                            handleSearchNavigation(
                                disabledTooltip as { searchPath: SearchPath }
                            )
                        : () => { }
                }
            >
                <span>
                    <Switch
                        ref={ref}
                        checked={switchedOn}
                        onChange={handleSwitch}
                        size="medium"
                        label={
                            !isParameterIdsHidden && (
                                <Tooltip
                                    title={getIdTooltipText(id, avlId)}
                                    small
                                    placement="top"
                                >
                                    <span>
                                        <Tag
                                            size="tiny"
                                            color="white"
                                            title="ID"
                                        />
                                    </span>
                                </Tooltip>
                            )
                        }
                        labelPlacement="start"
                        requesting={loading}
                        requestFailed={requestError}
                        wrapperStyle={wrapperStyle}
                        disabled={isSwitchDisabled}
                        idForTesting={`switch-${id}`}
                    />
                </span>
            </TooltipLarge>
        );
    }
);

export default SwitchToggle;
