/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from "@emotion/react";

import { CustomScenario as CustomScenarioData } from "../../../../../generatedTypes";

import CustomScenario from "./CustomScenario";
import TriggerState from "./context/TriggerState";

export interface CustomScenarioWithStateProps {
    data: CustomScenarioData;
}

const CustomScenarioWithState: React.FunctionComponent<
    CustomScenarioWithStateProps
> = ({ data }) => {
    return (
        <TriggerState>
            <CustomScenario data={data} />
        </TriggerState>
    );
};

export default CustomScenarioWithState;
