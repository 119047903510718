/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ThemeContext from "../../../../context/theme/themeContext";
import LanguageContext from "../../../../context/language/languageContext";
import LayoutContext from "../../../../context/layout/layoutContext";
import useMediaQueries from "../../../../utils/useMediaQueries";
import ResetConfigAgreementModal from "./ResetConfigAgreementModal";
import Button from "../../../MuiComponents/Button";
import SaveButtons from "./SaveButtons";

const Footer = () => {
    const {
        colors: { white, gray700 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    const { layoutData, actionLoading, configResetting } =
        useContext(LayoutContext);

    const { toSm, fromMd } = useMediaQueries();

    const [openedResetAgreement, setOpenedResetAgreement] = useState(false);

    const openResetAgreement = () => setOpenedResetAgreement(true);
    const closeResetAgreement = () => setOpenedResetAgreement(false);

    return (
        <Fragment>
            <div
                css={css({
                    position: "fixed",
                    paddingRight: "200px",
                    width: fromMd ? "calc(100% - 300px)" : "calc(100% - 40px)",
                    bottom: 0,
                    zIndex: 400,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: layoutData?.isOnline
                        ? "space-between"
                        : "flex-end",
                    background: white,
                    boxShadow: [
                        "0px -6px 14px -6px rgba(24, 39, 75, 0.08)",
                        "0px -10px 32px -4px rgba(24, 39, 75, 0.06)",
                    ],
                    padding: "10px 42px 10px 24px",
                    "@media (max-width: 599px)": {
                        "& > button + div": {
                            marginLeft: "8px",
                        },
                    },
                })}
            >
                {layoutData?.isOnline && (
                    <Fragment>
                        {configResetting ? (
                            <div
                                css={css({
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                })}
                            >
                                <Button
                                    variant="iconLeft"
                                    size="small"
                                    color="white"
                                    icon={
                                        <div
                                            css={css({
                                                width: "24px",
                                                height: "24px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            })}
                                        >
                                            <CircularProgress
                                                size={20}
                                                css={css({
                                                    color: gray700,
                                                })}
                                            />
                                        </div>
                                    }
                                    css={css({
                                        pointerEvents: "none",
                                    })}
                                    idForTesting="resettingButton"
                                >
                                    {t.Resetting}
                                </Button>
                            </div>
                        ) : (
                            <Button
                                variant="textOnly"
                                size="small"
                                color="secondary"
                                onClick={openResetAgreement}
                                disabled={actionLoading}
                                css={css({
                                    "@media (max-width: 599px)": {
                                        width: "50%",
                                    },
                                })}
                                idForTesting="resetButton"
                            >
                                {toSm ? t.Reset : t.ResetConfiguration}
                            </Button>
                        )}
                    </Fragment>
                )}

                <div
                    css={css({
                        "@media (max-width: 599px)": {
                            width: layoutData?.isOnline ? "50%" : "100%",
                        },

                        "& > button + button": {
                            marginLeft: "8px",
                        },
                    })}
                >
                    <SaveButtons />
                </div>
            </div>

            <ResetConfigAgreementModal
                isOpen={openedResetAgreement}
                close={closeResetAgreement}
            />
        </Fragment>
    );
};

export default Footer;
