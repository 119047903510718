/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";

import Button from "../../../../../../MuiComponents/Button";
import Tag from "../../../../../../MuiComponents/Tag";
import {
    operandColumns,
    priorityColumns,
} from "../../../../../../../constants/constants";
import InputOutputContext from "../../../../../../../context/inputOutput/inputOutputContext";
import ThemeContext from "../../../../../../../context/theme/themeContext";
import LayoutContext from "../../../../../../../context/layout/layoutContext";
import { DeviceFamily } from "../../../../../../../utils/types";
import LanguageContext from "../../../../../../../context/language/languageContext";

const FilteredBySection = () => {
    const {
        activeFilters,
        activeOperand,
        activePriority,
        resetAllFilters,
        resetFilter,
        resetOperand,
        resetPriority,
    } = useContext(InputOutputContext);

    const {
        colors: { textDark },
    } = useContext(ThemeContext);

    const { layoutData } = useContext(LayoutContext);

    const { t } = useContext(LanguageContext);

    const isFtcDeviceFamily =
        !!layoutData?.deviceFamily &&
        (layoutData?.deviceFamily as unknown) === DeviceFamily.FTC;

    return (
        <div
            css={css({
                display: "flex",
                alignItems: "center",
                padding: "12px 24px 0 0",
            })}
        >
            <div
                css={css({
                    color: textDark,
                    fontWeight: 600,
                    lineHeight: "20px",
                    padding: "6px 0",
                    letterSpacing: "0.1px",
                    marginRight: "16px",
                    whiteSpace: "nowrap",
                    alignSelf: "flex-start",
                })}
            >
                {t.FilteredBy}:
            </div>

            <div
                css={css({
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    columnGap: "6px",
                    rowGap: "6px",
                })}
            >
                {activePriority.map((priority: string) => (
                    <Tag
                        key={priority}
                        size="medium"
                        color="primary"
                        onDelete={() => resetPriority(priority)}
                        title={
                            priorityColumns.find(
                                (item) => item.value === priority
                            )?.name as string
                        }
                    />
                ))}
                {activeOperand.map((operand: string) => (
                    <Tag
                        key={operand}
                        size="medium"
                        color="primary"
                        onDelete={() => resetOperand(operand)}
                        title={
                            operandColumns(isFtcDeviceFamily).find(
                                (item) => item.value === operand
                            )?.name as string
                        }
                    />
                ))}
                {activeFilters.map((value) => {
                    const translations: { [key: string]: string } = {
                        currentValue: t.CurrentValues,
                        sendSmsTo: t.SendEventSet,
                    };
                    return (
                        <Tag
                            key={value}
                            size="medium"
                            color="primary"
                            onDelete={() => resetFilter(value)}
                            title={translations[value]}
                        />
                    );
                })}
                <Button
                    variant="textOnly"
                    size="small"
                    color="white"
                    onClick={resetAllFilters}
                    css={css({
                        alignSelf: "flex-end",
                        whiteSpace: "nowrap",
                        marginTop: "-2px",
                    })}
                    idForTesting="clearAllFilters"
                >
                    {t.ClearAll}
                </Button>
            </div>
        </div>
    );
};

export default FilteredBySection;
