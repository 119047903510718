/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Box } from "@mui/material";

interface DownloadProgressProps {
    downloadText: string | undefined;
    downloadingPercentage: number | null;
    percentageWidth?: string;
}

const DownloadProgress = ({
    downloadText,
    downloadingPercentage,
    percentageWidth,
}: DownloadProgressProps) => {
    const sharedCSS = css({
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
        letterSpacing: "0.1px",
    });

    return downloadText ? (
        <Box display="flex" justifyContent="right" alignItems="center">
            <span css={css(sharedCSS, { paddingRight: "2px" })}>
                {downloadText}
            </span>

            <Box width={percentageWidth ?? undefined}>
                <span css={css(sharedCSS)}>{downloadingPercentage || 0}%</span>
            </Box>
        </Box>
    ) : null;
};

export default DownloadProgress;
