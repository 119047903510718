/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import { Chip, Stack } from "@mui/material";

import Button from "./Button";

import ThemeContext from "../../context/theme/themeContext";
import LanguageContext from "../../context/language/languageContext";

export interface FilteredByBlockProps {
    filteredTagsNameAndValue: { name: string; value: string | number }[];
    onChipDelete: (value: string | number) => void;
    onClearAll: () => void;
    additionalStyles?: any;
}

const FilteredByBlock: React.FunctionComponent<FilteredByBlockProps> = ({
    filteredTagsNameAndValue,
    onChipDelete,
    onClearAll,
    additionalStyles,
}) => {
    const {
        colors: { textDark, blue100, blue700, blue800 },
    } = useContext(ThemeContext);
    const { t } = useContext(LanguageContext);
    return (
        <div
            css={css({
                display: "flex",
                alignItems: "stretch",
                padding: "0 24px 8px 24px",

                "@media (max-width: 839px)": {
                    padding: "0 0 16px 0",
                },
                ...additionalStyles,
            })}
        >
            <p
                css={css({
                    color: textDark,
                    lineHeight: "20px",
                    padding: "6px 0",
                    letterSpacing: "0.1px",
                    marginRight: "16px",
                    whiteSpace: "nowrap",
                })}
            >
                {t.FilteredBy}:
            </p>

            <Stack
                direction="row"
                css={css({
                    flexWrap: "wrap",
                    margin: "-6px 16px 0 -6px",

                    "& > div": {
                        background: blue100,
                        color: blue700,
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        marginLeft: "6px",
                        marginTop: "6px",
                        padding: "6px 8px",
                        borderRadius: "4px",
                    },
                })}
            >
                {filteredTagsNameAndValue.map(
                    (item: { name: string; value: string | number }) => (
                        <Chip
                            key={item.value}
                            label={item.name}
                            onDelete={() => onChipDelete(item.value)}
                            sx={{
                                "& .MuiChip-label": {
                                    padding: "0 8px 0 0",
                                },

                                "& .MuiChip-deleteIcon": {
                                    color: blue700,
                                    margin: "0",
                                    fontSize: "16px",

                                    "&:hover": {
                                        color: blue800,
                                    },
                                },
                            }}
                        />
                    )
                )}
            </Stack>

            <Button
                variant="textOnly"
                size="small"
                color="white"
                onClick={onClearAll}
                css={css({
                    alignSelf: "flex-start",
                    whiteSpace: "nowrap",
                    marginTop: "-2px",
                })}
                idForTesting="clear-all-btn"
            >
                {t.ClearAll}
            </Button>
        </div>
    );
};

export default FilteredByBlock;
