/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useContext } from "react";

import Tooltip from "../../MuiComponents/Tooltip";
import ThemeContext from "../../../context/theme/themeContext";

export interface PortHeaderActionButtonProps {
    portHeaderButton: {
        icon: React.JSX.Element;
        tooltip: any;
        onClick: () => void;
    };
}

const PortHeaderActionButton: React.FunctionComponent<
    PortHeaderActionButtonProps
> = ({ portHeaderButton }) => {
    const {
        colors: { gray200 },
    } = useContext(ThemeContext);
    return (
        <Tooltip title={portHeaderButton.tooltip} placement="top-end">
            <span>
                <div
                    css={css({
                        cursor: "pointer",
                        pointerEvents: "unset",
                        backgroundColor: "inherit",
                        "&:hover": {
                            background: gray200,
                        },
                        padding: "6px",
                        borderRadius: "6px",
                    })}
                    onClick={portHeaderButton?.onClick || (() => {})}
                >
                    {portHeaderButton.icon}
                </div>
            </span>
        </Tooltip>
    );
};

export default PortHeaderActionButton;
