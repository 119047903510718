/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FEParagraph } from "../../../../../../generatedTypes";
import useFeElementParser from "../../../../../../utils/useFeElementParser";

interface FeParagraphProps {
    paragraph: FEParagraph;
    type?: "notification" | "version";
    id?: string;
}
const FeParagraph = ({ paragraph, type, id }: FeParagraphProps) => {
    const { renderFeElement, parseTextStyle, isList } = useFeElementParser();

    const paragraphOrientation =
        paragraph.orientation === "Vertical" ? "column" : "row";

    const isNotification = type === "notification";

    let paragraphStyle: any = parseTextStyle(paragraph.textStyle);

    const cursorType = isNotification
        ? { userSelect: "text", cursor: "text" }
        : {};

    if (!paragraph.children.find((element) => isList(element))) {
        paragraphStyle = {
            ...paragraphStyle,
            display: "flex",
            flexDirection: paragraphOrientation,
            gap: isNotification ? 4 : 8,
            ...cursorType,
        };
    }

    const forwardId = isNotification ? id : undefined;

    return (
        <div css={css(paragraphStyle)}>
            {paragraph.children.map((element, index) =>
                renderFeElement(element, index, type, forwardId)
            )}
        </div>
    );
};

export default FeParagraph;
