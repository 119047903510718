/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useContext, useEffect } from "react";
import { jsx } from "@emotion/react";
import { Popover } from "@mui/material";
import { HubConnection } from "@microsoft/signalr";

import PortListItem from "./PortListItem";
import SinglePortPopup from "./SinglePortPopup";

import useMediaQueries from "../../../../utils/useMediaQueries";
import useApi from "../../../../utils/useApi";

import ThemeContext from "../../../../context/theme/themeContext";
import TerminalContext from "../../../../context/components/terminal/terminalContext";

type ListDevice = {
    modelName: string;
    imei: string;
    ports: {
        address: string;
        isConnected: boolean;
        type: number;
    }[];
};
export interface PortListProps {
    connection: HubConnection | null;
}

const PortList: React.FunctionComponent<PortListProps> = ({ connection }) => {
    const { toMd } = useMediaQueries();
    const [portList, setPortList] = React.useState<ListDevice[]>([]);

    const {
        portListAnchorEl,
        setPortListAnchorEl,
        singlePortAnchorEl,
        setSinglePortListAnchorEl,
        setActivePortsForDropdown,
    } = useContext(TerminalContext);

    const {
        colors: { black },
    } = useContext(ThemeContext);

    const { getData } = useApi();

    const getDevicesForList = async () => {
        try {
            const { data } = await getData(`terminal/devices`);
            setPortList(data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const isPortListOpen = !!portListAnchorEl;

    useEffect(() => {
        isPortListOpen && getDevicesForList();
    }, [isPortListOpen]);

    const handleSinglePortOpen = (portsData: any) => {
        setActivePortsForDropdown(portsData);
        setSinglePortListAnchorEl(portListAnchorEl);
        setPortListAnchorEl(null);
    };
    return (
        <Fragment>
            <Popover
                open={isPortListOpen}
                anchorEl={portListAnchorEl}
                onClose={() => setPortListAnchorEl(null)}
                anchorReference={toMd ? "anchorPosition" : "anchorEl"}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                marginThreshold={16}
                sx={{
                    "& .MuiPaper-root": {
                        maxHeight: "240px",
                        height: "unset",
                        maxWidth: "304px",
                        width: "100%",
                        marginTop: "10px",
                    },
                }}
            >
                {portList.length === 0 && (
                    <div
                        css={{
                            padding: "12px 16px",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            color: black,
                        }}
                    >
                        No devices found
                    </div>
                )}
                {portList.map((item) => {
                    return (
                        <PortListItem
                            key={item.imei}
                            deviceName={item.modelName}
                            imei={item.imei}
                            onClick={() => handleSinglePortOpen(item)}
                        />
                    );
                })}
            </Popover>
            {!!singlePortAnchorEl && (
                <SinglePortPopup connection={connection} />
            )}
        </Fragment>
    );
};

export default PortList;
