/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";

import { NOTIFICATION_STATUS, StatusBadgeProps } from "./types";
import ThemeContext from "../../../../../../../context/theme/themeContext";

const StatusBadge = ({ status }: StatusBadgeProps) => {
    const {
        colors: { blue600, orange500, red700 },
    } = useContext(ThemeContext);

    const setBadgeColor = () => {
        switch (status) {
            case NOTIFICATION_STATUS.Warning:
                return orange500;
            case NOTIFICATION_STATUS.Error:
                return red700;

            case NOTIFICATION_STATUS.Update:
                return blue600;
        }
    };

    const badgeColor = setBadgeColor();

    return (
        <span
            css={css({
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                margin: "6px 6px 6px 0",
                backgroundColor: badgeColor,
            })}
        />
    );
};

export default StatusBadge;
