/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext } from "react";
import TextLink from "../../../../../MuiComponents/TextLink";
import { FEHyperlink } from "../../../../../../generatedTypes";
import SettingsContext from "../../../../../../context/settings/settingsContext";
import { noop } from "../../../../../../utils/helpers";

interface FeHyperlinkProps {
    hyperlink: FEHyperlink;
    id?: string;
}

const FeHyperlink = ({ hyperlink, id }: FeHyperlinkProps) => {
    const url = hyperlink?.url;
    const text = hyperlink?.linkText;

    const { readNotification } = useContext(SettingsContext);

    const handleClick = () => {
        return id ? readNotification(id) : noop;
    };

    return (
        <TextLink
            component={"a"}
            href={url || ""}
            onClick={handleClick}
            target="_blank"
            size="small"
            idForTesting="notificationLink"
            css={css({
                wordBreak: "break-word",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "250px",
                display: "block",
            })}
        >
            {text}
        </TextLink>
    );
};

export default FeHyperlink;
