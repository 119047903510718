/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Fragment, useContext, useState } from "react";
import { useAtom } from "jotai";
import SingleNotification from "./SingleNotification";
import NoNotifications from "./NoNotifications";
import UpdateNotification from "../UpdateNotification";
import TextLink from "../../../../MuiComponents/TextLink";
import ThemeContext from "../../../../../context/theme/themeContext";
import SettingsContext from "../../../../../context/settings/settingsContext";
import { SingleNotification as Notification } from "../../../../../context/settings/settingsReducer";
import useMediaQueries from "../../../../../utils/useMediaQueries";
import { TctUpdateAvailableAtom } from "../../../../../context/webSockets/webSocketsState";
import LanguageContext from "../../../../../context/language/languageContext";
import { minCountForShowMoreNotifications } from "../../../../../constants/constants";

const NotificationsList = () => {
    const {
        colors: { gray200, white, blue400, blue300, textPlaceholder },
    } = useContext(ThemeContext);

    const { t } = useContext(LanguageContext);

    const { latestNotifications, readAllCommonNotifications } =
        useContext(SettingsContext);

    const [{ available }, _] = useAtom(TctUpdateAvailableAtom);

    const { toMd } = useMediaQueries();

    const [showAll, setShowAll] = useState(false);

    const handleShowPrevious = () => {
        readAllCommonNotifications();
        setShowAll(true);
    };

    const heightFromShowAll =
        latestNotifications.length > 10 && showAll ? 40 : 0;

    const desktopMaxHeight = available
        ? `${492 + heightFromShowAll}px`
        : `${384 + heightFromShowAll}px`;

    const checkIfLast = (notificationIndex: number) => {
        const notificationsLength = latestNotifications?.length;

        if (notificationsLength > 10 && !showAll) {
            return notificationIndex === 9;
        }

        return notificationIndex === notificationsLength - 1;
    };

    return !available && latestNotifications.length === 0 ? (
        <NoNotifications />
    ) : (
        <Fragment>
            <div
                css={css({
                    maxHeight: toMd ? "unset" : desktopMaxHeight,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                        width: "16px",
                    },

                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: blue300,
                        borderRadius: "100px",
                        border: `5px solid ${white}`,
                        minHeight: "48px",
                    },

                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: blue400,
                    },
                })}
            >
                {available && <UpdateNotification />}

                <div>
                    {latestNotifications.length > 0 && (
                        <div
                            css={css({
                                color: textPlaceholder,
                                fontSize: "12px",
                                fontWeight: "600",
                                lineHeight: "16px",
                                letterSpacing: "0.4px",
                                padding: "12px 16px 4px 16px",
                            })}
                        >
                            {t.Latest}
                        </div>
                    )}

                    {latestNotifications.map(
                        (
                            {
                                id,
                                type,
                                timeStamp,
                                title,
                                description,
                                isRead,
                                dynamicContent,
                            }: Notification,
                            i: number
                        ) =>
                            i < 10 && (
                                <SingleNotification
                                    key={`${title}_${id}`}
                                    isLast={checkIfLast(i)}
                                    id={id}
                                    type={type}
                                    timeStamp={timeStamp}
                                    title={title}
                                    description={description}
                                    dynamicContent={dynamicContent}
                                    isRead={isRead}
                                />
                            )
                    )}

                    {showAll &&
                        latestNotifications.map(
                            (
                                {
                                    id,
                                    type,
                                    timeStamp,
                                    title,
                                    description,
                                    isRead,
                                    dynamicContent,
                                }: Notification,
                                i: number
                            ) =>
                                i > 9 && (
                                    <SingleNotification
                                        key={`${title}_${id}`}
                                        isLast={checkIfLast(i)}
                                        id={id}
                                        type={type}
                                        timeStamp={timeStamp}
                                        title={title}
                                        description={description}
                                        dynamicContent={dynamicContent}
                                        isRead={isRead}
                                    />
                                )
                        )}
                </div>
            </div>
            {latestNotifications.length > minCountForShowMoreNotifications && (
                <div
                    css={css({
                        position: "sticky",
                        bottom: 0,
                        left: 0,
                        background: white,
                        width: "100%",
                        borderTop: `1px solid ${gray200}`,
                    })}
                >
                    {!showAll && (
                        <TextLink
                            size="small"
                            idForTesting="previousNotificationsBtn"
                            onClick={handleShowPrevious}
                            css={css({
                                margin: "8px 16px 16px",
                            })}
                        >
                            {t.SeePreviousNotifications}
                        </TextLink>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default NotificationsList;
