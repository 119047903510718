/** @jsxRuntime classic */

/** @jsx jsx */
import { Fragment, useContext } from "react";

import { css, jsx } from "@emotion/react";

import { CircularProgress } from "@mui/material";

import DeviceStatusContext from "../../../../../../context/deviceStatus/deviceStatusContext";
import LanguageContext from "../../../../../../context/language/languageContext";
import ThemeContext from "../../../../../../context/theme/themeContext";
import { FirmwareBundleUpdateStep } from "../../../../../../generatedTypes";
import { sortFirmwareAndDtb } from "../../../../../../utils/helpers";
import useApi from "../../../../../../utils/useApi";
import BannerView from "../../../../BannerView";
import Button from "../../../../Button";
import Dropzone from "../../../../Dropzone";
import BundleUpdateContext from "../../context/bundleUpdateContext";

import CustomCardContent from "./CustomCardContent";

export interface IntroStepProps {}

const IntroStep: React.FunctionComponent<IntroStepProps> = () => {
    const { setLoading, setError, loading, setFile, file, error } =
        useContext(BundleUpdateContext);
    const { selectedLanguage } = useContext(LanguageContext);
    const { setBundleUpdateProgressSteps, bundleModalProgressSteps } =
        useContext(DeviceStatusContext);
    const {
        colors: { blue700 },
    } = useContext(ThemeContext);

    const { postData } = useApi();
    const splitToDtbAndFirmware = sortFirmwareAndDtb(bundleModalProgressSteps);
    const firmwareSteps = splitToDtbAndFirmware?.firmware || [];
    const dtbSteps = splitToDtbAndFirmware?.dtb || [];

    const getSteps = (
        filePath: string,
        firmwareSteps: FirmwareBundleUpdateStep[],
        dtbSteps: FirmwareBundleUpdateStep[],
    ) => {
        const fileName = filePath.split("\\").pop();
        return [
            {
                fileName: fileName,
                filePath: filePath,
                isEmpty: false,
                customCardContent: (
                    <CustomCardContent
                        firmwareSteps={firmwareSteps}
                        dtbSteps={dtbSteps}
                    />
                ),
            },
        ];
    };

    const results: any =
        bundleModalProgressSteps.length > 0
            ? getSteps(file?.filename ?? "", firmwareSteps, dtbSteps)
            : [];

    const handleFileUpload = async (files: FileList) => {
        if (!files || files.length === 0) {
            return;
        }

        const formData = new FormData();

        formData.append(files[0].name, files[0]);
        try {
            setLoading(true);
            const { data } = await postData(
                `${selectedLanguage}/updateFile/firmwarebundle`,
                formData,
            );
            setBundleUpdateProgressSteps(null, data.updateSteps);
            setFile({ filename: files[0].name, file: formData });
            setLoading(false);
        } catch (error: any) {
            const err = error.response?.data;
            setError(err.detail);
            setLoading(false);
        }
    };

    const handleInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const files = event.target.files;
        if (!files?.length) return;
        try {
            await handleFileUpload(files);
        } catch (error: any) {
            const err = error.response?.data;
            setError(err.detail);
        }
    };

    const renderButtonContent = () => {
        if (loading) {
            return (
                <CircularProgress
                    size={20}
                    css={css({
                        color: blue700,
                    })}
                />
            );
        }
        return (
            <Fragment>
                Change
                <input
                    css={css({
                        position: "absolute",
                        opacity: 0,
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                    })}
                    type="file"
                    onChange={handleInputChange}
                    accept=".tpack"
                />
            </Fragment>
        );
    };

    return (
        <div
            css={css({
                display: "flex",
                gap: "16px",
                flexDirection: "column",
            })}
        >
            {error && (
                <BannerView
                    data={{
                        bannerType: "Error",
                        description: error,
                    }}
                />
            )}
            <Dropzone
                supportedFileFormats={".tpack"}
                OnInputChange={handleInputChange}
                menuItems={[]}
                items={results}
                customButton={
                    <Button
                        variant="textOnly"
                        size="small"
                        color="primarySubtle"
                        idForTesting="clearButton"
                        disabled={loading}
                    >
                        {renderButtonContent()}
                    </Button>
                }
                isDropzoneHidden={results.length > 0}
                loading={loading}
                isDropzoneDisabled={loading}
            />
        </div>
    );
};

export default IntroStep;
